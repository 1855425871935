<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol xl="12">
						<BannerForm
							is-update
							@apply-form="updateBanner"
							@cancel-form="goToBannerList"
							@delete-form="deleteBanner"
						>
						</BannerForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { UPDATE_BANNER, GET_BANNER_BY_ID, DELETE_BANNER } from "./../store/action-types"
import { createNamespacedHelpers, mapState } from "vuex"
const { mapActions } = createNamespacedHelpers("banners")
export default {
	name: "UpdateBanner",

	components: {
		BannerForm: () => import("./../components/BannerForm.vue"),
	},

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	async created() {
		this.recentBanner = null
		await this.GET_BANNER_BY_ID({ id: this.id })
	},

	methods: {
		...mapState("banners", [
			"recentBanner",
			"isShowStatusModal",
			"isShowDiscardModal",
			"isShowDeleteModal",
		]),
		...mapActions({ UPDATE_BANNER, GET_BANNER_BY_ID, DELETE_BANNER }),

		updateBanner: async function (params) {
			await this.UPDATE_BANNER(params)
		},
		goToBannerList() {
			this.$router.push({ name: "BannerList" })
		},
		deleteBanner: async function (params) {
			await this.DELETE_BANNER(params)
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
</style>
