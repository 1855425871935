import { callAPI } from "@/shared/services"

export default {
	getReportSchedule: params => {
		return callAPI("GET", "admin/appointment/schedule-report", {
			params,
			token: params.token,
		}).then(res => res.data)
	},

	getReportPrep: params => {
		return callAPI("GET", "admin/customer/prep-customer-report-v2", {
			params,
			token: params.token,
		}).then(res => res.data)
	},

	getReportPrepExcel: params => {
		return callAPI("GET", "admin/customer/export-prep-customer-report", {
			params,
			timeout: process.env.VUE_APP_TIMEOUT_IMPORT,
		}).then(response => {
			const url = response.data.fileUrl
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", null)
			document.body.appendChild(link)
			link.click()
		})
	},

	getReportScheduleExcel: params => {
		return callAPI("GET", "admin/appointment/export-schedule-report", {
			params,
			timeout: process.env.VUE_APP_TIMEOUT_IMPORT,
		}).then(response => {
			const url = response.data.fileUrl
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", null)
			document.body.appendChild(link)
			link.click()
		})
	},

	getReportCustomFormExcel: params => {
		return callAPI("GET", "admin/customer/custom-form-report", {
			params,
			timeout: process.env.VUE_APP_TIMEOUT_IMPORT,
		}).then(response => {
			const url = response.data.fileUrl
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", null)
			document.body.appendChild(link)
			link.click()
		})
	},
	getReportPlatformIndex: params => {
		return callAPI("GET", "admin/customer/platform-index-report", {
			params,
			timeout: process.env.VUE_APP_TIMEOUT_IMPORT,
		}).then(response => {
			const url = response.data.fileUrl
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", null)
			document.body.appendChild(link)
			link.click()
		})
	},

	getClinic: params => {
		return callAPI("GET", "clinic", {
			params,
			token: params.token,
			notLoading: true,
		}).then(res => res.data)
	},
}
