import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	ReportTitle: {
		ReportManagement: "Quản lý báo cáo",
		PrEP: "Thống kê KH PrEP",
		Schedule: "Thống kê đặt hẹn",
		General: "Báo cáo tổng hợp",
		ReportPrEPList: "Danh sách thống kê khách hàng PrEP",
		ReportScheduleList: "Danh sách thống kê đặt hẹn",
		ReportGeneralList: "Danh sách báo cáo tổng hợp",
		FormReport: "Xuất dữ liệu từ form",
	},

	ReportScheduleForm: {
		Clinics: "Phòng khám",
		Jan: "Jan",
		Feb: "Feb",
		Mar: "Mar",
		Apr: "Apr",
		May: "May",
		Jun: "Jun",
		Jul: "Jul",
		Aug: "Aug",
		Sep: "Sep",
		Oct: "Oct",
		Nov: "Nov",
		Dec: "Dec",
		Sum: "Tổng",
	},

	ScheduleForm: {
		Contact: "Phương thức liên hệ",
		Request: "Yêu cầu",
		Clinic: "Phòng khám",
		Examination_code: "Mã đặt hẹn",
		Date: "Ngày hẹn",
		Note: "Ghi chú",
		Status: "Trạng thái",
		Result: "Đánh giá",
	},

	PrEPStatusForm: {
		STT: "STT",
		ExaminationDate: "Ngày khách hàng đến khám",
		Result: "Kết quả XN HIV",
		Status: "Tình trạng điều trị PrEP",
		Regimen: "Phác đồ PrEP",
		MedicineUsed: "Số thuốc được phát",
		Detail: "Chi tiết",
		ExaminationDateNext: "Ngày hẹn khám tiếp theo",
		NewPrEPs: "Thống kê khách hàng PrEP đăng ký mới",
	},

	RiskForm: {
		ResultDate: "Ngày đánh giá",
		Result: "Kết quả đánh giá",
	},

	RiskDetail: {
		RiskResult: "Đánh giá nguy cơ",
		CustomerName: "Tên",
		PhoneNumber: "Số điện thoại",
		BirthYear: "Năm sinh",
		EvaluationDate: "Ngày đánh giá",
		ViewOptions: "Xem các lựa chọn",
	},

	MessageForm: {
		STT: "STT",
		Text: "Tin nhắn",
		Date: "Ngày gửi",
	},

	CustomerHistory: {
		Title: "Lịch sử bảo hành",
	},

	CustomerManagement: {
		Active: "Active",
		Inactive: "Inactive",
		No: "No.",
		Actions: "Xem/Sửa",
	},
	Status: {
		Active: "Đang hoạt động",
		Inactive: "Dừng hoạt động",
		Pending: "Đang chờ",
		Complete: "Hoàn thành",
	},

	fields: {
		...{
			customer_code: "Mã khách hàng",
			customer_full_name: "Tên khách hàng",
			customer_type: "Loại khách hàng",
			customer_type_code: "Mã khách hàng PrEP",
			customer_birth_day: "Năm sinh",
			customer_PK: "Phòng khám",
			customer_filter_date: "Ngày sàng lọc",
			customer_start_prep_date: "Ngày bắt đầu dùng PrEP",
			customer_contact: "Phương thức liên hệ",
			customer_address: "Địa chỉ",
			customer_count: "Địa chỉ",
			customer_status: "Trạng thái",
			customer_phone: "Điện thoại",
			// customer_max_process_ticket: "Số yêu cầu tối đa",
		},
	},

	validation: {
		...vi.messages,
		...{
			// custom message rules
			isEmail: "Thông tin này phải là một địa chỉ email hợp lệ",
		},
	},
})
