import i18n from "@/shared/plugins/i18n"

i18n.mergeLocaleMessage("vi", {
	AppTitle: {
		Dashboard: "Dashboard",
		Page404: "Page404",
		Page500: "Page500",
	},

	Form: {
		Error: "Thông tin chưa đủ hoặc chưa chính xác",
	},

	Header: {
		Account: "Tài khoản",
		Setting: "Cài đặt",
		Info: "Thông tin cá nhân",
		ChangePwd: "Thay đổi mật khẩu",
		Logout: "Đăng xuất",
		Notification: "Thông báo",
	},

	Button: {
		Update: "Chỉnh sửa",
		Delete: "Xóa",
		Create: "Tạo",
		Cancel: "Hủy bỏ",
		Submit: "Đồng ý",
		CreateNew: "Tạo mới",
		SaveChanges: "Lưu thay đổi",
		Exit: "Thoát",
		Noti: "Thông báo",
		OK: "OK",
		Save: "Lưu",
		Abort: "Hủy",
		Confirm: "Xác nhận",
		Yes: "Có",
		No: "Không",
		Add: "Thêm",
		Close: "Đóng",
		DiscardChanges: "Loại bỏ những thay đổi?",
		DiscardContent: "Dữ liệu đã nhập sẽ mất khi bạn xác nhận.",
		AreYouSure: "Bạn chắc chứ?",
		SaveAndUpdate: "Lưu thay đổi",
		DelAccount: "Xóa tài khoản",
		Import: "Import",
		Importing: "Importing",
		Find: "Tìm",
		Message: "Message",
		Trashed: "Trashed",
		Trash: "Trash",
		Untrash: "Untrash",
		Merge: "Merge",
	},

	DropDown: {
		Province: "Tỉnh/ Thành phố",
		District: "Quận/ Huyện",
		Status: "Trạng thái",
		Role: "Vai trò",
		CSIN: "C-SIN",
		Search: "Tìm kiếm",
		Create: "Tạo mới",
		Export: "Export link",
		Import: "Import file",
		NoResult: "Không có dữ liệu",
		NoOptions: "Danh sách trống",
		FilterService: "Lọc theo Yêu cầu",
		FilterPK: "Lọc theo PK",
		FilterStatus: "Lọc theo trạng thái",
		FilterCustomer: "Lọc theo khách hàng",
		FilterHIV: "Lọc theo nguy cơ HIV",
		ExportExcel: "Export",
		MaxSelect: "Tối đa {max} lựa chọn, cần xóa một số lựa chọn trước khi chọn thêm",
		ExportSelection: "Chọn loại dữ liệu để export",
		CreateAppointmentType: "Loại khởi tạo",
		RegisterStatus: "Trạng thái đăng kí",
	},

	Tabs: {
		RequestList: "Danh sách yêu cầu",
		AccountWaiting: "Tài khoản chờ",
		COMPLETE: "Đã hoàn thành",
		PENDING: "Chưa hoàn thành",
	},

	CustomerTabs: {
		Infor: "Thông tin chung",
		ExaminationSchedule: "Lịch khám",
		MessageSent: "Tin nhắn đã gửi",
		PrEpStatus: "Tình trạng điều trị PrEP",
		RiskAssessment: "Đánh giá nguy cơ",
		ProductBooking: "Nhận quà miễn phí",
	},

	Roles: {
		SuperAdmin: "Super Admin",
		Admin: "Admin",
	},

	Status: {
		Active: "Đang hoạt động",
		Inactive: "Dừng hoạt động",
		Expired: "Expired",
		Pending: "Pending",
	},

	Table: {
		NoData: "Không có dữ liệu",
		Total: "Tổng {totals} bản ghi",
	},

	CustomersType: {
		PrEP: "KH PrEP",
		Normal: "KH thường",
		Unofficial: "Chưa chính thức",
	},

	CustomersClasstify: {
		NEW: "KH mới",
		OLD: "KH cũ quay lại điều trị",
	},

	CustomerObject: {
		MSM: "MSM",
		TRANS_WOMAN: "Chuyển giới nữ",
		TRANS_MAN: "Chuyển giới nam",
		TCMT: "TCMT",
		PROSTITUTE: "Người bán dâm",
		HETEROSEXUAL_PARTNER: "Bạn tình dị nhiễm",
		OTHER: "Khác",
	},

	RiskEvaluation: {
		VeryHigh: "Rất cao",
		High: "Cao",
		Medium: "Trung bình",
		Low: "Thấp",
		NotEvaluation: "Chưa đánh giá",
	},

	Action: {
		Save: "Lưu",
		Cancel: "Hủy bỏ",
		Update: "Cập nhật",
		Create: "Tạo mới",
		Pending: "Danh sách Pending",
		Edit: "Chỉnh sửa",
		Clear: "Xóa",
		Delete: "Xóa",
		Detail: "Chi tiết",
		Accept: "Đồng ý",
		Deny: "Từ chối",
		Ok: "Đồng ý",
		No: "Hủy bỏ",
		ViewEdit: "Xem/Sửa",
	},

	Sidebar: {
		ManagerCustomer: "Quản lý Khách hàng",
		AppointmentManagement: "Quản lý Lịch khám",
		AdminManagement: "Quản lý Admin",
		MessageManagement: "Quản lý Thông điệp",
		ClinicManagement: "Quản lý Phòng khám",
		ReportManagement: "Báo cáo",
		TopicManagement: "Quản lý Hỏi đáp",
		BannerManagement: "Quản lý Banner",
		NotificationManagement: "Quản lý Thông báo",
	},

	DatePicker: {
		SelectDate: "Chọn ngày",
		SelectTime: "Chọn giờ",
		SelectDateTime: "Chọn thời gian",
	},

	Multiselect: {
		OtherObjects: "và {count} đối tượng khác",
	},

	RelativeTime: {
		s: "giây",
		ss: "giây",
		m: "phút",
		mm: "phút",
		h: "giờ",
		hh: "giờ",
		d: "ngày",
		dd: "ngày",
		w: "tuần",
		ww: "tuần",
		M: "tháng",
		MM: "tháng",
		y: "năm",
		yy: "năm",
	},

	Toast: {
		Update: "Thay đổi thành công!",
		Delete: "Xóa thành công!",
	},

	TreatmentStatus: {
		CONTINUE: "Tiếp tục sử dụng PrEP",
		STOP_DUE_TO_HIV_INFECTION: "Ngừng sử dụng PrEP do nhiễm mới HIV",
		STOP_DUE_TO_DRUG_TOXICITY: "Ngừng sử dụng PrEP do độc tính của thuốc",
		STOP_DUE_TO_DEATH: "Dừng do tử vong",
		STOP_DUE_TO_RISKLESS: "Ngừng sử dụng PrEP vì khách hàng không còn rủi ro",
		STOP_DUE_TO_ANOTHER_REASON: "Dừng do các lý do khác (chuyển địa điểm, ...)",
		LOSS_TRACKING: "Mất theo dõi khách hàng (trong vòng 30 ngày kể từ ngày hẹn tái khám)",
		AUTO_STOP_DUE_TO_TAKEN_ALL: "Dừng vì dùng hết thuốc",
	},

	RiskResult: {
		VERY_HIGH: "Rất cao",
		HIGH: "Cao",
		MEDIUM: "Trung bình",
		LOW: "Thấp",
		NOT_EVALUATE: "Chưa đánh giá",
	},

	TreatmentRegimen: {
		DAILY: "PrEP hàng ngày",
		BY_CASE: "PrEP theo tình huống",
	},

	DailyTimeUsingDrug: "Giờ dùng thuốc hàng ngày",

	RiskReportType: {
		RISK_ASSESS: "Dữ liệu đánh giá nguy cơ",
		ORAQUICK_PREP: "Dữ liệu nhận OraQuick & PrEP",
		PLATFORM_INDEX: "Platform Index Report",
	},
	RegisterStatus: {
		Active: "Đã xác minh SĐT",
		Pending: "Chưa xác minh SĐT",
	},
})
