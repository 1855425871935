export const GET_LIST_NOTIFICATION_SUCCESS = "GET_LIST_NOTIFICATION_SUCCESS"
export const RESET_FILTERS_NOTIFICATION_LIST = "RESET_FILTERS_NOTIFICATION_LIST"

export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const RESET_CUSTOMERS = "RESET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"

export const CHANGE_STATUS_DISCARD_MODAL = "CHANGE_STATUS_DISCARD_MODAL"
export const CHANGE_STATUS_DELETE_MODAL = "CHANGE_STATUS_DELETE_MODAL"
export const CHANGE_STATUS_BANNER_MODAL = "CHANGE_STATUS_BANNER_MODAL"
export const SHOW_PUSH_NOTI_MODAL = "SHOW_PUSH_NOTI_MODAL"
