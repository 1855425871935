<template>
	<div class="custom-table">
		<CCard>
			<CCardBody>
				<slot name="title" />

				<CRow class="form-group d-md-flex">
					<CCol v-if="time" class="text-left d-f-w-cter">
						<slot name="tabs" />
						<span
							>{{ $t("DailyTimeUsingDrug") }} <strong>{{ time }}</strong></span
						>
					</CCol>

					<slot v-if="showActions" name="actions">
						<CCol class="text-right">
							<template v-if="isCreateExamination">
								<button type="button" class="btn btn-outline-custom ml-2" @click="create">
									{{ $t("DropDown.Create") }}
								</button>
							</template>
						</CCol>
					</slot>
				</CRow>

				<slot name="table">
					<div class="table-responsive-md">
						<table class="table table-bordered table-hover table-responsive table-fit">
							<thead>
								<slot name="headers" />
							</thead>
							<tbody>
								<slot name="body" />
							</tbody>
						</table>
					</div>
				</slot>

				<slot name="footer">
					<CRow>
						<!-- Select limit  -->
						<CCol class="custom-table__footer">
							<CRow class="m-0 options">
								<div class="align-self-center">{{ rangeItemsOfTotal }}</div>
								<!-- pagination -->
								<CPagination
									v-if="totalPages > 1"
									:active-page.sync="recentPage"
									:pages="totalPages"
									align="end"
								/>
							</CRow>
						</CCol>
					</CRow>
				</slot>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { PAGING } from "../plugins/constants"
import { mapState } from "vuex"
export default {
	name: "CustomTable",

	props: {
		totalPages: {
			type: [Number, String],
			default: 0,
		},
		recentFilter: {
			type: Object,
			default: () => {},
		},
		showActions: {
			type: Boolean,
			default: true,
		},
		totalItems: {
			type: Number,
			default: 0,
		},
		isCreateExamination: {
			type: Boolean,
			default: false,
		},
		time: {
			type: String,
			default: "",
		},
	},

	data() {
		return {
			limit: PAGING.LIMIT,
			offset: 0,
			recentPage: 1,
		}
	},

	computed: {
		...mapState("authentication", ["currentUser"]),

		rangeItemsOfTotal: function () {
			if (!this.totalPages) return "0 - 0 of 0"

			const from = Number(this.offset) + 1
			const to =
				from / this.limit < this.totalPages - 1
					? Number(this.offset) + Number(this.limit)
					: this.totalItems

			return `${from} - ${to} of ${this.totalItems}`
		},
	},

	watch: {
		recentPage(val) {
			if (val !== Math.floor(this.offset / this.limit) + 1) {
				this.offset = (val - 1) * this.limit
				this.search()
			}
		},
	},

	created() {},

	methods: {
		setData() {
			const queryParams = this.$route.query
			this.limit = queryParams.limit || this.recentFilter.limit || this.limit
			this.offset = queryParams.offset || this.recentFilter.offset || this.offset
			this.recentPage = Math.floor(this.offset / this.limit) + 1

			if (Object.keys(queryParams).length) {
				this.$emit("search", queryParams)
			} else this.search()
		},

		search() {
			const params = {
				limit: this.limit,
				offset: this.offset,
			}
			this.$emit("search", params)
		},

		create() {
			this.$emit("create")
		},

		resetPaging() {
			this.offset = 0
			this.recentPage = 1
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.custom-table {
	.table td.fit,
	.table th.fit {
		white-space: nowrap;
		width: 1%;
	}

	.table th {
		background-color: $table-header-color;
		color: $base-color;
		font-weight: 600;
	}

	&__footer {
		display: grid;
		grid-template-columns: 1fr auto auto;

		.options {
			justify-self: end;
		}

		/deep/ .pagination {
			margin-left: 15px;
			margin-bottom: 0;
		}

		&__total {
			grid-column: -1/1;

			@media (min-width: $xxs) {
				grid-column: initial;
			}
		}
	}

	.actions {
		span {
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.d-f-w-cter {
	display: flex;
	align-items: center;
}
</style>
