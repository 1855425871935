import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins"

import CreateNotification from "./pages/CreateNotification"
import NotificationAdminModule from "./NotificationAdminModule"
const AdminNotificationManagement = () => import("./pages/AdminNotificationManagement")

const notificationRoutes = [
	{
		path: "/notifications",
		name: "NotificationAdminModule",
		component: NotificationAdminModule,
		meta: {
			label: i18n.t("NotificationTitle.Name"),
		},
		redirect: "/notifications/list",
		children: [
			{
				path: "list",
				name: "NotificationList",
				component: AdminNotificationManagement,
				meta: {
					title: i18n.t("NotificationTitle.NotificationManagement"),
					label: i18n.t("NotificationTitle.NotificationManagement"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
			{
				path: "create",
				name: "CreateNotification",
				component: CreateNotification,
				meta: {
					title: i18n.t("NotificationTitle.NotificationManagement"),
					label: i18n.t("NotificationTitle.NotificationManagement"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
		],
	},
]

export default notificationRoutes
