import i18n from "@/shared/plugins/i18n"

const Login = () => import("./pages/Login")
const OTP = () => import("./pages/OTP")
const ForgotPassword = () => import("./pages/ForgotPassword")
const ResetPassword = () => import("./pages/ResetPassword")
const Profile = () => import("./pages/Profile")

// Containers
const TheContainer = () => import("@/shared/containers/TheContainer")

const authenRoutes = [
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {
			title: i18n.t("AuthenTitle.Login"),
		},
	},
	{
		path: "/login-otp",
		name: "OTP",
		component: OTP,
		meta: {
			title: i18n.t("AuthenTitle.OTP"),
		},
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: ForgotPassword,
		meta: {
			title: i18n.t("AuthenTitle.ForgotPassword"),
		},
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: ResetPassword,
		meta: {
			title: i18n.t("AuthenTitle.ResetPassword"),
		},
	},
	{
		path: "/profile",
		component: TheContainer,
		children: [
			{
				path: "",
				name: "Profile",
				component: Profile,
				meta: {
					title: i18n.t("AuthenTitle.Profile"),
					requiresAuth: true,
					label: i18n.t("AuthenTitle.Profile"),
				},
			},
		],
	},
]

export default authenRoutes
