<template>
	<div class="custom-table">
		<CCard>
			<CCardBody>
				<slot name="title" />
				<slot name="filters">
					<div class="filters">
						<div v-if="isSystemAdmin">
							<multiselect
								id="clinic"
								v-model="clinic"
								:options="listClinics"
								:multiple="true"
								:close-on-select="true"
								:show-labels="false"
								:allow-empty="true"
								:searchable="true"
								:placeholder="$t('DropDown.FilterPK')"
								:internal-search="false"
								:limit="limitVisibleDD"
								:max="limitSelected"
								label="name"
								track-by="id"
								autocomplete="off"
								@select="selectClinic($event, DROPDOWN_ACTIONS.ADD)"
								@remove="selectClinic($event, DROPDOWN_ACTIONS.REMOVE)"
								@search-change="searchChange"
							>
								<template #maxElements>{{
									$t("DropDown.MaxSelect", { max: limitSelected })
								}}</template>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>

						<div>
							<date-picker
								v-model="dateYear"
								type="year"
								placeholder="Năm"
								class="w-100"
								@change="selectedYear"
							></date-picker>
						</div>
					</div>
				</slot>

				<CRow class="form-group d-md-flex">
					<CCol class="text-left">
						<slot name="tabs" />
					</CCol>

					<slot name="actions">
						<CCol v-if="!isMobile" class="text-right">
							<template>
								<button type="button" class="btn btn-outline-dark ml-2" @click="exportExcel">
									{{ $t("DropDown.ExportExcel") }}
								</button>
							</template>
						</CCol>
					</slot>
				</CRow>
				<div v-if="isPrep">
					<VueApexCharts :series="series" />
					<br />
					<br />
				</div>

				<slot name="table">
					<div class="table-responsive-md">
						<table class="table table-bordered table-hover table-responsive table-fit">
							<thead>
								<slot name="headers" />
							</thead>
							<tbody>
								<slot name="body" />
							</tbody>
						</table>
					</div>
				</slot>

				<slot name="footer">
					<CRow>
						<!-- Select limit  -->
						<CCol class="custom-table__footer">
							<p v-if="totalItems" class="custom-table__total">
								<i18n path="Table.Total" tag="span" class="custom-table__total">
									<template #totals>
										<span>{{ totalItems }}</span>
									</template>
								</i18n>
							</p>
						</CCol>
					</CRow>
				</slot>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { DROPDOWN_ACTIONS, USER_ROLE, INPUT_LENGTH } from "@/shared/plugins/constants"
import { mapState } from "vuex"

export default {
	name: "CustomTableReport",

	components: {
		VueApexCharts: () => import("../../modules/report/components/VueApexCharts"),
	},

	props: {
		clinics: {
			type: [Array],
			default: () => [],
		},
		totalItems: {
			type: Number,
			default: 0,
		},
		isPrep: {
			type: Boolean,
			default: false,
		},
		series: {
			type: Array,
			default: () => [],
		},
		isMobile: {
			type: [Boolean, String],
			default: false,
		},
		mobileRole: {
			type: [String],
			default: null,
		},
	},

	data() {
		return {
			limitVisibleDD: INPUT_LENGTH.VISIBLE_DROPDOWN,
			limitSelected: INPUT_LENGTH.LIMIT_SELECTED,
			DROPDOWN_ACTIONS: DROPDOWN_ACTIONS,

			listClinics: [],
			listClinicsSelected: [],
			dateYear: null,
			clinic: null,
		}
	},

	computed: {
		...mapState("authentication", ["currentUser"]),

		isSystemAdmin() {
			const role = this.isMobile ? this.mobileRole : this.currentUser?.role
			return role === USER_ROLE.SYSTEM_ADMIN
		},
	},

	watch: {
		clinics: function (val) {
			if (val && val.length) {
				this.listClinics = [...val]

				if (!this.isPrep) return

				this.clinic = [val[0]]
				this.listClinicsSelected = [val[0].id]

				this.search()
			}
		},
	},

	created() {
		if (!this.isPrep) this.search()
	},

	mounted() {
		this.setAutocompleteOff()
	},

	methods: {
		search() {
			const params = {}
			if (this.listClinicsSelected && this.listClinicsSelected.length > 0) {
				params["clinicIds"] = this.listClinicsSelected.join(",")
			}
			if (this.dateYear) {
				params["year"] = new Date(this.dateYear).getFullYear()
			}
			this.$emit("search", params)
		},

		selectClinic(selected, type) {
			switch (type) {
				case DROPDOWN_ACTIONS.ADD:
					this.listClinicsSelected.push(selected.id)
					break
				case DROPDOWN_ACTIONS.REMOVE:
					this.listClinicsSelected.splice(
						this.listClinicsSelected.findIndex(el => el === selected.id),
						1,
					)
					break
				default:
					break
			}
			this.search()
		},

		selectedYear() {
			this.search()
		},

		searchChange(value) {
			if (!value || !value.length) {
				this.listClinics = [...this.clinics]
				return
			}

			const valueSearch = this.nomalizeText(value)
			this.listClinics = this.clinics.filter(({ name }) => {
				return this.nomalizeText(name).includes(valueSearch)
			})
		},

		exportExcel() {
			const params = {}
			if (this.listClinicsSelected && this.listClinicsSelected.length > 0) {
				params["clinicIds"] = this.listClinicsSelected.join(",")
			}
			if (this.dateYear) {
				params["year"] = new Date(this.dateYear).getFullYear()
			}
			this.$emit("export", params)
		},

		setAutocompleteOff() {
			const elements = document.getElementsByClassName("multiselect__input")

			if (elements.length) {
				elements.forEach(element => {
					element.autocomplete = "off"
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.custom-table {
	.table td.fit,
	.table th.fit {
		white-space: nowrap;
		width: 1%;
	}

	.table th {
		background-color: $table-header-color;
		color: $base-color;
		font-weight: 600;
	}

	&__footer {
		display: grid;
		grid-template-columns: 1fr auto auto;

		.options {
			justify-self: end;
		}

		.pagination {
			margin-left: 15px;
			margin-bottom: 0;
		}

		&__total {
			grid-column: -1/1;

			@media (min-width: $xxs) {
				grid-column: initial;
			}
		}
	}

	.actions {
		span {
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.col-w-3 {
	width: 25%;
	flex: 0 0 25%;
}
</style>
