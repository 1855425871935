import router from "@/router"
import API from "../services"
// import CustomToastr from "@/shared/plugins/toastr"
// import i18n from "@/shared/plugins/i18n"

import {
	UPDATE_CUSTOMER_BY_ID,
	GET_CUSTOMER_DETAIL,
	GET_CUSTOMER_LIST,
	GET_CLINIC_LIST,
	GET_EXAMINATION_SCHEDULE,
	GET_STATUS_PREP_LIST,
	GET_RISK_LIST,
	GET_GIFT_LIST,
	CREATE_CUSTOMER,
	DELETE_CUSTOMER_BY_ID,
	GET_MESSAGE_LIST,
	GET_RISK_DETAIL,
	GET_GIFT_DETAIL,
	GET_REPORT_CUSTOMER_EXPORT,
	CREATE_APP_ACCOUNT,
} from "./action-types"

import {
	CREATE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER_BY_ID_SUCCESS,
	GET_CUSTOMER_DETAIL_SUCCESS,
	GET_CUSTOMER_DETAIL_FAILED,
	GET_CUSTOMER_LIST_SUCCESS,
	GET_CLINIC_LIST_SUCCESS,
	GET_EXAMINATION_SCHEDULE_SUCCESS,
	GET_STATUS_PREP_LIST_SUCCESS,
	GET_RISK_LIST_SUCCESS,
	GET_GIFT_LIST_SUCCESS,
	GET_MESSAGE_LIST_SUCCESS,
	GET_RISK_DETAIL_SUCCESS,
	GET_GIFT_DETAIL_SUCCESS,
	CREATE_USER_SUCCESS,
	CREATE_USER_FAILED,
} from "./mutation-types"

export default {
	[GET_CUSTOMER_LIST]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getCustomers(params)
			commit(GET_CUSTOMER_LIST_SUCCESS, { ...res, filters: params })
		} catch (error) {}
	},

	[GET_CLINIC_LIST]: async ({ commit }) => {
		try {
			const res = await API.getClinic()
			commit(GET_CLINIC_LIST_SUCCESS, res)
		} catch (error) {}
	},

	[GET_EXAMINATION_SCHEDULE]: async ({ commit }, { params = {} }) => {
		try {
			const res = await API.getExaminationSchedule(params)
			commit(GET_EXAMINATION_SCHEDULE_SUCCESS, res)
		} catch (error) {}
	},

	[GET_MESSAGE_LIST]: async ({ commit }, { params = {}, id }) => {
		try {
			const res = await API.getMessages(params, id)
			commit(GET_MESSAGE_LIST_SUCCESS, res)
		} catch (error) {}
	},

	[GET_STATUS_PREP_LIST]: async ({ commit }, { params = {}, id }) => {
		try {
			const res = await API.getStatusPrEP(params, id)
			commit(GET_STATUS_PREP_LIST_SUCCESS, res)
		} catch (error) {}
	},

	[GET_RISK_LIST]: async ({ commit }, { params = {}, id }) => {
		try {
			const res = await API.getEvaluation(params, id)
			commit(GET_RISK_LIST_SUCCESS, res)
		} catch (error) {}
	},

	[GET_GIFT_LIST]: async ({ commit }, { params = {} }) => {
		try {
			const res = await API.getProduct(params)
			commit(GET_GIFT_LIST_SUCCESS, res)
		} catch (error) {}
	},

	[CREATE_CUSTOMER]: async ({ commit }, params = {}) => {
		try {
			const res = await API.createCustomer(params)
			commit(CREATE_CUSTOMER_SUCCESS, res)

			router.push({ name: "CustomerManagement" })
		} catch (error) {}
	},

	[UPDATE_CUSTOMER_BY_ID]: async ({ commit }, { params = {}, id }) => {
		try {
			const res = await API.updateCustomerById(params, id)
			commit(UPDATE_CUSTOMER_BY_ID_SUCCESS, res)
			// CustomToastr.success(i18n.t("Toast.Update"))
			router.push({ name: "CustomerManagement" })
		} catch (error) {}
	},

	// eslint-disable-next-line
	[DELETE_CUSTOMER_BY_ID]: async ({}, { id }) => {
		try {
			await API.delCustomerById(id)
			router.push({ name: "CustomerManagement" })
			// CustomToastr.success(i18n.t("Toast.Delete"))
		} catch (error) {}
	},

	[GET_CUSTOMER_DETAIL]: async ({ commit }, id = {}) => {
		try {
			const res = await API.getCustomerById(id)
			commit(GET_CUSTOMER_DETAIL_SUCCESS, res)
		} catch (error) {
			commit(GET_CUSTOMER_DETAIL_FAILED)

			router.push({ name: "CustomerManagement" })
		}
	},

	[GET_RISK_DETAIL]: async ({ commit }, id = {}) => {
		try {
			const res = await API.getRiskById(id)
			commit(GET_RISK_DETAIL_SUCCESS, res)
		} catch (error) {
			router.push({ name: "CustomerManagement" })
		}
	},

	[GET_GIFT_DETAIL]: async ({ commit }, id = {}) => {
		try {
			const res = await API.getGiftById(id)
			commit(GET_GIFT_DETAIL_SUCCESS, res)
		} catch (error) {
			router.push({ name: "CustomerManagement" })
		}
	},

	// eslint-disable-next-line
	[GET_REPORT_CUSTOMER_EXPORT]: async ({}, params = {}) => {
		try {
			const res = await API.getCustomerExcel(params)
			return res
		} catch (error) {}
	},

	[CREATE_APP_ACCOUNT]: async ({ commit }, params = {}) => {
		try {
			const res = await API.createPrepUser(params)
			commit(CREATE_USER_SUCCESS, res)
			return res
		} catch (error) {
			commit(CREATE_USER_FAILED, error.response?.data?.code)
		}
	},
}
