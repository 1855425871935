import moment from "moment"
import Vue from "vue"

// defined key and value: https://momentjs.com/docs/#/displaying/fromnow
// config relative time: https://momentjs.com/docs/#/customization/relative-time-threshold

const locale = window.localStorage.getItem("locale") || process.env.VUE_APP_I18N_LOCALE || "vi"
moment.locale(locale)
moment.relativeTimeThreshold("s", 59)
moment.relativeTimeThreshold("ss", 3)
moment.relativeTimeThreshold("m", 60)
moment.relativeTimeThreshold("h", 24)

Vue.prototype.moment = moment
