import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins/constants"

const CustomerCreateAppt = () => import("./pages/CustomerCreateAppt")
const CustomerUpdateAppt = () => import("./pages/CustomerUpdateAppt")
const CustomerApptDetail = () => import("./pages/CustomerApptDetail")
const CustomerSearchForm = () => import("./pages/CustomerSearchForm")

const AppointmentModule = () => import("./AppointmentModule")
const AppointmentManagement = () => import("./pages/AppointmentManagement.vue")
const AdminCreateAppt = () => import("./pages/AdminCreateAppt.vue")
const AdminApptDetail = () => import("./pages/AdminApptDetail.vue")
const AdminUpdateAppt = () => import("./pages/AdminUpdateAppt.vue")

const appointmentRoutes = [
	{
		path: "/survey-appt",
		name: "CustomerCreateAppt",
		component: CustomerCreateAppt,
		meta: {
			title: i18n.t("Appointment.CreateAppointment"),
			label: i18n.t("Appointment.CreateAppointment"),
		},
	},
	{
		path: "/survey-appt/search",
		name: "CustomerSearchForm",
		component: CustomerSearchForm,
		meta: {
			title: i18n.t("Appointment.SearchForm"),
			label: i18n.t("Appointment.SearchForm"),
		},
	},
	{
		path: "/survey-appt/detail",
		name: "CustomerApptDetail",
		component: CustomerApptDetail,
		props: route => ({
			default: true,
			contactTool: route.query.contactTool,
			contactInfo: route.query.contactInfo,
			code: route.query.code,
		}),
		meta: {
			title: i18n.t("Appointment.AppointmentDetail"),
			label: i18n.t("Appointment.AppointmentDetail"),
		},
	},
	{
		path: "/survey-appt/success",
		name: "AppointmentSuccess",
		component: CustomerApptDetail,
		props: route => ({
			default: true,
			isSuccess: true,
			contactTool: route.query.contactTool,
			contactInfo: route.query.contactInfo,
			code: route.query.code,
		}),
		meta: {
			title: i18n.t("Appointment.AppointmentSuccess"),
			label: i18n.t("Appointment.AppointmentSuccess"),
		},
	},
	{
		path: "/survey-appt/update",
		name: "CustomerUpdateAppt",
		component: CustomerUpdateAppt,
		props: route => ({
			default: true,
			isUpdate: true,
			contactTool: route.query.contactTool,
			contactInfo: route.query.contactInfo,
			code: route.query.code,
		}),
		meta: {
			title: i18n.t("Appointment.UpdateAppointment"),
			label: i18n.t("Appointment.UpdateAppointment"),
		},
	},

	{
		path: "/appointments",
		name: "AppointmentModule",
		component: AppointmentModule,
		meta: {
			label: i18n.t("AppointmentTitle.AppointmentManagement"),
		},
		redirect: "/appointments/list",
		children: [
			{
				path: "list",
				name: "AppointmentManagement",
				component: AppointmentManagement,
				meta: {
					title: i18n.t("AppointmentTitle.AppointmentList"),
					label: i18n.t("AppointmentTitle.AppointmentList"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],
				},
			},
			{
				path: "create",
				name: "AdminCreateAppt",
				component: AdminCreateAppt,
				props: route => ({
					isAdmin: true,
					fromCustomer: route.query.fromCustomer,
				}),
				meta: {
					title: i18n.t("Appointment.CreateAppointment"),
					label: i18n.t("Appointment.CreateAppointment"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],
				},
			},
			{
				path: ":id",
				name: "AdminApptDetail",
				component: AdminApptDetail,
				props: route => ({
					id: route.params.id,
					isAdmin: true,
				}),
				meta: {
					title: i18n.t("Appointment.AppointmentDetail"),
					label: i18n.t("Appointment.AppointmentDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],
				},
			},
			{
				path: ":id/update",
				name: "AdminUpdateAppt",
				component: AdminUpdateAppt,
				props: route => ({
					id: route.params.id,
					isUpdate: true,
				}),
				meta: {
					title: i18n.t("Appointment.UpdateAppointment"),
					label: i18n.t("Appointment.UpdateAppointment"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],
				},
			},
			{
				path: ":*",
				redirect: "/appointments/list",
			},
		],
	},
]

export default appointmentRoutes
