export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const SEND_OTP_FORGOT = "SEND_OTP_FORGOT"
export const GET_OTP_AGAIN = "GET_OTP_AGAIN"
export const REFRESH_TOKEN = "REFRESH_TOKEN"
export const GET_USER_LOGIN_PROFILE = "GET_USER_LOGIN_PROFILE"
export const UPDATE_USER_LOGIN_PROFILE = "UPDATE_USER_LOGIN_PROFILE"
export const CHECK_TOKEN_VALID = "CHECK_TOKEN_VALID"
