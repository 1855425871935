import API from "../services"
import router from "@/router"
import {
	CREATE_BANNER,
	GET_BANNER_BY_ID,
	GET_LIST_BANNER,
	UPDATE_BANNER,
	DELETE_BANNER,
} from "./action-types"

import {
	DELETE_BANNER_SUCCESS,
	GET_BANNER_BY_ID_SUCCESS,
	GET_LIST_BANNER_SUCCESS,
} from "./mutation-types"

export default {
	[GET_LIST_BANNER]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getListBanner(params)
			commit(GET_LIST_BANNER_SUCCESS, { ...res, filters: params })
		} catch (error) {}
	},

	// eslint-disable-next-line no-unused-vars
	[CREATE_BANNER]: async ({ commit }, params = {}) => {
		try {
			await API.createBanner(params)
			router.push({ name: "BannerList" })
		} catch (error) {}
	},

	[GET_BANNER_BY_ID]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getBannerById(params)
			commit(GET_BANNER_BY_ID_SUCCESS, { ...res, filters: params })
		} catch (error) {}
	},

	// eslint-disable-next-line no-unused-vars
	[UPDATE_BANNER]: async ({ commit }, params = {}) => {
		try {
			await API.updateBanner(params)
			router.push({ name: "BannerList" })
		} catch (error) {}
	},

	[DELETE_BANNER]: async ({ commit }, params = {}) => {
		try {
			const res = await API.deleteBanner(params)
			commit(DELETE_BANNER_SUCCESS, { ...res, filters: params })
			router.push({ name: "BannerList" })
		} catch (error) {}
	},
}
