import Vue from "vue"
import Router from "vue-router"
import i18n from "@/shared/plugins/i18n"
import "@/shared/lang"
import SecureLS from "secure-ls"

const ls = new SecureLS({ isCompression: false })

// Views - All Modules's routes
import modulesRoutes from "./others"

// Containers
const TheContainer = () => import("@/shared/containers/TheContainer")

// Views
// Views - Pages
const Page404 = () => import("@/shared/pages/Page404")
const Page500 = () => import("@/shared/pages/Page500")

Vue.use(Router)

const appRoutes = [
	{
		path: "/",
		redirect: "/appointments/list",
		name: "Home",
		component: TheContainer,
	},
	{
		path: "/pages",
		redirect: "/pages/404",
		name: "Pages",
		component: {
			render(c) {
				return c("router-view")
			},
		},
		children: [
			{
				path: "404",
				name: "Page404",
				component: Page404,
				meta: {
					title: i18n.t("AppTitle.Page404"),
				},
			},
			{
				path: "500",
				name: "Page500",
				component: Page500,
				meta: {
					title: i18n.t("AppTitle.Page500"),
				},
			},
		],
	},
	{
		path: "*",
		redirect: "/",
	},
]

function configRoutes() {
	if (modulesRoutes && Object.values(modulesRoutes).length) {
		let allRoutes = new Set()
		Object.values(modulesRoutes).forEach(routes => {
			routes.forEach(route => {
				allRoutes.add(route)
			})
		})

		return [...Array.from(allRoutes), ...appRoutes]
	}

	return [...appRoutes]
}

const router = new Router({
	mode: "history",
	linkActiveClass: "active",
	scrollBehavior: () => ({ y: 0 }),
	routes: configRoutes(),
})

router.beforeEach((to, from, next) => {
	document.title = "Carezone - " + to.meta.title
	const vuex = ls.get("vuex") ? JSON.parse(ls.get("vuex")) : null
	const authUser = vuex?.authentication?.currentUser || null
	const homePath = "/users/customers"

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!authUser) {
			next({
				path: "/login",
				query: !to.fullPath.includes(homePath) && { redirect: to.fullPath },
			})
		} else if (
			(to.meta.role && to.meta.role.includes(authUser.role)) || // handle route with role permission
			!to.meta.role // handle route not set role permission
		) {
			next()
		}
	} else {
		const ignorePath = ["/login-otp", "/login", "/forgot-password", "/reset-password"]
		if (authUser && ignorePath.includes(to.path)) {
			next("/")
		} else {
			// check case reset password without token
			if (to.path === "/reset-password" && !to.query.token) {
				// next("/login")
				next()
			} else next()
		}
	}
})

export default router
