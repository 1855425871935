export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS"
export const RESET_FILTERS_ADMIN_LIST = "RESET_FILTERS_ADMIN_LIST"

export const GET_ADMIN_BY_ID_SUCCESS = "GET_ADMIN_BY_ID_SUCCESS"
export const DELETE_ADMIN_BY_ID_SUCCESS = "DELETE_ADMIN_BY_ID_SUCCESS"

export const GET_CLINIC_SUCCESS = "GET_CLINIC_SUCCESS"

export const CHANGE_STATUS_ADMIN_MODAL = "CHANGE_STATUS_ADMIN_MODAL"
export const CHANGE_STATUS_DISCARD_MODAL = "CHANGE_STATUS_DISCARD_MODAL"
export const CHANGE_STATUS_DELETE_MODAL = "CHANGE_STATUS_DELETE_MODAL"
export const CHANGE_STATUS_PASSWORD_MODAL = "CHANGE_STATUS_PASSWORD_MODAL"
