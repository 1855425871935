import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	AuthenTitle: {
		Login: "Đăng nhập",
		OTP: "Nhập mã OTP",
		ForgotPassword: "Quên mật khẩu",
		ResetPassword: "Đổi mật khẩu",
		Profile: "Thông tin cá nhân",
	},

	Login: {
		Title: "Đăng nhập",
		Email: "Email",
		Password: "Mật khẩu",
		Submit: "Đăng nhập",
		ForgotPassword: "Quên mật khẩu?",
		Register: "Đăng ký ngay!",
		hidePwd: "Ẩn mật khẩu",
		showPwd: "Hiện mật khẩu",
		Account: "Tên tài khoản",
		AccountPlaceholder: "Nhập tên tài khoản của bạn",
		PasswordPlaceholder: "Nhập mật khẩu của bạn",
		remember: "Nhớ tài khoản",
		EmailPlaceholder: "Nhập email của bạn",
	},

	OTP: {
		Title: "Nhập mã OTP",
		Field: "Mã OTP",
		Submit: "Xác nhận",
		Resend: "Nhận lại mã OTP",
	},

	ForgotPwd: {
		Title: "Quên mật khẩu",
		Description:
			"Hãy điền email của bạn dưới đây, chúng tôi sẽ gửi email hướng dẫn đến địa chỉ bạn cung cấp",
		Email: "Email",
		Send: "Gửi",
		Login: "Quay lại",
		messSuccess:
			"Đường link đặt lại mật khẩu đã được gửi đến email của bạn. Vui lòng kiểm tra email.",
	},

	ResetPwd: {
		Title_Reset: "Đặt mật khẩu",
		Title: "Đặt lại mật khẩu",
		Password: "Mật khẩu mới",
		ConfirmPassword: "Nhập lại mật khẩu",
		Submit: "Lưu",
		Cancel: "Hủy bỏ",
		hidePwd: "Ẩn mật khẩu",
		showPwd: "Hiện mật khẩu",
		ok: "OK",
		msgErr: "Link đã được sử dụng, không hợp lệ.",
		resetSuccess: "Đặt mật khẩu thành công.",
		resetAgainSuccess: "Mật khẩu đã được thay đổi thành công.",
	},

	Logout: {
		Title: "Đăng xuất",
		Message: "Bạn có muốn đăng xuất không ?",
		Submit: "Đăng xuất",
		Cancel: "Hủy bỏ",
	},

	ChangePwd: {
		Title: "Thay đổi mật khẩu",
		OldPassword: "Mật khẩu cũ",
		CurrentPassword: "Mật khẩu hiện tại",
		NewPassword: "Mật khẩu mới",
		ConfirmPassword: "Xác nhận mật khẩu mới",
		Submit: "Lưu",
		Cancel: "Huỷ bỏ",
		hidePwd: "Ẩn mật khẩu",
		showPwd: "Hiện mật khẩu",
		Success: "Thay đổi mật khẩu thành công",
	},

	Profile: {
		Title: "Thông tin cá nhân",
		Email: "Email đăng nhập",
		Name: "Tên",
		PhoneNumber: "Số điện thoại",
		Status: "Trạng thái",
		ManagePK: "Quản lý PK",
		Role: "Quyền",
		Edit: "Cập nhật",
		UpdateTitle: "Cập nhật thông tin",
		Submit: "Lưu",
		Cancel: "Huỷ bỏ",
		Success: "Cập nhật thông tin thành công",
		Active: "Hoạt động",
		Inactive: "Không hoạt động",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			auth_username: "Email",
			auth_email: "Email",
			auth_password: "Mật khẩu",
			auth_old_password: "Mật khẩu cũ",
			auth_new_password: "Mật khẩu mới",
			auth_confirm_password: "Xác nhận mật khẩu mới",
			auth_otp: "Mã OTP",
			auth_full_name: "Họ và tên",
			auth_phone: "Số điện thoại",
			auth_account: "Tên tài khoản",
		},
	},

	validation: {
		...vi.messages,
		...{
			// custom message rules
			// confirmedBy: "{_field_} không chính xác. Vui lòng nhập lại.",
			confirmedBy: "Mật khẩu mới & Xác nhận mật khẩu mới không khớp",
			confirmedByOldPW: "Mật khẩu mới phải khác với mật khẩu cũ",
			phone: "{_field_} không hợp lệ",
			requiredChar: "Vui lòng nhập {_field_}",
			requiredEmail: "Vui lòng nhập địa chỉ {_field_}",
		},
	},
})
