import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	MessageTitle: {
		Message: "Thông điệp",
		MessageManagement: "Quản lý Thông điệp",
		MessageList: "Danh sách Thông điệp",
		CreateMessage: "Tạo mới Thông điệp",
		MessageID: "Thông điệp ID {id}",
		UpdateMessage: "Chỉnh sửa Thông điệp",
	},

	MessageForm: {
		No: "STT",
		ID: "ID",
		All: "Tất cả",
		MSM: "MSM",
		TransWoman: "Chuyển giới nữ",
		TransMan: "Chuyển giới nam",
		TCMT: "TCMT",
		Prostitute: "Người bán dâm",
		HeterosexualPartner: "Bạn tình dị nhiễm",
		Other: "Khác",
		CustomerObject: "Đối tượng",
		CustomerStatus: "Trạng thái đối tượng",
		Active: "Đang hoạt động",
		Inactive: "Tạm dừng",
		Content: "Nội dung",
		MessageContent: "Nội dung thông điệp",
		ShortMessage: "Thông điệp ngắn",
		SendingTimes: "Gửi vào tháng thứ",
		Month: "Tháng {month}",
		Week: "W {week}",
		With: "Với",
		Times: "Lần",
		Using: "Sử dụng",
		Actions: "Actions",
		DeleteDescription:
			"Khi xác nhận xóa thông điệp này. Khách hàng sẽ không nhận được thông điệp này nữa.",
		OtherObjects: "và {count} đối tượng khác",
		FieldRequired: "Thông tin này là bắt buộc",
		RequiredSendTime: "Bạn vui lòng nhập số lần gửi trong một tuần",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			admin_object: "Thông tin này",
			admin_status: "Thông tin này",
			admin_message: "Thông tin này",
			admin_short_message: "Thông tin này",
			admin_time: "Thông tin này",
		},
	},
	validation: {
		...vi.messages,
		...{
			// custom message rules
			integer: "{_field_} phải là một số nguyên",
		},
	},
})
