<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol xl="12">
						<BannerForm @apply-form="createBanner" @cancel-form="goToBannerList"></BannerForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { CREATE_BANNER } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("banners")
export default {
	name: "CreateBanner",

	components: {
		BannerForm: () => import("./../components/BannerForm.vue"),
	},

	props: {},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {},

	methods: {
		...mapActions({ CREATE_BANNER }),

		createBanner: async function (params) {
			await this.CREATE_BANNER(params)
		},

		goToBannerList() {
			this.$router.push({ name: "BannerList" })
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
</style>
