import i18n from "@/shared/plugins/i18n"
// eslint-disable-next-line no-unused-vars
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	TopicTitle: {
		Name: "Topic",
		TopicManagement: "Topic Management",
	},
	TopicHeader: {
		TopicContent: "Nội dung hỏi đáp giao lưu",
		Customer: "Khách hàng",
		Type: "Phân loại",
		Time: "Thời gian",
		Category: "Chuyên mục",
		Question: "Câu hỏi",
	},
	TopicType: {
		QUESTION: "Question",
		ANSWER: "Answer",
	},
	CATEGORY: {
		HIV: "Câu hỏi",
		PREP: "Trả lời",
		ART: "ART",
		OTHER: "Khác",
		FRIEND: "Kết bạn",
		BLOG: "Blog tâm sự",
		COVID: "Covid",
	},
})
