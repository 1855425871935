import { callAPI } from "@/shared/services"

export default {
	getCustomers: params => {
		return callAPI("GET", "admin/customer", { params }).then(res => res.data)
	},

	getClinic: () => {
		return callAPI("GET", "clinic", {
			notLoading: true,
		}).then(res => res.data)
	},

	createCustomer: params => {
		return callAPI("POST", "admin/customer", params).then(res => res.data)
	},

	updateCustomerById: (params, id) => {
		return callAPI("POST", `admin/customer/${id}`, params).then(res => res.data)
	},

	getCustomerById: (id, params) => {
		return callAPI("GET", `admin/customer/${id}`, { params }).then(res => res.data)
	},

	getExaminationSchedule: params => {
		return callAPI("GET", "admin/appointment/history", { params }).then(res => res.data)
	},

	getMessages: (params, id) => {
		return callAPI("GET", `admin/customer/${id}/prep-messages`, { params }).then(res => res.data)
	},

	getStatusPrEP: (params, id) => {
		return callAPI("GET", `admin/customer/${id}/prep-treatment`, { params }).then(res => res.data)
	},

	getEvaluation: (params, id) => {
		return callAPI("GET", `admin/customer/${id}/evaluation`, { params }).then(res => res.data)
	},

	getProduct: params => {
		return callAPI("GET", `admin/product-booking`, { params }).then(res => res.data)
	},

	delCustomerById: id => {
		return callAPI("DELETE", `admin/customer/${id}`).then(res => res.data)
	},

	getRiskById: id => {
		return callAPI("GET", `risk-accessment/${id}`).then(res => res.data)
	},

	getGiftById: id => {
		return callAPI("GET", `admin/product-booking/${id}`).then(res => res.data)
	},

	getCustomerExcel(params) {
		return callAPI("GET", `admin/customer/export`, {
			params,
			timeout: process.env.VUE_APP_TIMEOUT_IMPORT,
		}).then(response => {
			const url = response.data.fileUrl
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", null)
			document.body.appendChild(link)
			link.click()
		})
	},

	createPrepUser: id => {
		return callAPI("POST", `users/prep/${id}`, null, { skipToast: true })
	},
}
