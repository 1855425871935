import { callAPI } from "@/shared/services"

export default {
	getListNotification: params => {
		return callAPI("GET", "notification/management", { params }).then(res => res.data)
	},

	createNotification: params => {
		return callAPI("POST", "notification/push-notification", params).then(res => res.data)
	},

	getCustomers: params => {
		return callAPI("GET", "admin/customer/search", {
			params,
			notLoading: true,
		}).then(res => res.data)
	},
}
