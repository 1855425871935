import Vue from "vue"
import { REGEX } from "./constants"

import {
	ValidationProvider,
	ValidationObserver,
	extend,
	localize,
	configure,
	setInteractionMode,
} from "vee-validate"
import en from "vee-validate/dist/locale/en.json"
import vi from "vee-validate/dist/locale/vi.json"
import * as rules from "vee-validate/dist/rules"
import i18n from "@/shared/plugins/i18n"

// Add a rule.
extend("confirmedBy", {
	params: ["target"],
	validate(value, { target }) {
		return value === target
	},
})

extend("confirmedByOldPW", {
	params: ["target"],
	validate(value, { target }) {
		return value !== target
	},
})

extend("phone", {
	validate(value) {
		const valid = /^[0-9]*$/g
		return valid.test(value)
	},
})

extend("month", {
	validate(value) {
		const valid = /^(0|0[0-9]|1[0-2])$/g
		return valid.test(value)
	},
})

extend("phoneNumber", {
	validate(value) {
		return REGEX.IS_NUMBER.test(value)
	},
})

extend("isEmail", {
	validate(value) {
		return REGEX.IS_EMAIL.test(value)
	},
})

extend("minmax_value", {
	validate(value, { min, max }) {
		return +value >= min && +value <= max
	},
	params: ["min", "max"],
})

extend("requiredExport", {
	validate(value) {
		return {
			required: true,
			valid: ["", null, undefined].indexOf(value) === -1,
		}
	},
	computesRequired: true,
})

extend("requiredChar", {
	validate(value) {
		return {
			required: true,
			valid: value && value.length,
		}
	},
	computesRequired: true,
})

extend("requiredEmail", {
	validate(value) {
		return {
			required: true,
			valid: value && value.length,
		}
	},
	computesRequired: true,
})

extend("isUrl", {
	validate(value) {
		return value.match(REGEX.IS_URL)
	},
})

// Import all rules
Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule])
})

// Register it globally
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

// config interaction mode
setInteractionMode("aggressive")

// Install English and Vietnamese locales.
localize({
	en,
	vi,
})

// Activate locale
const locale = window.localStorage.getItem("locale") || process.env.VUE_APP_I18N_LOCALE || "vi"

function setLocale() {
	switch (locale) {
		case "vi":
			return vi

		default:
			return en
	}
}

localize(locale, setLocale())

// Config options
const config = {
	classes: {
		valid: "is-valid",
		invalid: "is-invalid",
	},
	defaultMessage: (field, values) => {
		// get all fields were overridden
		const fields = { ...i18n.t(`fields`) }

		// check field name exsists in fields
		if (Object.prototype.hasOwnProperty.call(fields, field)) {
			// override the field name.
			values._field_ = i18n.t(`fields.${field}`)

			// overide the custom field name
			if (values.target) {
				values.target = i18n.t(`fields.${values.target}`)
			}
		}

		return i18n.t(`validation.${values._rule_}`, values)
	},
}

// Sets the options.
configure(config)
