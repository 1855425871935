import { addPadStartTime } from "@/shared/utils/mixins"
import store from "@/store"

import {
	UPDATE_CUSTOMER_BY_ID_SUCCESS,
	GET_CUSTOMER_DETAIL_SUCCESS,
	GET_CUSTOMER_DETAIL_FAILED,
	RESET_FILTERS_TABLE_DETAIL,
	GET_CUSTOMER_LIST_SUCCESS,
	GET_CLINIC_LIST_SUCCESS,
	CREATE_CUSTOMER_SUCCESS,
	GET_EXAMINATION_SCHEDULE_SUCCESS,
	GET_STATUS_PREP_LIST_SUCCESS,
	GET_RISK_LIST_SUCCESS,
	RESET_CUSTOMER_DETAIL,
	GET_MESSAGE_LIST_SUCCESS,
	GET_RISK_DETAIL_SUCCESS,
	SET_CUSTOMER_FOR_APPT,
	GET_GIFT_LIST_SUCCESS,
	GET_GIFT_DETAIL_SUCCESS,
	RESET_FILTERS_CUSTOMER_LIST,
	CHANGE_STATUS_CUSTOMER_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	CHANGE_STATUS_DISCARD_MODAL,
	RESET_RECENT_CUSTOMER,
	CHANGE_STATUS_CREATE_MODAL,
	CREATE_USER_SUCCESS,
	CREATE_USER_FAILED,
} from "./mutation-types"
import { AUTO_CREATE_USER_RESULT } from "../../../shared/plugins"

export default {
	[GET_CUSTOMER_LIST_SUCCESS]: function (state, payload = {}) {
		state.customers = payload.items
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[RESET_FILTERS_CUSTOMER_LIST]: function (state) {
		state.customers = null
		state.totalItems = null
		state.totalPages = null

		state.recentFilters = {
			keyword: null,
			status: null,
			types: null,
			riskEvaluation: null,
			prepStartDate: null,
			prepEndDate: null,
			limit: process.env.VUE_APP_LIMIT_LIST,
			offset: 0,
		}
	},

	[RESET_FILTERS_TABLE_DETAIL]: function (state) {
		state.customers = null
		state.schedule = null
		state.messages = null
		state.prepList = null
		state.riskList = null
		state.products = null
		state.totalItems = null
		state.totalPages = null
		state.timeDrug = null
	},

	[RESET_RECENT_CUSTOMER]: function (state) {
		state.recentCustomer = null
		state.customers = null
	},

	[GET_CLINIC_LIST_SUCCESS]: function (state, payload = {}) {
		state.clinics = payload
	},

	[GET_EXAMINATION_SCHEDULE_SUCCESS]: function (state, payload = {}) {
		state.schedule = payload.data
		state.totalItems = payload.total
	},

	[GET_MESSAGE_LIST_SUCCESS]: function (state, payload = {}) {
		state.messages = payload.items
		state.totalItems = payload.total
	},

	[GET_STATUS_PREP_LIST_SUCCESS]: function (state, payload = {}) {
		state.prepList = payload.items || []
		state.totalItems = payload.total
		state.timeDrug = `${addPadStartTime(payload.dailyTakenHour)}:${addPadStartTime(
			payload.dailyTakenMinute,
		)}`
	},

	[GET_RISK_LIST_SUCCESS]: function (state, payload = {}) {
		state.riskList = payload.items
		state.totalItems = payload.total
	},

	[GET_GIFT_LIST_SUCCESS]: function (state, payload = {}) {
		state.products = payload.productBookings
		state.totalItems = payload.total
	},

	[CREATE_CUSTOMER_SUCCESS]: function (state, payload = {}) {
		state.recentCustomer = payload
	},

	[UPDATE_CUSTOMER_BY_ID_SUCCESS]: function (state, payload = {}) {
		state.recentCustomer = payload
	},

	[GET_RISK_DETAIL_SUCCESS]: function (state, payload = {}) {
		state.recentRisk = payload
	},

	[GET_GIFT_DETAIL_SUCCESS]: function (state, payload = {}) {
		state.recentGift = payload
	},

	[GET_CUSTOMER_DETAIL_SUCCESS]: function (state, payload = {}) {
		state.recentCustomer = payload
	},

	[GET_CUSTOMER_DETAIL_FAILED]: function (state) {
		state.recentCustomer = null
	},

	[RESET_CUSTOMER_DETAIL]: function (state) {
		state.recentCustomer = null
	},

	[SET_CUSTOMER_FOR_APPT]: function (state, payload = {}) {
		state.customerAppt = payload
	},

	[CHANGE_STATUS_CUSTOMER_MODAL]: function (state, payload = {}) {
		state.isShowStatusModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DELETE_MODAL]: function (state, payload = {}) {
		state.isShowDeleteModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DISCARD_MODAL]: function (state, payload = {}) {
		state.isShowDiscardModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_CREATE_MODAL]: function (state) {
		state.isShowCreateModal = false
	},

	[CREATE_USER_SUCCESS]: function (state) {
		state.createUserResult = AUTO_CREATE_USER_RESULT.SUCCESS
		store.commit("set", ["modalShow", true])
		state.isShowCreateModal = true
	},

	[CREATE_USER_FAILED]: function (state, payload = {}) {
		state.createUserResult = payload
		store.commit("set", ["modalShow", true])
		state.isShowCreateModal = true
	},
}
