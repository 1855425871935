import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins"
const AdminTopicManagement = () => import("./pages/AdminTopicManagement")
const TopicDeepLink = () => import("./pages/TopicDeepLink")
const TopicModule = () => import("./TopicModule")

const topicRoutes = [
	{
		path: "/topics",
		name: "TopicModule",
		component: TopicModule,
		meta: {
			label: i18n.t("TopicTitle.Name"),
		},
		redirect: "/topics/list",
		children: [
			{
				path: "list",
				name: "TopicList",
				component: AdminTopicManagement,
				meta: {
					title: i18n.t("TopicTitle.TopicManagement"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
		],
	},
	{
		path: "/ref",
		name: "TopicDeepLink",
		component: TopicDeepLink,
		props: route => ({
			type: route.query.type,
			id: route.query.id,
		}),
		meta: {
			title: "CAREZONE",
		},
	},
]

export default topicRoutes
