import { callAPI } from "@/shared/services"

export default {
	getMessages: params => {
		return callAPI("GET", "admin/message/", { params }).then(res => res.data)
	},

	getMessageById: params => {
		return callAPI("GET", `admin/message/${params.id}`).then(res => res.data)
	},

	createMessage: params => {
		return callAPI("POST", "admin/message", params).then(res => res.data)
	},

	updateMessage: params => {
		return callAPI("POST", `admin/message/${params.id}`, params).then(res => res.data)
	},

	deleteMessage: params => {
		return callAPI("DELETE", `admin/message/${params.id}`, params).then(res => res.data)
	},
}
