import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins"

const ClinicList = () => import("./pages/ClinicList.vue")
const CreateClinic = () => import("./pages/CreateClinic.vue")
const UpdateClinic = () => import("./pages/UpdateClinic.vue")

const ClinicModule = () => import("./ClinicModule.vue")

const clinicRoutes = [
	{
		path: "/clinics",
		name: "ClinicModule",
		component: ClinicModule,
		meta: {
			label: i18n.t("ClinicTitle.ClinicManagement"),
		},
		redirect: "/clinics/list",
		children: [
			{
				path: "list",
				name: "ClinicList",
				component: ClinicList,
				meta: {
					title: i18n.t("ClinicTitle.ClinicList"),
					label: i18n.t("ClinicTitle.ClinicList"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],
				},
			},
			{
				path: "create",
				name: "CreateClinic",
				component: CreateClinic,
				meta: {
					title: i18n.t("ClinicTitle.CreateClinic"),
					label: i18n.t("ClinicTitle.CreateClinic"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],
				},
			},
			{
				path: ":id/update",
				name: "UpdateClinic",
				component: UpdateClinic,
				props: route => ({
					default: true,
					id: route.params.id,
				}),
				meta: {
					title: i18n.t("ClinicTitle.UpdateClinic"),
					label: i18n.t("ClinicTitle.UpdateClinic"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],
				},
			},
			{
				path: ":*",
				redirect: "/clinics/list",
			},
		],
	},
]

export default clinicRoutes
