import store from "@/store"
import { unionBy } from "lodash-es"

import {
	// Start of Admin Side
	GET_APPOINTMENT_LIST_SUCCESS,
	RESET_FILTERS_APPT_LIST,
	GET_APPT_BY_ID_SUCCESS,
	GET_CUSTOMERS_SUCCESS,
	RESET_CUSTOMERS,
	SEND_IMPORT_FILE_SUCCESS,
	GET_IMPORT_STATUS_SUCCESS,
	GET_IMPORT_STATUS_FAILED,
	RESET_IMPORT_STATUS,
	CLEAR_INTERVAL_IMPORT,
	CHANGE_UPLOAD_FILE_STATUS,
	CHANGE_PROGRESS_BAR_MODAL_STATUS,
	CHANGE_IMPORT_SUCCESS_MODAL_STATUS,
	CHANGE_STATUS_DISCARD_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	// End of Admin Side

	// Start of Customer Side
	SEARCH_APPT_SUCCESS,
	CANCEL_APPOINTMENT_SUCCESS,
	GET_MAIN_CITIES_SUCCESS,
	// End of Customer Side

	// Both
	GET_SERVICES_SUCCESS,
	GET_CLINICS_SUCCESS,
	RESET_CLINICS,
	RESET_APPT_FORM,
	RESET_DATA_INPUT_FILTERS,
} from "./mutation-types"

export default {
	// Start of Admin Side
	[GET_APPOINTMENT_LIST_SUCCESS]: function (state, payload = {}) {
		state.appointment = payload.data
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[RESET_FILTERS_APPT_LIST]: function (state) {
		state.appointment = null
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			key: null,
			serviceIds: null,
			clinicIds: null,
			statuses: null,
			customerTypes: null,
			limit: process.env.VUE_APP_LIMIT_LIST,
			offset: 0,
		}
	},

	[GET_APPT_BY_ID_SUCCESS]: function (state, payload = {}) {
		state.recentAppt = payload
	},

	[GET_CUSTOMERS_SUCCESS]: function (state, payload = {}) {
		state.customers = unionBy(state.customers, payload.items, "id")
		state.totalCustomer = payload.total
		state.filtersCustomer = { ...payload.filters }
	},

	[RESET_CUSTOMERS]: function (state) {
		state.customers = []
		state.filtersCustomer = {
			limit: Number(process.env.VUE_APP_LIMIT_LIST),
			offset: 0,
		}
	},

	[SEND_IMPORT_FILE_SUCCESS]: function (state, payload = {}) {
		state.importFileId = payload.importId
	},

	[GET_IMPORT_STATUS_SUCCESS]: function (state, payload = {}) {
		state.importStatus = payload
		state.importFileId = payload.id
	},

	[GET_IMPORT_STATUS_FAILED]: function (state) {
		state.importStatus = null
		state.importFileId = null
		state.isShowProgressBar = false
		state.isShowShortcutImport = false
	},

	[RESET_IMPORT_STATUS]: function (state) {
		state.importStatus = null
		state.importFileId = null
	},

	[CLEAR_INTERVAL_IMPORT]: function (state) {
		clearInterval(state.intervalImport)
		state.intervalImport = null
	},

	[CHANGE_UPLOAD_FILE_STATUS]: function (state, payload = {}) {
		state.isUploadingFile = payload
	},

	[CHANGE_PROGRESS_BAR_MODAL_STATUS]: function (state, payload = {}) {
		state.isShowProgressBar = payload.isShowProgressBar
		state.isShowShortcutImport = payload.isShowShortcutImport
		store.commit("set", ["modalShow", payload.isShowProgressBar && !payload.isShowShortcutImport])
	},

	[CHANGE_IMPORT_SUCCESS_MODAL_STATUS]: function (state, payload = {}) {
		state.isShowImportSuccess = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DISCARD_MODAL]: function (state, payload = {}) {
		state.isShowDiscardModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DELETE_MODAL]: function (state, payload = {}) {
		state.isShowDelModal = payload
		store.commit("set", ["modalShow", payload])
	},
	// End of Admin Side

	// Start of Customer Side
	[SEARCH_APPT_SUCCESS]: function (state, payload = {}) {
		state.recentAppt = payload
	},

	[CANCEL_APPOINTMENT_SUCCESS]: function (state) {
		state.recentAppt = null
	},
	// End of Customer Side

	// Both
	[GET_MAIN_CITIES_SUCCESS]: function (state, payload = {}) {
		state.mainCities = payload
	},

	[GET_SERVICES_SUCCESS]: function (state, payload = {}) {
		state.services = payload
	},

	[GET_CLINICS_SUCCESS]: function (state, payload = {}) {
		state.clinics = payload
	},

	[RESET_CLINICS]: function (state) {
		state.clinics = []
	},

	[RESET_APPT_FORM]: function (state) {
		state.recentAppt = null
		state.mainCities = []
		state.services = []
		state.clinics = []
	},

	[RESET_DATA_INPUT_FILTERS]: function (state) {
		state.services = []
		state.clinics = []
	},
}
