import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins"

const CustomerManagement = () => import("./pages/CustomerManagement.vue")
const CustomerDetail = () => import("./pages/CustomerDetail.vue")
const CreateCustomer = () => import("./pages/CreateCustomer.vue")
const CustomerResultDetail = () => import("./components/CustomerResultDetail.vue")
const CustomerGiftDetail = () => import("./components/CustomerGiftDetail.vue")

const CustomerModule = () => import("./CustomerModule.vue")

const customerRoutes = [
	{
		path: "/customers",
		name: "CustomerModule",
		component: CustomerModule,
		meta: {
			label: i18n.t("CustomerTitle.CustomerManagement"),
		},
		redirect: "/customers/list",
		children: [
			{
				path: "list",
				name: "CustomerManagement",
				component: CustomerManagement,
				meta: {
					title: i18n.t("CustomerTitle.CustomerList"),
					label: i18n.t("CustomerTitle.CustomerList"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: "create",
				name: "CreateCustomer",
				component: CreateCustomer,
				meta: {
					title: i18n.t("CustomerTitle.CreateCustomer"),
					label: i18n.t("CustomerTitle.CreateCustomer"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: ":id",
				name: "CustomerDetail",
				component: CustomerDetail,
				props: true,
				meta: {
					title: i18n.t("CustomerTitle.CustomerDetail"),
					label: i18n.t("CustomerTitle.CustomerDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: "risk/:id",
				name: "CustomerResultDetail",
				component: CustomerResultDetail,
				props: true,
				meta: {
					title: i18n.t("CustomerTitle.CustomerResultDetail"),
					label: i18n.t("CustomerTitle.CustomerResultDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: "risk/:id",
				name: "CustomerResultDetail",
				component: CustomerResultDetail,
				props: true,
				meta: {
					title: i18n.t("CustomerTitle.CustomerResultDetail"),
					label: i18n.t("CustomerTitle.CustomerResultDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: "gift/:id",
				name: "CustomerGiftDetail",
				component: CustomerGiftDetail,
				props: true,
				meta: {
					title: i18n.t("CustomerTitle.CustomerGiftDetail"),
					label: i18n.t("CustomerTitle.CustomerGiftDetail"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: ":*",
				redirect: "/customers/list",
			},
		],
	},
]

export default customerRoutes
