import router from "@/router"
import API from "../services"

import {
	GET_CLINICS,
	GET_CLINIC_BY_ID,
	CREATE_CLINIC,
	UPDATE_CLINIC,
	DELETE_CLINIC,
	GET_CITIES,
	GET_SERVICES,
} from "./action-types"

import {
	GET_CLINICS_SUCCESS,
	GET_CLINIC_BY_ID_SUCCESS,
	DELETE_CLINIC_SUCCESS,
	GET_CITIES_SUCCESS,
	GET_CITIES_FAILED,
	GET_SERVICES_SUCCESS,
} from "./mutation-types"

export default {
	[GET_CLINICS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getClinics(params)
			commit(GET_CLINICS_SUCCESS, { ...res, filters: params })
		} catch (error) {}
	},

	[GET_CLINIC_BY_ID]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getClinicById(params)
			commit(GET_CLINIC_BY_ID_SUCCESS, res)
		} catch (error) {
			router.push({ name: "ClinicList" })
		}
	},

	// eslint-disable-next-line
	[CREATE_CLINIC]: async ({}, params = {}) => {
		try {
			await API.createClinic(params)

			router.push({ name: "ClinicList" })
		} catch (error) {}
	},

	// eslint-disable-next-line
	[UPDATE_CLINIC]: async ({}, params = {}) => {
		try {
			await API.updateClinic(params)

			router.push({ name: "ClinicList" })
		} catch (error) {}
	},

	[DELETE_CLINIC]: async ({ commit }, params = {}) => {
		try {
			const res = await API.deleteClinic(params)
			commit(DELETE_CLINIC_SUCCESS, res)

			router.push({ name: "ClinicList" })
		} catch (error) {}
	},

	[GET_CITIES]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getCities(params)
			commit(GET_CITIES_SUCCESS, res)
		} catch (error) {
			commit(GET_CITIES_FAILED)
		}
	},

	[GET_SERVICES]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getServices(params)
			commit(GET_SERVICES_SUCCESS, res)
		} catch (error) {}
	},
}
