import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins"

import CreateBanner from "./pages/CreateBanner"
import UpdateBanner from "./pages/UpdateBanner"
const AdminBannerManagement = () => import("./pages/AdminBannerManagement")
const BannerModule = () => import("./BannerModule")

const topicRoutes = [
	{
		path: "/banners",
		name: "BannerModule",
		component: BannerModule,
		meta: {
			label: i18n.t("BannerTitle.Name"),
		},
		redirect: "/banners/list",
		children: [
			{
				path: "list",
				name: "BannerList",
				component: AdminBannerManagement,
				meta: {
					title: i18n.t("BannerTitle.BannerManagement"),
					label: i18n.t("BannerTitle.BannerManagement"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
			{
				path: "create",
				name: "CreateBanner",
				component: CreateBanner,
				meta: {
					title: i18n.t("BannerTitle.BannerManagement"),
					label: i18n.t("BannerTitle.BannerManagement"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
			{
				path: "update/:id",
				name: "UpdateBanner",
				component: UpdateBanner,
				props: route => ({
					default: true,
					id: route.params.id,
				}),
				meta: {
					title: i18n.t("BannerTitle.BannerManagement"),
					label: i18n.t("BannerTitle.BannerManagement"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
		],
	},
]

export default topicRoutes
