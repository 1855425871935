import router from "@/router"
import API from "../services"
import CustomToastr from "@/shared/plugins/toastr"
import { IMPORT_STATUS } from "@/shared/plugins/constants"

import {
	// Start of Admin Side
	GET_APPOINTMENT_LIST,
	GET_APPT_BY_ID,
	ADMIN_CREATE_APPT,
	UPDATE_APPT_BY_ID,
	DELETE_APPT_BY_ID,
	GET_CUSTOMERS,
	IMPORT_FILE,
	GET_IMPORT_STATUS,
	INTERVAL_IMPORT,
	FINISH_IMPORT,
	EXPORT_FILE,
	MERGE_CONFLICT_IMPORT,
	// End of Admin Side

	// Start of Customer Side
	CREATE_APPOINTMENT,
	UPDATE_APPOINTMENT,
	CANCEL_APPOINTMENT,
	SEARCH_APPT,
	GET_MAIN_CITIES,
	// End of Customer Side

	// Both
	GET_SERVICES,
	GET_CLINICS,
} from "./action-types"

import {
	// Start of Admin Side
	GET_APPOINTMENT_LIST_SUCCESS,
	GET_APPT_BY_ID_SUCCESS,
	GET_CUSTOMERS_SUCCESS,
	SEND_IMPORT_FILE_SUCCESS,
	GET_IMPORT_STATUS_SUCCESS,
	GET_IMPORT_STATUS_FAILED,
	RESET_IMPORT_STATUS,
	CLEAR_INTERVAL_IMPORT,
	CHANGE_UPLOAD_FILE_STATUS,
	CHANGE_PROGRESS_BAR_MODAL_STATUS,
	CHANGE_IMPORT_SUCCESS_MODAL_STATUS,
	// End of Admin Side

	// Start of Customer Side
	SEARCH_APPT_SUCCESS,
	CANCEL_APPOINTMENT_SUCCESS,
	GET_MAIN_CITIES_SUCCESS,
	// End of Customer Side

	// Both
	GET_SERVICES_SUCCESS,
	GET_CLINICS_SUCCESS,
	RESET_CLINICS,
} from "./mutation-types"

export default {
	// Start of Admin Side
	[GET_APPOINTMENT_LIST]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getAppointment(params)
			commit(GET_APPOINTMENT_LIST_SUCCESS, { ...res, filters: params })
		} catch (error) {}
	},

	// eslint-disable-next-line
	[ADMIN_CREATE_APPT]: async ({}, params = {}) => {
		try {
			await API.adminCreateAppt(params)
			router.push({ name: "AppointmentManagement" })
		} catch (error) {}
	},

	[GET_APPT_BY_ID]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getApptById(params)
			commit(GET_APPT_BY_ID_SUCCESS, res)
		} catch (error) {
			router.push({ name: "AppointmentManagement" })
		}
	},

	// eslint-disable-next-line
	[UPDATE_APPT_BY_ID]: async ({}, params = {}) => {
		try {
			await API.updateApptById(params)

			router.push({ name: "AppointmentManagement" })
		} catch (error) {}
	},

	// eslint-disable-next-line
	[DELETE_APPT_BY_ID]: async ({}, params = {}) => {
		try {
			await API.deleteApptById(params)

			router.push({ name: "AppointmentManagement" })
		} catch (error) {}
	},

	[GET_CUSTOMERS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getCustomers(params)
			commit(GET_CUSTOMERS_SUCCESS, { ...res, filters: params })

			return res
		} catch (error) {}
	},

	// eslint-disable-next-line
	[IMPORT_FILE]: async ({ commit }, params = {}) => {
		try {
			commit(CHANGE_UPLOAD_FILE_STATUS, true)

			const res = await API.importFile(params)
			commit(SEND_IMPORT_FILE_SUCCESS, res)
			commit(CHANGE_PROGRESS_BAR_MODAL_STATUS, {
				isShowProgressBar: true,
				isShowShortcutImport: false,
			})
		} catch (error) {
		} finally {
			commit(CHANGE_UPLOAD_FILE_STATUS, false)
		}
	},

	[GET_IMPORT_STATUS]: async ({ commit, state }, params = {}) => {
		try {
			// Random to avoid "from disk cache"
			const rand = new Date().getTime()
			const res = await API.getImportStatus({
				...params,
				rand: rand,
			})

			if (res.status === IMPORT_STATUS.COMPLETED) {
				commit(CLEAR_INTERVAL_IMPORT)
				commit(RESET_IMPORT_STATUS)
				return
			}

			commit(GET_IMPORT_STATUS_SUCCESS, res)

			// Show modal of importing file
			if ([IMPORT_STATUS.VALIDATING, IMPORT_STATUS.IN_PROGRESS].includes(res.status)) {
				commit(CHANGE_PROGRESS_BAR_MODAL_STATUS, {
					isShowProgressBar: true,
					isShowShortcutImport: state.isShowShortcutImport,
				})
			}

			// Show modal of import successfully
			if (res.status === IMPORT_STATUS.IMPORTED) {
				commit(CHANGE_PROGRESS_BAR_MODAL_STATUS, {
					isShowProgressBar: false,
					isShowShortcutImport: false,
				})

				commit(CHANGE_IMPORT_SUCCESS_MODAL_STATUS, true)
			}
		} catch (error) {
			commit(GET_IMPORT_STATUS_FAILED, null)
		}
	},

	[INTERVAL_IMPORT]: ({ dispatch, state }, params = {}) => {
		state.intervalImport = setInterval(() => {
			dispatch(GET_IMPORT_STATUS, { id: state.importFileId })
		}, params)
	},

	[FINISH_IMPORT]: async ({ commit }, params = {}) => {
		try {
			await API.finishImport(params)

			commit(RESET_IMPORT_STATUS)
			commit(CHANGE_IMPORT_SUCCESS_MODAL_STATUS, false)
		} catch (error) {}
	},

	[MERGE_CONFLICT_IMPORT]: async (store, params = {}) => {
		try {
			await API.mergeConflictImport(params)
		} catch (error) {}
	},

	// eslint-disable-next-line
	[EXPORT_FILE]: async ({}, params = {}) => {
		try {
			const res = await API.exportFileAppt(params)
			return res
		} catch (error) {}
	},
	// End of Admin Side

	// Start of Customer Side
	// eslint-disable-next-line
	[CREATE_APPOINTMENT]: async ({}, params = {}) => {
		try {
			const res = await API.createAppointment(params)
			localStorage.removeItem("isCompletedRisk")
			// await window.parent.postMessage("create-new-appt", "*")
			const message = JSON.stringify({
				message: "create-new-appt",
				id: res.id,
			})
			await window.parent.postMessage(message, "*")

			router.push({
				path: "/survey-appt/success",
				query: { contactTool: res.contactTool, contactInfo: res.contactInfo, code: res.code },
			})
		} catch (error) {}
	},

	// eslint-disable-next-line
	[UPDATE_APPOINTMENT]: async ({}, params = {}) => {
		try {
			const res = await API.updateAppointment(params)

			router.push({
				path: "/survey-appt/detail",
				query: { contactTool: res.contactTool, contactInfo: res.contactInfo, code: res.code },
			})
		} catch (error) {}
	},

	[SEARCH_APPT]: async ({ commit }, params = {}) => {
		try {
			const res = await API.searchAppointment(params)
			commit(SEARCH_APPT_SUCCESS, res)

			if (router.history.current.name === "CustomerSearchForm")
				router.push({
					path: "/survey-appt/detail",
					query: { contactTool: res.contactTool, contactInfo: res.contactInfo, code: res.code },
				})
		} catch (error) {
			if (router.history.current.name !== "CustomerSearchForm")
				CustomToastr.error(error.response?.data?.message || error.message)
			else throw error.response?.data?.code
		}
	},

	[CANCEL_APPOINTMENT]: async ({ commit }, params = {}) => {
		try {
			const res = await API.cancelAppointment(params)
			commit(CANCEL_APPOINTMENT_SUCCESS, res)

			router.push({ name: "CustomerCreateAppt" })
		} catch (error) {}
	},
	// End of Customer Side

	// Both
	[GET_MAIN_CITIES]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getMainCities(params)
			const city = { HNO: null, HCM: null, DON: null }
			res.forEach(item => {
				city[item.code] = item
			})
			commit(GET_MAIN_CITIES_SUCCESS, Object.values(city))
		} catch (error) {}
	},

	[GET_SERVICES]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getServices(params)

			// // Sort by order of services
			// let services = Object.assign({}, new Array(res.length).fill(""))
			// res.forEach(service => {
			// 	// Sort by order of resultItems
			// 	let resultItems = Object.assign({}, new Array(service.resultItems.length).fill(""))
			// 	service.resultItems.forEach(result => {
			// 		resultItems[result.order - 1] = result
			// 	})
			// 	resultItems = Object.values(resultItems)

			// 	services[service.displayOrder - 1] = { ...service, resultItems }
			// })
			// services = Object.values(services)

			commit(GET_SERVICES_SUCCESS, res)
		} catch (error) {}
	},

	[GET_CLINICS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getClinics(params)
			commit(GET_CLINICS_SUCCESS, res)
		} catch (error) {
			commit(RESET_CLINICS)
		}
	},
}
