import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	AdminTitle: {
		AdminManagement: "Admin Management",
		AdminList: "Admin List",
		AdminDetail: "Information Detail",
		CreateAdmin: "Create new Admin",
		UpdateAdmin: "Update Admin",
	},

	AdminManagement: {
		No: "No.",
		Actions: "Actions",
	},

	AdminForm: {
		Name: "Name",
		Email: "Email",
		Status: "Status",
		Clinic: "Clinic",
		Permission: "Permission",
		SystemAdmin: "System Admin",
		ClinicAdmin: "Clinic Admin",
		ClinicSupport: "Clinic Support",
		CreateAdmin: "Create new Admin",
		UpdateAdmin: "Admin {name}",
		ChangePassword: "Change Password",
		DateCreated: "Date Created",
		Creator: "Creator",
		Reference: "Reference",
		ConfirmStatus:
			"Change status to inactive means block this admin from logging into Clinic web, which make he/she can't use app's features anymore.",
		Active: "Active",
		Inactive: "Inactive",
		DiscardChanges: "Discard changes?",
		AreYouSure: "Are you sure?",
		ID: "ID",
		PKManagement: "Clinic Management",
		Actions: "Actions",
		DeleteDescription:
			"Once confirm this account will be deleted in everywhere and this admin will lose permissions with Clinic web.",
		All: "All",
	},

	fields: {
		...{
			admin_name: "Input",
			admin_email: "Input",
			admin_status: "Input",
			admin_clinic: "Input",
			admin_permission: "Input",
			admin_new_password: "Input",
		},
	},

	validation: {
		...en.messages,
		...{
			// custom message rules
			isEmail: "The input field must be a valid email",
		},
	},
})
