import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	MessageTitle: {
		Message: "Message",
		MessageManagement: "Message Management",
		MessageList: "Message List",
		CreateMessage: "Create new Message",
		MessageID: "Message ID {id}",
		UpdateMessage: "Update Message",
	},

	MessageForm: {
		ID: "ID",
		All: "All",
		MSM: "MSM",
		TransWoman: "Trans Woman",
		TransMan: "Trans Man",
		TCMT: "TCMT",
		Prostitute: "Prostitute",
		HeterosexualPartner: "Heterosexual Partner",
		Other: "Other",
		CustomerObject: "Customer Object",
		CustomerStatus: "Customer Status",
		Active: "Active",
		Inactive: "Inactive",
		MessageContent: "Message Content",
		SendingTimes: "Sending times",
		Week: "Week",
		With: "With",
		Times: "Times",
		DeleteDescription:
			"Once confirm this message will be deleted. No more customers will receive this message.",
		OtherObjects: "and {count} other objects",
		FieldRequired: "Thông tin này là bắt buộc",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			admin_object: "Input",
			admin_status: "Input",
			admin_message: "Input",
			admin_short_message: "Input",
			admin_time: "Input",
		},
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
			integer: "The {_field_} field must be an integer",
		},
	},
})
