import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

const locale = window.localStorage.getItem("locale") || process.env.VUE_APP_I18N_LOCALE || "en"

export default new VueI18n({
	locale: locale,
	fallbackLocale: process.env.VUE_APP_I18N_LOCALE || "en",
	formatFallbackMessages: true,
	silentTranslationWarn: true,
	messages: {},
})
