import Vue from "vue"
import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"

Vue.use(Loading, {
	color: "#000000",
	loader: "spinner",
	height: 60,
	width: 60,
	lockScroll: true,
})
