import i18n from "@/shared/plugins/i18n"

const BookingForm = () => import("./pages/BookingForm")
const BookingSuccess = () => import("./components/BookingSuccess")

const bookingRoutes = [
	{
		path: "/survey-booking",
		name: "BookingForm",
		component: BookingForm,
		props: route => ({
			fullName: route.query.fullname,
			birthYear: route.query.birth,
			phoneNumber: route.query.phone,
		}),
		meta: {
			title: i18n.t("ProductBooking.BookingForm"),
			label: i18n.t("ProductBooking.BookingForm"),
		},
	},
	{
		path: "/survey-booking/success",
		name: "BookingSuccess",
		component: BookingSuccess,
		meta: {
			title: i18n.t("ProductBooking.BookingSuccess"),
			label: i18n.t("ProductBooking.BookingSuccess"),
		},
	},
]

export default bookingRoutes
