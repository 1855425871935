import router from "@/router"
import API from "../services"
import { COMMON_CHECK } from "@/shared/plugins/constants"

import {
	REGISTER_EVALUATION,
	RISK_ASSESSMENT,
	FINISH_ASSESSMENT,
	GET_RISK_QUESTIONS,
	RISK_ASSESSMENT_MOBILE,
	FINISH_ASSESSMENT_MOBILE,
} from "./action-types"

import { GET_RISK_QUESTIONS_SUCCESS } from "./mutation-types"

export default {
	// eslint-disable-next-line
	[REGISTER_EVALUATION]: ({}, params = {}) => {
		localStorage.setItem("riskInfo", JSON.stringify(params))

		router.push({ name: "AssessmentForm" })
	},

	// eslint-disable-next-line
	[RISK_ASSESSMENT]: async ({}, params = {}) => {
		try {
			const res = await API.riskAssessment(params)
			localStorage.setItem("riskResult", JSON.stringify(res))
			localStorage.setItem("isCompletedRisk", true)

			router.push({ name: "EvaluationResult" })
		} catch (error) {}
	},

	// eslint-disable-next-line
	[FINISH_ASSESSMENT]: async ({}, params = {}) => {
		try {
			await API.finishAssessment(params)
			localStorage.removeItem("riskResult")

			router.push({ name: "CompleteEvaluation" })
		} catch (error) {}
	},

	[GET_RISK_QUESTIONS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getRiskQuestions(params)

			const dataAssessment = {}
			for (const key in res) {
				for (const question of res[key]) {
					dataAssessment[question.code.toLowerCase()] = question.type !== "checkbox" ? null : []
					if (question.otherFreetext)
						dataAssessment[question.code.toLowerCase() + COMMON_CHECK.TEXT] = null
				}
			}

			commit(GET_RISK_QUESTIONS_SUCCESS, { riskQuestions: res, dataAssessment: dataAssessment })
		} catch (error) {}
	},

	// eslint-disable-next-line
	// eslint-disable-next-line
	[RISK_ASSESSMENT_MOBILE]: async ({}, params = {}) => {
		try {
			const res = await API.riskAssessment(params)
			localStorage.setItem("riskResult", JSON.stringify(res))
			localStorage.setItem("isCompletedRisk", true)

			router.push({ name: "EvaluationResultMobile" })
		} catch (error) {}
	},

	// eslint-disable-next-line
	[FINISH_ASSESSMENT_MOBILE]: async ({}, params = {}) => {
		try {
			await API.finishAssessment(params)
			localStorage.removeItem("riskResult")

			router.push({ name: "CompleteEvaluationMobile" })
		} catch (error) {}
	},
}
