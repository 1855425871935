export const USER_ROLE = {
	SYSTEM_ADMIN: "SYSTEM_ADMIN",
	USER_MEMBER: "USER_MEMBER",
	SYSTEM_CLINIC: "SYSTEM_CLINIC",
	SUPPORT_CLINIC: "SUPPORT_CLINIC",
	CBO_ADMIN: "CBO_ADMIN",
}

export const _USER_ROLE = {
	SYSTEM_ADMIN: "SYSTEM ADMIN",
	USER_MEMBER: "USER MEMBER",
	SYSTEM_CLINIC: "CLINIC ADMIN",
	SUPPORT_CLINIC: "CLINIC SUPPORT",
	CBO_ADMIN: "CBO ADMIN",
}

export const ADMIN_STATUS = {
	ACTIVE: "ACTIVE",
	BANNED: "BANNED",
}

export const REGEX = {
	IS_NUMBER: /^[0-9]*$/,
	IS_EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
	IS_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
}

export const TRUNCATED_TEXT_LENGTH = {
	DEFAULT: 50,
	EMAIL: 10,
	FULLNAME: 20,
}

export const BOOKING_SERVICES = {
	HA_NOI: "OraQuick",
	FREE_PREP: "PrEP",
	NO_SERVICES: "NONE",
}

export const BOOKING_ORAQUICK = {
	POST: "POST_OFFICE",
	GRAB: "GRAB",
	CLINIC: "DIRECT_AT_CLINIC",
}

export const BOOKING_CLINIC = {
	GLINK_HN: "GLINK",
	BIEN_VIET: "BIEN_VIET",
	VENUS_HN: "VENUS_HN",
	ALOCARE_HCM: "ALOCARE_HCM",
	GLINK_HCM: "GLINK_HCM",
	ALOCARE_DONGNAI: "ALOCARE_DONGNAI",
	GLINK_DONGNAI: "GLINK_DONGNAI",
}

export const _GENDER = {
	MALE: "MALE",
	FEMALE: "FEMALE",
}

export const ASSESSMENT_KNOW_PREP = {
	YES: "Đã biết",
	NO: "Chưa biết",
}

export const ASSESSMENT_TESTED = {
	YES: "Đã từng",
	NO: "Chưa từng",
}

export const ASSESSMENT_QUESTION = {
	Q1: "Q1",
	Q2: "Q2",
	Q3: "Q3",
	Q4: "Q4",
	Q5: "Q5",
	Q6: "Q6",
	Q7: "Q7",
	Q8: "Q8",
	Q9: "Q9",
	Q10: "Q10",
	Q11: "Q11",
	Q12: "Q12",
	Q13: "Q13",
	Q14: "Q14",
	Q15: "Q15",
	Q16: "Q16",
	Q17: "Q17",
	Q18: "Q18",
}

export const CONTACT_METHOD = {
	PHONE: "PHONE",
	MESSENGER: "MESSENGER",
	ZALO: "ZALO",
	VIBER: "VIBER",
	SKYPE: "SKYPE",
	WHATSAPP: "WHATSAPP",
	EMAIL: "EMAIL",
}

export const HIV_RESULTS = {
	NEGATIVE: "NEGATIVE",
	POSITIVE: "POSITIVE",
	NOT_AVAILABLE: "NOT_AVAILABLE",
}

export const APPT_STATUS = {
	PENDING: "PENDING",
	CUSTOMER_CANCEL: "CUSTOMER_CANCEL",
	COMPLETE: "COMPLETE",
}

export const CUSTOMER_TYPE = {
	PREP: "PREP",
	NORMAL: "NORMAL",
	UNOFFICIAL: "UNOFFICIAL",
}

export const STEP = {
	NEXT: "NEXT",
	BACK: "BACK",
}

export const COMMON_CHECK = {
	OTHER: "Khác",
	TEXT: "Text",
}

export const TYPE = {
	VERIFY_EMAIL: "VERIFY_EMAIL",
	RESET_PASSWORD: "RESET_PASSWORD",
}

export const STATUS = {
	ACTIVE: "ACTIVE",
	INACTIVE: "INACTIVE",
	PENDING: "PENDING",
	BANNED: "BANNED",
	CUSTOMER_CANCEL: "CUSTOMER_CANCEL",
	COMPLETE: "COMPLETE",
}

export const RISK_EVALUATION = {
	VERY_HIGH: "VERY_HIGH",
	HIGH: "HIGH",
	MEDIUM: "MEDIUM",
	LOW: "LOW",
	NOT_EVALUATE: "NOT_EVALUATE",
}

export const CUSTOMER_OBJECT = {
	ALL: "ALL",
	MSM: "MSM",
	TRANS_WOMAN: "TRANS_WOMAN",
	TRANS_MAN: "TRANS_MAN",
	TCMT: "TCMT",
	PROSTITUTE: "PROSTITUTE",
	OTHER: "OTHER",
	HETEROSEXUAL_PARTNER: "HETEROSEXUAL_PARTNER",
}

export const PAGING = {
	SKIP: 0,
	LIMIT: 10,
}

export const DROPDOWN_ACTIONS = {
	ADD: "ADD",
	REMOVE: "REMOVE",
}

export const CUSTOMER_CLASSIFY = {
	NEW: "NEW",
	OLD: "OLD",
}

export const MESS_MONTH = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]

export const MESS_WEEK = [1, 2, 3, 4]

export const MESS_TIMES = [1, 2, 3, 4, 5, 6, 7]

export const CUSTOMER_TABS = {
	INFOR: "INFOR",
	SCHEDULE: "SCHEDULE",
	MESSAGE: "MESSAGE",
	STATUS: "STATUS",
	RISK: "RISK",
	GIFT: "GIFT",
}

export const CLINIC_TYPE = {
	TU_NHAN: "TU_NHAN",
	CONG: "CONG",
	DNXH: "DNXH",
	CBO: "CBO",
}

export const CLINIC_STATUS = {
	ACTIVE: "ACTIVE",
	INACTIVE: "INACTIVE",
	DELETED: "DELETED",
}

export const APPT_SERVICES = {
	HIV_TEST: "Tư vấn & xét nghiệm HIV",
	HIV_TREATMENT: "Tư vấn & điều trị HIV",
	PREP_TREATMENT: "Khám, tư vấn & điều trị PREP",
	BLTQĐTD_TREATMENT: "Khám & điều trị BLTQĐTD",
	HEPATITIS_B_TREATMENT: "Khám & điều trị viêm gan B",
	HEPATITIS_C_TREATMENT: "Khám & điều trị viêm gan C",
	MIND_TREATMENT: "Tư vấn sức khỏe tâm trí",
	ABUSE_COUNSELING: "Tư vấn lạm dụng nghiện chất",
	HORMONE_COUNSELING: "Tư vấn & sử dụng Hormone",
}

export const INPUT_TYPE = {
	SELECT: "select_box",
	TEXT: "text_box",
}

export const MONTH = {
	JAN: "Jan",
	FEB: "Feb",
	MAR: "Mar",
	APR: "Apr",
	MAY: "May",
	JUN: "Jun",
	JUL: "Jul",
	AUG: "Aug",
	SEP: "Sep",
	OCT: "Oct",
	NOV: "Nov",
	DEC: "Dec",
}

export const ERROR_CODE = {
	APPOINTMENT_NOT_FOUND: "APPOINTMENT_NOT_FOUND",
}

export const INPUT_LENGTH = {
	CODE: 10,
	WEBSITE: 1000,
	WORKING_TIME: 100,
	INTRO: 150,
	VISIBLE_DROPDOWN: 3,
	OTHER: 200,
	SHORT_MESSAGE: 100,
	MESSAGE: 500,
	LIMIT_SELECTED: 10,
	CLINIC_NAME: 100,
}

export const IMPORT_STATUS = {
	VALIDATING: "VALIDATING",
	IN_PROGRESS: "IN_PROGRESS",
	IMPORTED: "IMPORTED",
	COMPLETED: "COMPLETED",
}

export const MANAGEMENT = {
	ADMIN: "ADMIN",
	CLINIC: "CLINIC",
}

export const REPORT_TYPE = {
	RISK_ASSESS: "RISK_ASSESS",
	ORAQUICK_PREP: "ORANQUICK_PREP",
	PLATFORM_INDEX: "PLATFORM_INDEX",
}

export const CREATE_APPOINTMENT_TYPE = {
	MANUAL: "MANUAL",
	AUTO: "AUTO",
}

export const CATEGORY = {
	HIV: 1,
	PREP: 2,
	ART: 3,
	OTHER: 4,
	FRIEND: 5,
	BLOG: 6,
	COVID: 7,
}

export const ADDRESS = {
	HN: "HN",
	DONGNAI: "DONGNAI",
	HCM: "HCM",
}

export const CITYID = {
	HN: 1,
	DONGNAI: 75,
	HCM: 79,
}

export const BANNER_TYPE = {
	TUTORIAL: "TUTORIAL",
	CAMPAIGN: "CAMPAIGN",
	BANNER: "BANNER",
}

export const GROUP_NOTIFICATION_TYPE = {
	PREP: "PREP",
	ALL: "ALL",
	ALL_DEVICE: "ALL_DEVICE",
	PERSONS: "PERSONS",
}

export const AUTO_CREATE_USER_RESULT = {
	SUCCESS: "SUCCESS",
	USER_HAS_BEEN_CREATED: "USER_HAS_BEEN_CREATED",
	PHONE_IS_EXISTED: "PHONE_IS_EXISTED",
}

export const VERIFIED_OPTION = {
	YES: "YES",
	NO: "NO",
}

export const CONFIG_SMS = {
	REMENDER: "REMENDER",
	FEEDBACK: "FEEDBACK",
	NONE: "NONE",
}
