export const GET_LIST_BANNER_SUCCESS = "GET_LIST_BANNER_SUCCESS"
export const RESET_FILTERS_BANNER_LIST = "RESET_FILTERS_BANNER_LIST"

export const GET_BANNER_BY_ID_SUCCESS = "GET_BANNER_BY_ID_SUCCESS"
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS"
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS"

export const CHANGE_STATUS_DISCARD_MODAL = "CHANGE_STATUS_DISCARD_MODAL"
export const CHANGE_STATUS_DELETE_MODAL = "CHANGE_STATUS_DELETE_MODAL"
export const CHANGE_STATUS_BANNER_MODAL = "CHANGE_STATUS_BANNER_MODAL"
