export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS"

export const UPDATE_CUSTOMER_BY_ID_SUCCESS = "UPDATE_CUSTOMER_BY_ID_SUCCESS"

export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS"
export const GET_CUSTOMER_DETAIL_FAILED = "GET_CUSTOMER_DETAIL_FAILED"

export const RESET_FILTERS_CUSTOMER_LIST = "RESET_FILTERS_CUSTOMER_LIST"
export const RESET_FILTERS_TABLE_DETAIL = "RESET_FILTERS_TABLE_DETAIL"
export const RESET_RECENT_CUSTOMER = "RESET_RECENT_CUSTOMER"

export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS"

export const GET_CLINIC_LIST_SUCCESS = "GET_CLINIC_LIST_SUCCESS"
export const GET_EXAMINATION_SCHEDULE_SUCCESS = "GET_EXAMINATION_SCHEDULE_SUCCESS"
export const GET_STATUS_PREP_LIST_SUCCESS = "GET_STATUS_PREP_LIST_SUCCESS"
export const GET_RISK_LIST_SUCCESS = "GET_RISK_LIST_SUCCESS"
export const GET_MESSAGE_LIST_SUCCESS = "GET_MESSAGE_LIST_SUCCESS"
export const RESET_CUSTOMER_DETAIL = "RESET_CUSTOMER_DETAIL"

export const GET_RISK_DETAIL_SUCCESS = "GET_RISK_DETAIL_SUCCESS"
export const SET_CUSTOMER_FOR_APPT = "SET_CUSTOMER_FOR_APPT"
export const GET_GIFT_LIST_SUCCESS = "GET_GIFT_LIST_SUCCESS"
export const GET_GIFT_DETAIL_SUCCESS = "GET_GIFT_DETAIL_SUCCESS"

export const CHANGE_STATUS_CUSTOMER_MODAL = "CHANGE_STATUS_CUSTOMER_MODAL"
export const CHANGE_STATUS_DELETE_MODAL = "CHANGE_STATUS_DELETE_MODAL"
export const CHANGE_STATUS_DISCARD_MODAL = "CHANGE_STATUS_DISCARD_MODAL"
export const CHANGE_STATUS_CREATE_MODAL = "CHANGE_STATUS_CREATE_MODAL"

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAILED = "CREATE_USER_FAILED"
