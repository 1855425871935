import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	ClinicTitle: {
		Clinic: "Phòng khám",
		ClinicManagement: "Quản lý Phòng khám",
		ClinicList: "Danh sách Phòng khám",
		CreateClinic: "Tạo mới Phòng khám",
		ClinicID: "Phòng khám ID {id}",
		UpdateClinic: "Chỉnh sửa Phòng khám",
		ClinicName: "Phòng khám {name}",
	},

	ClinicForm: {
		ID: "ID",
		Status: "Trạng thái",
		ClinicCode: "Mã phòng khám",
		ClinicName: "Tên phòng khám",
		Type: "Loại",
		Logo: "Logo",
		Address: "Địa chỉ",
		Province: "Thành phố",
		Phone: "Số điện thoại",
		Website: "Website",
		WorkingTime: "Giờ làm việc",
		ShortDesClinic: "Giới thiệu ngắn về phòng khám",
		Email: "Hỗ trợ trực tuyến",
		ConfirmStatus:
			"Thay đổi trạng thái thành dừng hoạt động thì phòng khám này sẽ không xuất hiện trong danh sách lựa chọn phòng khám của tất cả các form.",
		PrivateClinic: "PK tư nhân",
		PublicClinic: "PK công",
		DNXHClinic: "DNXH",
		CBOClinic: "CBO",
		ChooseFile: "Choose File",
		DeleteDescription: "Khi xác nhận Clinic này sẽ bị xóa.",
		PKCode: "Mã PK",
		Name: "Tên",
		Rating: "Điểm đánh giá",
		Actions: "Actions",
		Feedback: "Feedback link",
		LinkRating: "Link đánh giá",
		ErrorUpload: "Ảnh upload có dung lượng tối đa là 100kb",
		MaxUpload: "150px x 120px, dung lượng < 100kb",
		PlaceHolderName: "PK PrEP ghi tên theo Hmed (VAAC)",
		Search: "Nhập tên, địa chỉ hoặc giới thiệu ngắn để tìm kiếm",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			clinic_status: "Thông tin này",
			clinic_code: "Thông tin này",
			clinic_name: "Thông tin này",
			clinic_type: "Thông tin này",
			clinic_logo: "Thông tin này",
			clinic_address: "Thông tin này",
			clinic_city: "Thông tin này",
			clinic_phone: "Thông tin này",
			clinic_website: "Thông tin này",
			clinic_working_hour: "Thông tin này",
			clinic_short_intro: "Thông tin này",
			clinic_email: "Thông tin này",
			clinic_feedback: "Thông tin này",
		},
	},
	validation: {
		...vi.messages,
		...{
			// custom message rules
			integer: "{_field_} phải là một số nguyên",
			isEmail: "Thông tin này phải là một địa chỉ email hợp lệ",
		},
	},
})
