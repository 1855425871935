export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST"
export const CREATE_CUSTOMER = "CREATE_CUSTOMER"
export const UPDATE_CUSTOMER_BY_ID = "UPDATE_CUSTOMER_BY_ID"
export const DELETE_CUSTOMER_BY_ID = "DELETE_CUSTOMER_BY_ID"
export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL"
export const GET_REPORT_CUSTOMER_EXPORT = "GET_REPORT_CUSTOMER_EXPORT"

export const GET_CLINIC_LIST = "GET_CLINIC_LIST"
export const GET_EXAMINATION_SCHEDULE = "GET_EXAMINATION_SCHEDULE"
export const GET_STATUS_PREP_LIST = "GET_STATUS_PREP_LIST"
export const GET_RISK_LIST = "GET_RISK_LIST"
export const GET_GIFT_LIST = "GET_GIFT_LIST"
export const GET_MESSAGE_LIST = "GET_MESSAGE_LIST"
export const GET_RISK_DETAIL = "GET_RISK_DETAIL"
export const GET_GIFT_DETAIL = "GET_GIFT_DETAIL"

export const CREATE_APP_ACCOUNT = "CREATE_APP_ACCOUNT"
