import i18n from "@/shared/plugins/i18n"

i18n.mergeLocaleMessage("en", {
	AppTitle: {
		Dashboard: "Dashboard",
		Page404: "Page404",
		Page500: "Page500",
	},

	Header: {
		Account: "Account",
		Setting: "Setting",
		Info: "My info",
		ChangePwd: "Change Password",
		Logout: "Logout",
		Notification: "Notification",
	},

	Button: {
		Update: "Update",
		Delete: "Delete",
		Create: "Create",
		Cancel: "Cancel",
		Submit: "Submit",
		CreateNew: "Create new",
		SaveChanges: "Save changes",
		Exit: "Exit",
		Noti: "Notification",
		OK: "OK",
		Save: "Save",
		Abort: "Abort",
		Confirm: "Confirm",
		Yes: "Yes",
		No: "No",
		Add: "Add",
		Close: "Close",
		DiscardChanges: "Discard changes?",
		DiscardContent: "All inputted info will be lost once you confirm.",
		AreYouSure: "Are you sure?",
		Message: "Message",
		Trashed: "Trashed",
		Trash: "Trash",
		Untrash: "Untrash",
		Merge: "Merge",
	},

	DropDown: {
		Province: "Province",
		District: "District",
		Status: "Status",
		Role: "Vai trò",
		CSIN: "C-SIN",
		Search: "Search",
		Create: "Create new",
		Export: "Export link",
		Import: "Import file",
		NoResult: "No data to show.",
		NoOptions: "List is empty",
	},

	Tabs: {
		RequestList: "Request List",
		AccountWaiting: "Account waiting",
	},

	Roles: {
		SuperAdmin: "Super Admin",
		Admin: "Admin",
	},

	Status: {
		Active: "Active",
		Inactive: "Inactive",
		Expired: "Expired",
		Pending: "Pending",
	},

	Table: {
		NoData: "No data to show",
		Total: "Total {totals} records",
	},

	Action: {
		Save: "Save",
		Cancel: "Cancel",
		Update: "Update",
		Create: "Create",
		Pending: "Pending",
		Edit: "Edit",
		Clear: "Clear",
		Delete: "Delete",
		Detail: "Detail",
		Accept: "Accept",
		Deny: "Deny",
		Ok: "Accept",
		No: "Cancel",
		ViewEdit: "View/Edit",
	},

	Sidebar: {
		ManagerCustomer: "Customer Management",
		AppointmentManagement: "Appointment Management",
		AdminManagement: "Admin Management",
		MessageManagement: "Message Management",
		ClinicManagement: "Clinic Management",
		ReportManagement: "Report",
		TopicManagement: "Topic Management",
		BannerManagement: "Banner Management",
		NotificationManagement: "Notification Management",
	},

	DatePicker: {
		SelectDate: "Select date",
		SelectTime: "Select time",
	},

	RelativeTime: {
		s: "seconds",
		ss: "seconds",
		m: "minute",
		mm: "minutes",
		h: "hour",
		hh: "hours",
		d: "day",
		dd: "days",
		w: "week",
		ww: "weeks",
		M: "month",
		MM: "months",
		y: "year",
		yy: "years",
	},
})
