import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins"

const ReportPREPManagement = () => import("./pages/ReportPREPManagement.vue")
const ReportScheduleManagement = () => import("./pages/ReportScheduleManagement.vue")
const ReportModule = () => import("./ReportModule")
const ReportRiskAssessment = () => import("./pages/ReportRiskAssessmentManagement.vue")

const customerRoutes = [
	{
		path: "/report",
		name: "ReportModule",
		component: ReportModule,
		meta: {
			label: i18n.t("ReportTitle.ReportManagement"),
		},
		redirect: "/report/prep",
		children: [
			{
				path: "prep",
				name: "ReportPREPManagement",
				component: ReportPREPManagement,
				meta: {
					title: i18n.t("ReportTitle.ReportPrEPList"),
					label: i18n.t("ReportTitle.ReportPrEPList"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: "schedule",
				name: "ReportScheduleManagement",
				component: ReportScheduleManagement,
				meta: {
					title: i18n.t("ReportTitle.ReportScheduleList"),
					label: i18n.t("ReportTitle.ReportScheduleList"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: "risk",
				name: "ReportRiskAssessment",
				component: ReportRiskAssessment,
				meta: {
					title: i18n.t("ReportTitle.FormReport"),
					label: i18n.t("ReportTitle.FormReport"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/report/prep",
			},
		],
	},

	// Support embedded in mobile
	{
		path: "/report/prep-mobile",
		name: "ReportPREPManagement",
		component: ReportPREPManagement,
		props: route => ({
			isMobile: true,
			token: route.query.token,
			mobileRole: route.query.role,
		}),
		meta: {
			title: i18n.t("ReportTitle.ReportPrEPList"),
			label: i18n.t("ReportTitle.ReportPrEPList"),
		},
	},

	{
		path: "/report/schedule-mobile",
		name: "ReportScheduleManagement",
		component: ReportScheduleManagement,
		props: route => ({
			isMobile: true,
			token: route.query.token,
			mobileRole: route.query.role,
		}),
		meta: {
			title: i18n.t("ReportTitle.ReportScheduleList"),
			label: i18n.t("ReportTitle.ReportScheduleList"),
		},
	},
]

export default customerRoutes
