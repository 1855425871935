import store from "@/store"
import { cloneDeep } from "lodash-es"

import {
	GET_MESSAGES_SUCCESS,
	RESET_FILTERS_MESSAGE_LIST,
	GET_MESSAGE_BY_ID_SUCCESS,
	CHANGE_STATUS_DISCARD_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	DELETE_MESSAGE_SUCCESS,
} from "./mutation-types"

export default {
	[GET_MESSAGES_SUCCESS]: function (state, payload = {}) {
		state.messages = payload.items
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[RESET_FILTERS_MESSAGE_LIST]: function (state) {
		state.messages = []
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			offset: 0,
			limit: process.env.VUE_APP_LIMIT_LIST,
		}
	},

	[GET_MESSAGE_BY_ID_SUCCESS]: function (state, payload = {}) {
		state.recentMess = cloneDeep(payload)
		state.recentMess.setting = cloneDeep(store.state.messages.setting)

		payload.setting.forEach(item => {
			state.recentMess.setting[4 * (item.month - 1) + item.week - 1] = { ...item }
		})
	},

	[DELETE_MESSAGE_SUCCESS]: function (state) {
		state.recentMess = null
	},

	[CHANGE_STATUS_DISCARD_MODAL]: function (state, payload = {}) {
		state.isShowDiscardModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DELETE_MODAL]: function (state, payload = {}) {
		state.isShowDeleteModal = payload
		store.commit("set", ["modalShow", payload])
	},
}
