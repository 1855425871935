import router from "@/router"
import API from "../services"

import { ORDER_PRODUCT, GET_CLINIC } from "./action-types"

import { GET_CLINIC_SUCCESS } from "./mutation-types"

export default {
	// eslint-disable-next-line
	[ORDER_PRODUCT]: async ({}, params = {}) => {
		try {
			await API.orderProduct(params)
			localStorage.removeItem("isCompletedRisk")
			await window.parent.postMessage("finish-order-product", "*")

			router.push({ name: "BookingSuccess" })
		} catch (error) {}
	},

	[GET_CLINIC]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getClinic(params)
			commit(GET_CLINIC_SUCCESS, res)
		} catch (error) {}
	},
}
