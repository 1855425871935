<template>
	<div class="custom-table">
		<CCard>
			<CCardBody>
				<slot name="title" />

				<slot name="filters">
					<div class="filters">
						<!-- Status -->
						<div>
							<multiselect
								id="statuses"
								v-model="statuses"
								:options="listStatus"
								:limit-text="limitTextObject"
								:custom-label="labelStatus"
								:close-on-select="true"
								:show-labels="false"
								:allow-empty="true"
								:searchable="false"
								:multiple="true"
								:limit="limitVisibleDD"
								:placeholder="$t('DropDown.FilterStatus')"
								@input="resetPaging"
							>
								<template slot="clear">
									<div
										v-if="statuses.length > 0"
										class="multiselect__clear"
										@mousedown.prevent.stop="clearAll('statuses')"
									></div>
								</template>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>

						<!-- Examination date -->
						<div>
							<date-picker
								v-model="dateRange"
								type="date"
								value-type="format"
								range
								class="w-100"
								:confirm="true"
								:confirm-text="$t('Button.Find')"
								:editable="false"
								:placeholder="$t('Appointment.FilterByDate')"
								@change="resetPaging"
							></date-picker>
						</div>

						<!-- Search -->
						<div>
							<input
								id="search"
								v-model.trim="key"
								class="form-control custom-input"
								type="text"
								:placeholder="$t('Appointment.Search')"
								:maxlength="maxLength"
								@keydown="searchDebounce"
							/>
						</div>

						<!-- Clinic -->
						<div v-if="isSystemAdmin">
							<multiselect
								id="clinicIds"
								v-model="clinicIds"
								:options="listClinics"
								:limit-text="limitTextObject"
								:multiple="true"
								:close-on-select="true"
								:show-labels="false"
								:allow-empty="true"
								:searchable="true"
								:placeholder="$t('DropDown.FilterPK')"
								:limit="limitVisibleDD"
								:internal-search="false"
								label="name"
								track-by="id"
								autocomplete="off"
								@input="resetPaging"
								@search-change="searchChangeClinic"
							>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>

						<!-- Service -->
						<div>
							<multiselect
								id="serviceIds"
								v-model="serviceIds"
								:options="listServices"
								:multiple="true"
								:limit-text="limitTextObject"
								:close-on-select="true"
								:show-labels="false"
								:allow-empty="true"
								:searchable="true"
								:placeholder="$t('DropDown.FilterService')"
								:limit="limitVisibleDD"
								:internal-search="false"
								label="name"
								track-by="id"
								autocomplete="off"
								@input="resetPaging"
								@search-change="searchChangeService"
							>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>

						<!-- Customer -->
						<div>
							<multiselect
								id="customerTypes"
								v-model="customerTypes"
								:options="listCustomersType"
								:limit-text="limitTextObject"
								:custom-label="labelCustomerType"
								:close-on-select="true"
								:show-labels="false"
								:allow-empty="true"
								:searchable="false"
								:multiple="true"
								:limit="limitVisibleDD"
								:placeholder="$t('DropDown.FilterCustomer')"
								@input="resetPaging"
							>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>

						<!-- Create Appointment Type -->
						<div>
							<multiselect
								id="createTypes"
								v-model="createTypes"
								:options="listCreateType"
								:limit-text="limitTextObject"
								:custom-label="labelCreateAppointmentType"
								:close-on-select="true"
								:show-labels="false"
								:allow-empty="true"
								:searchable="false"
								:multiple="true"
								:limit="limitVisibleDD"
								:placeholder="$t('DropDown.CreateAppointmentType')"
								@input="resetPaging"
							>
								<template slot="clear">
									<div
										v-if="createTypes.length > 0"
										class="multiselect__clear"
										@mousedown.prevent.stop="clearAll('createTypes')"
									></div>
								</template>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>
					</div>
				</slot>

				<CRow class="form-group d-md-flex">
					<CCol class="text-left">
						<slot name="tabs" />
					</CCol>

					<slot name="actions">
						<CCol class="text-right">
							<template>
								<CButton color="info" @click="create">{{ $t("DropDown.Create") }}</CButton>
							</template>

							<!-- Import -->
							<template v-if="!isImporting">
								<button
									type="button"
									class="btn btn-outline-dark ml-2"
									:disabled="isUploadingFile"
									@click="onPickFileImport"
									>{{ $t("Button.Import") }}</button
								>
								<input
									ref="fileInput"
									type="file"
									accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
									class="form-control d-none"
									@change="handleSelectedFile"
								/>
							</template>
							<button
								v-else
								type="button"
								class="btn btn-outline-dark ml-2"
								@click="toggleProgressBar"
								>{{ $t("Button.Importing") }}</button
							>

							<template>
								<button type="button" class="btn btn-outline-dark ml-2" @click="exportExcel">
									{{ $t("DropDown.ExportExcel") }}
								</button>
							</template>
						</CCol>
					</slot>
				</CRow>

				<slot name="table">
					<div class="table-responsive-md">
						<table class="table table-bordered table-hover table-responsive table-fit">
							<thead>
								<slot name="headers" />
							</thead>
							<tbody>
								<slot name="body" />
							</tbody>
						</table>
					</div>
				</slot>

				<slot name="footer">
					<CRow>
						<!-- Select limit  -->
						<CCol class="custom-table__footer">
							<CRow class="m-0 options">
								<div class="align-self-center">{{ rangeItemsOfTotal }}</div>

								<!-- pagination -->
								<CPagination
									v-if="totalPages > 1"
									:active-page.sync="recentPage"
									:pages="totalPages"
									align="end"
								/>
							</CRow>
						</CCol>
					</CRow>
				</slot>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { debounce, find, isArray } from "lodash-es"
import { CUSTOMER_TYPE, USER_ROLE, INPUT_LENGTH, APPT_STATUS } from "@/shared/plugins/constants"
import { mapState } from "vuex"

import timezone from "moment-timezone"
import { CREATE_APPOINTMENT_TYPE } from "../plugins"

export default {
	name: "CustomTableAppointment",

	props: {
		totalPages: {
			type: [Number, String],
			default: 0,
		},
		clinics: {
			type: [Array, Object],
			default: () => [],
		},
		services: {
			type: Array,
			default: () => [],
		},
		isShowProgressBar: {
			type: Boolean,
			default: false,
		},
		isImporting: {
			type: Boolean,
			default: false,
		},
		totalItems: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			limitVisibleDD: INPUT_LENGTH.VISIBLE_DROPDOWN,
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			recentPage: 1,
			listClinics: [],
			listServices: [],

			limit: process.env.VUE_APP_LIMIT_LIST,
			offset: 0,

			key: "",
			dateRange: [],
			serviceIds: [],
			clinicIds: [],

			statuses: [APPT_STATUS.PENDING],
			listStatus: [APPT_STATUS.PENDING, APPT_STATUS.COMPLETE, APPT_STATUS.CUSTOMER_CANCEL],

			customerTypes: [],
			listCustomersType: [CUSTOMER_TYPE.PREP, CUSTOMER_TYPE.NORMAL, CUSTOMER_TYPE.UNOFFICIAL],

			createTypes: [CREATE_APPOINTMENT_TYPE.MANUAL],
			listCreateType: [CREATE_APPOINTMENT_TYPE.MANUAL, CREATE_APPOINTMENT_TYPE.AUTO],
		}
	},

	computed: {
		...mapState("authentication", ["currentUser"]),
		...mapState("serviceAppointment", ["isUploadingFile", "recentFilters"]),

		isSystemAdmin: function () {
			return this.currentUser?.role === USER_ROLE.SYSTEM_ADMIN
		},

		rangeItemsOfTotal: function () {
			if (!this.totalPages) return "0 - 0 of 0"

			const from = Number(this.offset) + 1
			const to =
				from / this.limit < this.totalPages - 1
					? Number(this.offset) + Number(this.limit)
					: this.totalItems

			return `${from} - ${to} of ${this.totalItems}`
		},
	},

	watch: {
		recentPage(val) {
			if (val !== Math.floor(this.offset / this.limit) + 1) {
				this.offset = (val - 1) * this.limit
				this.search()
			}
		},

		clinics: function (val) {
			this.listClinics = [...val]

			this.setData()
		},

		services: function (val) {
			this.listServices = [...val]

			this.setData()
		},
	},

	created() {
		this.setData()
	},

	mounted() {
		this.setAutocompleteOff()
	},

	methods: {
		setData() {
			if (!this.clinics.length || !this.services.length) return

			const queryParams = this.$route.query
			this.key = queryParams.key || this.recentFilter?.key || this.key
			this.statuses = this.getDataParams(
				queryParams.statuses,
				this.recentFilter?.statuses,
				this.statuses,
			)
			this.customerTypes = this.getDataParams(
				queryParams.customerTypes,
				this.recentFilter?.customerTypes,
				this.customerTypes,
			)

			this.createTypes = this.getDataParams(
				queryParams.createTypes,
				this.recentFilter?.createTypes,
				this.createTypes,
			)

			this.dateRange = [
				queryParams.fromDate || this.recentFilter?.fromDate || this.dateRange[0],
				queryParams.toDate || this.recentFilter?.toDate || this.dateRange[1],
			]

			this.limit = queryParams.limit || this.recentFilter?.limit || this.limit
			this.offset = queryParams.offset || this.recentFilter?.offset || this.offset

			const serviceIds = this.getDataParams(
				queryParams.serviceIds,
				this.recentFilter?.serviceIds,
				this.serviceIds,
			)
			this.serviceIds = serviceIds.map(id => {
				return find(this.services, function (o) {
					return o.id == id
				})
			})

			const clinicIds = this.getDataParams(
				queryParams.clinicIds,
				this.recentFilter?.clinicIds,
				this.clinicIds,
			)
			this.clinicIds = clinicIds.map(id => {
				return find(this.clinics, function (o) {
					return o.id == id
				})
			})

			this.recentPage = Math.floor(this.offset / this.limit) + 1

			if (Object.keys(queryParams).length) {
				this.$emit("search", queryParams)
			} else this.search()
		},

		create() {
			this.$emit("create")
		},

		searchDebounce: debounce(function () {
			this.resetPaging()
		}, process.env.VUE_APP_DEBOUNCE_TIME),

		search() {
			const params = {
				limit: this.limit,
				offset: this.offset,
			}
			if (this.key) {
				params["key"] = this.key
			}
			if (this.serviceIds.length > 0) {
				params["serviceIds"] = this.serviceIds.map(item => item.id).join(",")
			}
			if (this.clinicIds.length > 0) {
				params["clinicIds"] = this.clinicIds.map(item => item.id).join(",")
			}
			if (this.statuses.length > 0) {
				params["statuses"] = this.statuses.join(",")
			}
			if (this.customerTypes.length > 0) {
				params["customerTypes"] = this.customerTypes.join(",")
			}
			if (this.createTypes.length > 0) {
				params["createTypes"] = this.createTypes.join(",")
			}
			if (this.dateRange[0] && this.dateRange[1]) {
				params["fromDate"] = this.dateRange[0]
				params["toDate"] = this.dateRange[1]
			}

			this.$router.push({ query: params }).catch(error => {
				if (error.name !== "NavigationDuplicated") {
					throw error
				}
			})

			this.$emit("search", { ...params, timezone: timezone.tz.guess() })
		},

		resetPaging() {
			this.offset = 0
			this.recentPage = 1
			this.search()
		},

		onPickFileImport() {
			this.$refs.fileInput.value = ""
			this.$refs.fileInput.click()
		},

		handleSelectedFile(event) {
			const importedFile = event.target.files[0]
			const formData = new FormData()
			formData.append("file", importedFile)
			this.$emit("import", formData)
		},

		exportExcel() {
			this.$emit("export")
		},

		toggleProgressBar() {
			this.$emit("toggle-progress-bar", {
				isShowProgressBar: true,
				isShowShortcutImport: false,
			})
		},
		labelCustomerType(value) {
			return {
				[CUSTOMER_TYPE.PREP]: this.$t("ApptCustomerType.PrEP"),
				[CUSTOMER_TYPE.NORMAL]: this.$t("ApptCustomerType.Normal"),
				[CUSTOMER_TYPE.UNOFFICIAL]: this.$t("ApptCustomerType.Unofficial"),
			}[value]
		},
		labelStatus(value) {
			return {
				[APPT_STATUS.PENDING]: this.$t("AppointmentDetail.Pending"),
				[APPT_STATUS.CUSTOMER_CANCEL]: this.$t("AppointmentDetail.Cancel"),
				[APPT_STATUS.COMPLETE]: this.$t("AppointmentDetail.Complete"),
			}[value]
		},
		labelCreateAppointmentType(value) {
			return {
				[CREATE_APPOINTMENT_TYPE.AUTO]: this.$t("CreateAppointmentType.AUTO"),
				[CREATE_APPOINTMENT_TYPE.MANUAL]: this.$t("CreateAppointmentType.MANUAL"),
			}[value]
		},
		clearAll(array) {
			this[array] = null
		},

		limitTextObject(count) {
			return this.$t("Multiselect.OtherObjects", { count: count })
		},

		getDataParams(arg1, arg2, arg3) {
			return this.convertToArray(arg1) || this.convertToArray(arg2) || this.convertToArray(arg3)
		},

		convertToArray(value) {
			if ([null, undefined, ""].indexOf(value) > -1) return null

			if (isArray(value)) return value
			else return value.split(",")
		},

		searchChangeClinic(value) {
			if (!value || !value.length) {
				this.listClinics = [...this.clinics]
				return
			}

			const valueSearch = this.nomalizeText(value)
			this.listClinics = this.clinics.filter(({ name }) => {
				return this.nomalizeText(name).includes(valueSearch)
			})
		},

		searchChangeService(value) {
			if (!value || !value.length) {
				this.listServices = [...this.services]
				return
			}

			const valueSearch = this.nomalizeText(value)
			this.listServices = this.services.filter(({ name }) => {
				return this.nomalizeText(name).includes(valueSearch)
			})
		},

		setAutocompleteOff() {
			const elements = document.getElementsByClassName("multiselect__input")

			if (elements.length) {
				elements.forEach(element => {
					element.autocomplete = "off"
				})
			}
		},
	},
}
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";

.custom-table {
	.filters {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(238px, 1fr));
		gap: 10px;
		margin-bottom: 1rem;
	}

	.table td.fit,
	.table th.fit {
		white-space: nowrap;
		width: 1%;
	}

	.table th {
		background-color: $table-header-color;
		color: $base-color;
		font-weight: 600;
	}

	&__footer {
		display: grid;
		grid-template-columns: 1fr auto auto;

		.options {
			justify-self: end;
		}

		.pagination {
			margin-left: 15px;
			margin-bottom: 0;
		}

		&__total {
			grid-column: -1/1;

			@media (min-width: $xxs) {
				grid-column: initial;
			}
		}
	}

	.actions {
		span {
			&:hover {
				cursor: pointer;
			}
		}
	}
}
</style>
