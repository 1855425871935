import { GET_RISK_QUESTIONS_SUCCESS, CHANGE_STEP } from "./mutation-types"

export default {
	[GET_RISK_QUESTIONS_SUCCESS]: function (state, payload = {}) {
		state.riskQuestions = payload.riskQuestions
		state.totalGroupQuestions = Object.keys(payload.riskQuestions).length
		state.dataAssessment = payload.dataAssessment
	},

	[CHANGE_STEP]: function (state, payload = {}) {
		state.step = payload
	},
}
