import { callAPI } from "@/shared/services"

export default {
	login: params => {
		return callAPI("POST", "auth/admin/login", params, {
			"Content-Type": "multipart/form-data",
		}).then(res => res.data)
	},

	loginOTP: params => {
		return callAPI("POST", "admin/login/otp", params, {
			"Content-Type": "multipart/form-data",
		}).then(res => res.data)
	},

	logout: () => {
		return callAPI("GET", "admin/logout", { skipErrorHandle: true }).then(res => res.data)
	},

	refreshToken: params => {
		return callAPI("POST", "auth/refresh", params, { notLoading: true }).then(res => res.data)
	},

	recoverPwd: params => {
		return callAPI("POST", "auth/admin/send-otp-email", params).then(res => res.data)
	},

	forgotVerifyOTP: params => {
		return callAPI("POST", "auth/admin/verify-otp-email", params).then(res => res.data)
	},

	resetPwd: params => {
		return callAPI("POST", "auth/admin/reset-password", params).then(res => res.data)
	},

	changePwdMe: params => {
		return callAPI("PUT", "auth/change-password", params).then(res => res.data)
	},

	getUserProfile: params => {
		return callAPI("GET", "admins/profile", params).then(res => res.data)
	},

	updateUserProfile: params => {
		return callAPI("POST", "admins/profile", params).then(res => res.data)
	},

	checkTokenValid: params => {
		return callAPI("POST", "admin/token", params, { notLoading: true }).then(res => res.data)
	},

	sendFirebaseToken: params => {
		return callAPI("POST", "admin/users/client-token", params, {
			notLoading: true,
		}).then(res => res.data)
	},
}
