import API from "../services"

import {
	CHANGE_TOPIC_CATEGORY,
	GET_LIST_TOPIC_COMMENT,
	TRASH_LIST_TOPIC_COMMENT,
} from "./action-types"

import { GET_LIST_TOPIC_COMMENT_SUCCESS } from "./mutation-types"

export default {
	[GET_LIST_TOPIC_COMMENT]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getListTopicComment(params)
			commit(GET_LIST_TOPIC_COMMENT_SUCCESS, { ...res, filters: params })
		} catch (error) {}
	},

	[TRASH_LIST_TOPIC_COMMENT]: (store, params = {}) => {
		try {
			return API.trashListTopicComment(params)
		} catch (error) {}
	},
	[CHANGE_TOPIC_CATEGORY]: (store, params = {}) => {
		try {
			return API.changeTopicCategory(params)
		} catch (error) {}
	},
}
