import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	Appointment: {
		Register: "Register appointment schedule",
		FindSchedule: "Find schedule",
		Description:
			"Make appointments with community-friendly clinics for prompt consultation, testing and treatment with the motto: <span class='quote'>Safe - Reliable - Quick - Share</span>. Your information will be completely confidential, encrypted in the system and only shared in the clinic you register.",
		AppointmentForm: "Appointment Form",
		CreateAppointment: "Create new Appointment",
		UpdateAppointment: "Update Appointment",
		AppointmentSuccess: "Appointment Successfully",
		SearchForm: "Search Form",
		AppointmentDetail: "Appointment Detail",
		CreateNewAppt: "Create new service appointment",
		AppointmentId: "Appointment ID {id}",
	},

	AppointmentForm: {
		Status: "Status",
		CustomerType: "Customer type",
		Name: "Name",
		Phone: "Phone",
		YearOfBirth: "Year Of Birth",
		Contact: "Contact",
		Services: "Services",
		Clinics: "Clinics",
		DateTime: "Date time",
		HIVResult: "HIV testing result in community (if any)",
		Note: "Note",
		ConfirmPhone:
			"This phone number already set for another account. Are you creating an examination schedule for this existed account?",
		HaNoi: "Ha Noi",
		HoChiMinh: "Ho Chi Minh",
		NewServiceAppt: "New service appointment",
		Finish: "Finish",
		WorkingHours: "Working hours",
		Website: "Website",
		HIVScreening: "KQ XN sàng lọc HIV",
		HIVPositive: "KQ XN khẳng định HIV",
		IntroducePrEP: "Introduced PrEP from other services",
		PrEPTreatmentStatus: "PrEP treatment status",
		PrEPRegimen: "PrEP Regimen",
		MedicationDispensed: "Medication dispensed",
		SendingSource: "Sending source",
		HivART: "HIV (ART) test and treatment results",
		STIResult: "STI test and treatment results",
		HepatitisBResult: "B test and treatment results",
		HepatitisCResult: "C test and treatment results",
		MindCounselingResult: "Mind heathy treatment results",
		SubstanceResult: "Substance abuse counseling results",
		HormonResult: "Hormone usage and counseling results",
		FindCustomers: "Find customers",

		ReceivedSMS: "Received SMS",
		ReminderAppt: "Reminder appointment",
		ServiceRatingPrep: "Service rating Prep",
		DontNeed: "I don't need",
		NoteSMS: "The message content will not contain the service name to ensure privacy",
	},

	AppointmentSearch: {
		ApptCode: "Appointment code",
		ScheduledAppt: "Find a scheduled appointment",
		CancelSchedule: "Cancel schedule",
		Find: "Find",
		SearchBy: "Search by nane or phone",
		SearchFalse: "Không tìm thấy kết quả nào phù hợp với yêu cầu của bạn",
	},

	AppointmentDetail: {
		SearchResult: "Search result",
		AppointmentInfo: "Appointment information",
		Pending: "Pending",
		Cancel: "Cancel",
		Complete: "Complete",
		CancelMessage: "Do you want to cancel this appointment",
		ApptInfo: "Appointment info",
	},

	AppointmentSuccess: {
		Appointment: "Schedule appointment successfully",
		RegisterInfo:
			"Your service registration information has been transferred to the clinic. Clinic staff will contact you within 24 working hours.",
		PleaseSave:
			"Please save your appointment code for easy reference. If you need assistance, please contact the clinic phone number as below for the appointment information.",
	},

	ApptStatus: {
		Waiting: "Waiting",
		Finished: "Finished",
		Canceled: "Canceled",
	},

	ApptCustomerType: {
		PrEP: "PrEP customer",
		Normal: "Normal customer",
		Unofficial: "Unofficial customer",
	},

	ApptHIVResult: {
		Negative: "Negative",
		Positive: "Positive",
		NotAvailable: "N/A",
	},

	ServicesQuestion: {
		Answer1: "HIV counseling",
		Answer2: "HIV testing",
		Answer3: "PrEP counseling",
		Answer4: "PrEP treatment",
		Answer5: "STI counseling",
		Answer6: "STI treatment",
		Answer7: "ARV counseling",
		Answer8: "ARV treatment",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			appointment_status: "Input",
			appointment_result_date: "Input",
			appointment_customer_type: "Input",
			appointment_name: "Input",
			appointment_phone: "Input",
			appointment_birth_year: "Input",
			appointment_contact: "Input",
			appointment_services: "Input",
			appointment_clinics: "Input",
			appointment_date: "Input",
			appointment_hiv_result: "Input",
			appointment_note: "Input",
			appointment_sms: "Input",
			appointment_hiv_screening: "Input",
			appointment_hiv_confirm: "Input",
			appointment_prep_service: "Input",
			appointment_prep_status: "Input",
			appointment_prep_regimen: "Input",
			appointment_prep_medication: "Input",
			appointment_prep_source: "Input",
			appointment_result_0: "Input",
			appointment_result_1: "Input",
			appointment_result_2: "Input",
			appointment_result_3: "Input",
			appointment_result_4: "Input",
			appointment_result_5: "Input",
			appointment_result_6: "Input",
			appointment_result_7: "Input",
			appointment_result_8: "Input",
			appointment_result_9: "Input",
			appointment_result_10: "Input",
			appointment_result_11: "Input",
			appointment_result_12: "Input",
			appointment_result_13: "Input",
			appointment_result_14: "Input",
			appointment_result_15: "Input",
			appointment_result_16: "Input",
			appointment_result_17: "Input",
			appointment_result_18: "Input",

			// Search form
			appointment_code: "Input",
		},
	},

	validation: {
		...en.messages,
		...{
			// custom message rules
			integer: "The {_field_} field must be an integer",
			isEmail: "The input field must be a valid email",
		},
	},

	CreateAppointmentType: {
		MANUAL: "Manual",
		AUTO: "Auto",
	},
})
