import API from "../services"
import router from "@/router"
import { CREATE_NOTIFICATION, GET_LIST_NOTIFICATION, GET_CUSTOMERS } from "./action-types"

import { GET_LIST_NOTIFICATION_SUCCESS, GET_CUSTOMERS_SUCCESS } from "./mutation-types"

export default {
	[GET_LIST_NOTIFICATION]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getListNotification(params)
			commit(GET_LIST_NOTIFICATION_SUCCESS, { ...res, filters: params })
		} catch (error) {}
	},

	// eslint-disable-next-line no-unused-vars
	[CREATE_NOTIFICATION]: async ({ commit }, params = {}) => {
		try {
			await API.createNotification(params)
			router.push({ name: "NotificationList" })
		} catch (error) {}
	},

	[GET_CUSTOMERS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getCustomers(params)
			commit(GET_CUSTOMERS_SUCCESS, { ...res, filters: params })

			return res
		} catch (error) {}
	},
}
