import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
const ls = new SecureLS({ isCompression: false })

// import the auto exporter
import modules from "./modules"

Vue.use(Vuex)

const state = {
	sidebarShow: "responsive",
	sidebarMinimize: false,
	modalShow: false,
}

const mutations = {
	toggleSidebarDesktop(state) {
		const sidebarOpened = [true, "responsive"].includes(state.sidebarShow)
		state.sidebarShow = sidebarOpened ? false : "responsive"
	},
	toggleSidebarMobile(state) {
		const sidebarClosed = [false, "responsive"].includes(state.sidebarShow)
		state.sidebarShow = sidebarClosed ? true : "responsive"
	},
	set(state, [variable, value]) {
		state[variable] = value
	},
}

const store = new Vuex.Store({
	state,
	mutations,
	modules,
	plugins: [
		createPersistedState({
			paths: ["authentication"],
			storage: {
				getItem: key => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: key => ls.remove(key),
			},
		}),
	],
})

export default store
