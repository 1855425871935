import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins/constants"

const AdminModule = () => import("./AdminModule.vue")
const AdminList = () => import("./pages/AdminList.vue")
const CreateAdmin = () => import("./pages/CreateAdmin.vue")
const UpdateAdmin = () => import("./pages/UpdateAdmin.vue")

const adminRoutes = [
	{
		path: "/admins",
		name: "AdminModule",
		component: AdminModule,
		meta: {
			label: i18n.t("AdminTitle.AdminManagement"),
		},
		redirect: "/admins/list",
		children: [
			{
				path: "list",
				name: "AdminList",
				component: AdminList,
				meta: {
					title: i18n.t("AdminTitle.AdminList"),
					label: i18n.t("AdminTitle.AdminList"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: "create",
				name: "CreateAdmin",
				component: CreateAdmin,
				meta: {
					title: i18n.t("AdminTitle.CreateAdmin"),
					label: i18n.t("AdminTitle.CreateAdmin"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
			{
				path: ":id/update",
				name: "UpdateAdmin",
				component: UpdateAdmin,
				props: route => ({
					default: true,
					id: route.params.id,
				}),
				meta: {
					title: i18n.t("AdminTitle.UpdateAdmin"),
					label: i18n.t("AdminTitle.UpdateAdmin"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
				},
			},
		],
	},
]

export default adminRoutes
