<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol xl="12">
						<NotificationForm
							@apply-form="createNotification"
							@cancel-form="goToNotificationList"
						></NotificationForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { CREATE_NOTIFICATION } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("notifications")
export default {
	name: "CreateNotification",

	components: {
		NotificationForm: () => import("./../components/NotificationForm.vue"),
	},

	props: {},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {},

	methods: {
		...mapActions({ CREATE_NOTIFICATION }),

		createNotification: async function (params) {
			await this.CREATE_NOTIFICATION(params)
		},

		goToNotificationList() {
			this.$router.push({ name: "NotificationList" })
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
</style>
