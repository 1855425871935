import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	recentCustomer: null,
	customerAppt: null,
	recentRisk: null,
	recentGift: null,
	clinics: [],
	schedule: [],
	messages: [],
	prepList: [],
	riskList: [],
	products: [],
	timeDrug: null,
	customers: null,
	totalItems: null,
	totalPages: null,
	recentFilters: {
		keyword: null,
		status: null,
		types: null,
		riskEvaluation: null,
		prepStartDate: null,
		prepEndDate: null,
		limit: process.env.VUE_APP_LIMIT_LIST,
		offset: 0,
	},

	isShowStatusModal: false,
	isShowDeleteModal: false,
	isShowDiscardModal: false,
	isShowCreateModal: false,
	createUserResult: null,
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
