import store from "@/store"

import {
	GET_CLINICS_SUCCESS,
	RESET_FILTERS_CLINIC_LIST,
	GET_CLINIC_BY_ID_SUCCESS,
	CHANGE_STATUS_DISCARD_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	CHANGE_STATUS_CLINIC_MODAL,
	DELETE_CLINIC_SUCCESS,
	GET_CITIES_SUCCESS,
	GET_CITIES_FAILED,
	GET_SERVICES_SUCCESS,
} from "./mutation-types"

export default {
	[GET_CLINICS_SUCCESS]: function (state, payload = {}) {
		state.clinics = payload.items
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[RESET_FILTERS_CLINIC_LIST]: function (state) {
		state.clinics = []
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			keyword: "",
			offset: 0,
			limit: process.env.VUE_APP_LIMIT_LIST,
		}
	},

	[GET_CLINIC_BY_ID_SUCCESS]: function (state, payload = {}) {
		state.recentClinic = payload
	},

	[DELETE_CLINIC_SUCCESS]: function (state) {
		state.recentClinic = null
	},

	[GET_CITIES_SUCCESS]: function (state, payload = {}) {
		state.cities = payload
	},

	[GET_CITIES_FAILED]: function (state) {
		state.cities = []
	},

	[GET_SERVICES_SUCCESS]: function (state, payload = {}) {
		state.services = payload
	},

	[CHANGE_STATUS_CLINIC_MODAL]: function (state, payload = {}) {
		state.isShowStatusModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DISCARD_MODAL]: function (state, payload = {}) {
		state.isShowDiscardModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DELETE_MODAL]: function (state, payload = {}) {
		state.isShowDeleteModal = payload
		store.commit("set", ["modalShow", payload])
	},
}
