import store from "@/store"

import {
	GET_LIST_NOTIFICATION_SUCCESS,
	RESET_FILTERS_NOTIFICATION_LIST,
	SHOW_PUSH_NOTI_MODAL,
} from "./mutation-types"
import {
	CHANGE_STATUS_BANNER_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	CHANGE_STATUS_DISCARD_MODAL,
} from "../store/mutation-types"
import {
	GET_CUSTOMERS_SUCCESS,
	RESET_CUSTOMERS,
} from "../../serviceAppointment/store/mutation-types"
import { unionBy } from "lodash-es"

export default {
	[GET_LIST_NOTIFICATION_SUCCESS]: function (state, payload = {}) {
		state.notifications = payload.data
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[RESET_FILTERS_NOTIFICATION_LIST]: function (state) {
		state.notifications = null
		state.totalItems = null
		state.totalPages = null
		state.recentNotification = null
		state.recentFilters = {
			keyword: "",
			offset: 0,
			limit: process.env.VUE_APP_LIMIT_LIST,
		}
	},

	[RESET_CUSTOMERS]: function (state) {
		state.customers = []
		state.filtersCustomer = {
			limit: Number(process.env.VUE_APP_LIMIT_LIST),
			offset: 0,
		}
	},

	[GET_CUSTOMERS_SUCCESS]: function (state, payload = {}) {
		state.customers = unionBy(state.customers, payload.items, "id")
		state.totalCustomer = payload.total
		state.filtersCustomer = { ...payload.filters }
	},

	[CHANGE_STATUS_BANNER_MODAL]: function (state, payload = {}) {
		state.isShowStatusModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DISCARD_MODAL]: function (state, payload = {}) {
		state.isShowDiscardModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DELETE_MODAL]: function (state, payload = {}) {
		state.isShowDeleteModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[SHOW_PUSH_NOTI_MODAL]: function (state, payload = {}) {
		state.isShowPushNotiModal = payload
		store.commit("set", ["modalShow", payload])
	},
}
