import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	clinics: [],
	totalItems: null,
	totalPages: null,
	recentFilters: {
		offset: 0,
		limit: process.env.VUE_APP_LIMIT_LIST,
	},

	recentClinic: null,
	cities: [],
	services: [],
	isShowStatusModal: null,
	isShowDiscardModal: null,
	isShowDeleteModal: null,
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
