import Vue from "vue"
import { truncate } from "lodash-es"
import { TRUNCATED_TEXT_LENGTH } from "@/shared/plugins/constants"

Vue.filter("truncate", function (value, lengthType = "DEFAULT", separator = " ") {
	if (!value) return ""

	const length = TRUNCATED_TEXT_LENGTH[lengthType]

	return truncate(value, {
		length,
		separator,
	})
})

export default {
	truncate,
}
