import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	RiskAssessment: {
		AssessmentForm: "Đánh giá nguy cơ",
		AssessmentSuccess: "Đánh giá thành công",
		EvaluationResult: "Kết quả đánh giá",
		CompleteEvaluation: "Hoàn thành đánh giá",
		RegisterEvaluation: "Đăng ký đánh giá",
		Next: "Tiếp theo",
		Back: "Quay lại",
		GetResults: "Nhận kết quả",
		Finish: "Hoàn thành đánh giá",
	},

	RegisterEvaluation: {
		Title:
			"Tất cả thông tin thu thập trong phiếu này hoàn toàn được bảo mật, và chỉ được sử dụng giúp bạn tự đánh giá nguy cơ HIV và kết nối bạn tới những dịch vụ phù hợp và cả miễn phí (ví dụ xét nghiệm HIV)",
		YourPhone: "Số điện thoại",
		YourBirth: "Năm sinh",
		StartNow: "Bắt đầu ngay",
		RiskAssessment: "Đánh giá nguy cơ HIV của bạn",
	},

	CompleteEvaluation: {
		Title: "Bạn đã hoàn thành đánh giá",
		Question: "Bạn có muốn tăng khả năng bảo vệ cho bản thân?",
		Appointment: "Đặt hẹn xét nghiệm/tư vấn",
		GetFree: "Nhận que tự xét nghiệm HIV/thuốc PrEP miễn phí",
		BackHome: "Quay về trang chủ",
	},

	AssessmentResult: {
		Question: "Bạn biết trang đánh giá này qua kênh nào?",
		Answer1: "Facebook Xóm Cầu Vồng",
		Answer2: "Website Xóm Cầu Vồng",
		Answer3: "Phòng khám Glink",
		Answer4: "Nhóm MTMTG",
		Answer5: "Nhóm Venus",
		Answer6: "Nhóm Niềm Tin Xanh",
		Answer7: "Khác",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			assessment_q1: "Câu hỏi",
			assessment_q2: "Câu hỏi",
			assessment_q3: "Câu hỏi",
			assessment_q4: "Câu hỏi",
			assessment_q5: "Câu hỏi",
			assessment_q6: "Câu hỏi",
			assessment_q7: "Câu hỏi",
			assessment_q8: "Câu hỏi",
			assessment_q9: "Câu hỏi",
			assessment_q10: "Câu hỏi",
			assessment_q11: "Câu hỏi",
			assessment_q12: "Câu hỏi",
			assessment_q13: "Câu hỏi",
			assessment_q14: "Câu hỏi",
			assessment_q15: "Câu hỏi",
			assessment_q16: "Câu hỏi",
			assessment_q17: "Câu hỏi",
			assessment_q18: "Câu hỏi",

			register_phone: "Số điện thoại",
			register_birth: "Năm sinh",
		},
	},

	validation: {
		...vi.messages,
		...{
			// custom message rules
			integer: "{_field_} phải là một số nguyên",
		},
	},
})
