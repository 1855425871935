import { callAPI } from "@/shared/services"

export default {
	getAdmins: params => {
		return callAPI("GET", "admins/users", { params }).then(res => res.data)
	},

	createAdmin: params => {
		return callAPI("POST", "admins/users", params).then(res => res.data)
	},

	getAdminById: params => {
		return callAPI("GET", `admins/users/${params.id}`).then(res => res.data)
	},

	updateAdminById: params => {
		return callAPI("PUT", `admins/users/${params.id}`, params).then(res => res.data)
	},

	updateAdminPassword: params => {
		return callAPI("PUT", `admins/users/${params.id}/change-password`, {
			newPassword: params.newPassword,
		}).then(res => res.data)
	},

	deleteAdminById: params => {
		return callAPI("DELETE", `admins/users/${params.id}`).then(res => res.data)
	},

	getClinics: params => {
		return callAPI("GET", "clinic", { params, notLoading: true }).then(res => res.data)
	},
}
