import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	notifications: [],
	totalItems: null,
	totalPages: null,
	customers: [],
	filtersCustomer: {
		limit: Number(process.env.VUE_APP_LIMIT_LIST),
		offset: 0,
	},
	recentFilters: {
		offset: 0,
		limit: process.env.VUE_APP_LIMIT_LIST,
	},
	recentNotification: null,
	isShowStatusModal: null,
	isShowDiscardModal: null,
	isShowDeleteModal: null,
	isShowPasswordModal: null,
	isShowPushNotiModal: null,
	totalCustomer: null,
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
