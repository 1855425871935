import { callAPI } from "@/shared/services"

export default {
	getListTopicComment: params => {
		return callAPI("GET", "admin/topics", { params }).then(res => res.data)
	},

	trashListTopicComment: params => {
		return callAPI("PUT", "admin/topics/trash", params)
	},

	changeTopicCategory: params => {
		return callAPI("PUT", `admin/topics/${params.id}`, params).then(res => res.data)
	},
}
