<template>
	<div class="custom-table">
		<CCard>
			<CCardBody>
				<slot name="title" />
				<slot name="filters">
					<div class="filters">
						<div v-if="isSystemAdmin">
							<div>
								<multiselect
									id="type"
									v-model="type"
									:options="listType"
									:custom-label="labelTypeReport"
									:multiple="true"
									:close-on-select="true"
									:clear-on-select="false"
									:show-labels="false"
									:searchable="false"
									:placeholder="$t('DropDown.ExportSelection')"
								>
									<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
									<template #noResult>{{ $t("DropDown.NoResult") }}</template>
								</multiselect>
							</div>
						</div>
						<!-- Examination date -->
						<div>
							<date-picker
								v-model="dateRange"
								type="date"
								value-type="format"
								range
								class="w-100"
								:confirm="false"
								:confirm-text="$t('Button.Find')"
								:editable="false"
								:placeholder="$t('DatePicker.SelectDateTime')"
							></date-picker>
						</div>
					</div>
				</slot>

				<CRow class="form-group d-md-flex">
					<CCol class="text-left">
						<slot name="tabs" />
					</CCol>

					<slot name="actions">
						<CCol v-if="!isMobile" class="text-right">
							<template>
								<button type="button" class="btn btn-outline-dark ml-2" @click="exportExcel">
									{{ $t("DropDown.ExportExcel") }}
								</button>
							</template>
						</CCol>
					</slot>
				</CRow>
			</CCardBody>

			<!--  Platform Index Report -->
			<CCardBody>
				<slot name="filters">
					<div class="filters">
						<div v-if="isSystemAdmin">
							<div>
								<multiselect
									id="type2"
									v-model="typePlatform"
									:options="listTypePlatform"
									:custom-label="lablePlatformReport"
									:multiple="false"
									:close-on-select="true"
									:clear-on-select="false"
									:show-labels="false"
									:searchable="false"
									:placeholder="$t('DropDown.ExportSelection')"
								>
									<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
									<template #noResult>{{ $t("DropDown.NoResult") }}</template>
								</multiselect>
							</div>
						</div>
						<!-- Examination date -->
						<div>
							<date-picker
								v-model="datePlatformIndex"
								type="month"
								value-type="format"
								class="w-100"
								:confirm="false"
								:confirm-text="$t('Button.Find')"
								:editable="false"
								:placeholder="$t('DatePicker.SelectDateTime')"
							></date-picker>
						</div>
					</div>
				</slot>

				<CRow class="form-group d-md-flex">
					<CCol class="text-left">
						<slot name="tabs" />
					</CCol>

					<slot name="actions">
						<CCol v-if="!isMobile" class="text-right">
							<template>
								<button
									type="button"
									class="btn btn-outline-dark ml-2"
									@click="exportPlatformIndex"
								>
									{{ $t("DropDown.ExportExcel") }}
								</button>
							</template>
						</CCol>
					</slot>
				</CRow>
			</CCardBody>
			<!-- End of Platform Index Report -->
		</CCard>
	</div>
</template>

<script>
import { USER_ROLE, REPORT_TYPE } from "@/shared/plugins/constants"
import { mapState } from "vuex"
import timezone from "moment-timezone"

export default {
	name: "CustomTableRiskReport",

	components: {
		// eslint-disable-next-line vue/no-unused-components
		VueApexCharts: () => import("../../modules/report/components/VueApexCharts"),
	},
	props: {
		totalItems: {
			type: Number,
			default: 0,
		},
		isPrep: {
			type: Boolean,
			default: false,
		},
		series: {
			type: Array,
			default: () => [],
		},
		isMobile: {
			type: [Boolean, String],
			default: false,
		},
		mobileRole: {
			type: [String],
			default: null,
		},
	},
	data() {
		return {
			dateRange: null,
			listType: [REPORT_TYPE.RISK_ASSESS, REPORT_TYPE.ORAQUICK_PREP],
			type: [REPORT_TYPE.RISK_ASSESS, REPORT_TYPE.ORAQUICK_PREP],
			typePlatform: [REPORT_TYPE.PLATFORM_INDEX],
			listTypePlatform: [REPORT_TYPE.PLATFORM_INDEX],
			datePlatformIndex: null,
		}
	},

	computed: {
		...mapState("authentication", ["currentUser"]),

		isSystemAdmin() {
			const role = this.isMobile ? this.mobileRole : this.currentUser?.role
			return role === USER_ROLE.SYSTEM_ADMIN
		},
	},

	watch: {},
	mounted() {
		this.setAutocompleteOff()
	},

	methods: {
		exportPlatformIndex() {
			const params = {}
			if (this.datePlatformIndex) {
				params["date"] = this.datePlatformIndex
			}
			this.$emit("exportPlatformIndex", { ...params, timezone: timezone.tz.guess() })
		},
		exportExcel() {
			const params = {}
			if (this.type && this.type.length > 0) {
				params["reportType"] = this.type.join(",")
			}
			if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
				params["fromDate"] = this.dateRange[0]
				params["toDate"] = this.dateRange[1]
			}
			console.log(params)
			this.$emit("export", { ...params, timezone: timezone.tz.guess() })
		},

		setAutocompleteOff() {
			const elements = document.getElementsByClassName("multiselect__input")

			if (elements.length) {
				elements.forEach(element => {
					element.autocomplete = "off"
				})
			}
		},
		labelTypeReport(value) {
			return {
				[REPORT_TYPE.RISK_ASSESS]: this.$t("RiskReportType.RISK_ASSESS"),
				[REPORT_TYPE.ORAQUICK_PREP]: this.$t("RiskReportType.ORAQUICK_PREP"),
			}[value]
		},
		lablePlatformReport() {
			return this.$t("RiskReportType.PLATFORM_INDEX")
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.custom-table {
	.table td.fit,
	.table th.fit {
		white-space: nowrap;
		width: 1%;
	}

	.table th {
		background-color: $table-header-color;
		color: $base-color;
		font-weight: 600;
	}

	&__footer {
		display: grid;
		grid-template-columns: 1fr auto auto;

		.options {
			justify-self: end;
		}

		.pagination {
			margin-left: 15px;
			margin-bottom: 0;
		}

		&__total {
			grid-column: -1/1;

			@media (min-width: $xxs) {
				grid-column: initial;
			}
		}
	}

	.actions {
		span {
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.col-w-3 {
	width: 25%;
	flex: 0 0 25%;
}
</style>
