<template>
	<div>
		<CModal
			:show.sync="modalShow"
			:close-on-backdrop="false"
			:centered="true"
			:size="size"
			class="custom-modal"
		>
			<slot name="content" />

			<template v-if="customHeader" #header-wrapper><slot name="header" /></template>
			<template #header>
				<h6 class="modal-title">
					{{ title }}
				</h6>
				<CButtonClose class="btn" @click="cancel" />
			</template>

			<template v-if="customFooter" #footer-wrapper><slot name="footer" /></template>
			<template v-else #footer>
				<CButton :color="cancelColor" @click="cancel">
					{{ cancelText }}
				</CButton>
				<CButton v-if="isShowSubmit" type="submit" color="outline-info" @click="submit">
					{{ submitText }}
				</CButton>
			</template>
		</CModal>
	</div>
</template>

<script>
import i18n from "@/shared/plugins/i18n"

export default {
	name: "CustomModal",

	props: {
		title: {
			type: String,
			default: i18n.t("Button.Noti"),
		},
		size: {
			type: String,
			default: "",
		},
		submitText: {
			type: String,
			default: i18n.t("Button.Submit"),
		},
		cancelText: {
			type: String,
			default: i18n.t("Button.Cancel"),
		},
		isShowSubmit: {
			type: Boolean,
			default: true,
		},
		cancelColor: {
			type: String,
			default: "outline-danger",
		},
		customHeader: {
			type: Boolean,
			default: false,
		},
		customFooter: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		modalShow() {
			return this.$store.state.modalShow
		},
	},

	methods: {
		submit() {
			this.$emit("submit-modal")
		},

		cancel() {
			this.$emit("cancel-modal")
			this.$store.commit("set", ["modalShow", false])
		},
	},
}
</script>
<style lang="scss">
.modal-content {
	.modal-header {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		.close {
			text-align: right;
			margin: 0;
		}
	}
}
</style>
