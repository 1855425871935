import i18n from "@/shared/plugins/i18n"

const RegisterEvaluation = () => import("./pages/RegisterEvaluation")
const AssessmentForm = () => import("./pages/AssessmentForm")
const EvaluationResult = () => import("./pages/EvaluationResult")
const CompleteEvaluation = () => import("./pages/CompleteEvaluation")

const riskRoutes = [
	// Embbed in xomcauvong
	{
		path: "/register-eval",
		name: "RegisterEvaluation",
		component: RegisterEvaluation,
		meta: {
			title: i18n.t("RiskAssessment.RegisterEvaluation"),
			label: i18n.t("RiskAssessment.RegisterEvaluation"),
		},
	},
	{
		path: "/survey-risk",
		name: "AssessmentForm",
		component: AssessmentForm,
		props: route => ({
			fullName: route.query.fullname,
			birthYear: route.query.birth,
			phoneNumber: route.query.phone,
		}),
		meta: {
			title: i18n.t("RiskAssessment.AssessmentForm"),
			label: i18n.t("RiskAssessment.AssessmentForm"),
		},
	},
	{
		path: "/survey-risk/result",
		name: "EvaluationResult",
		component: EvaluationResult,
		meta: {
			title: i18n.t("RiskAssessment.EvaluationResult"),
			label: i18n.t("RiskAssessment.EvaluationResult"),
		},
	},
	{
		path: "/survey-risk/finish",
		name: "CompleteEvaluation",
		component: CompleteEvaluation,
		meta: {
			title: i18n.t("RiskAssessment.CompleteEvaluation"),
			label: i18n.t("RiskAssessment.CompleteEvaluation"),
		},
	},

	// Embbed in mobile
	{
		path: "/survey-risk-mobile",
		name: "AssessmentFormMobile",
		component: AssessmentForm,
		props: route => ({
			isMobile: true,
			fullName: route.query.fullname,
			birthYear: route.query.birth,
			phoneNumber: route.query.phone,
		}),
		meta: {
			title: i18n.t("RiskAssessment.AssessmentForm"),
			label: i18n.t("RiskAssessment.AssessmentForm"),
		},
	},
	{
		path: "/survey-risk-mobile/result",
		name: "EvaluationResultMobile",
		component: EvaluationResult,
		props: {
			isMobile: true,
		},
		meta: {
			title: i18n.t("RiskAssessment.EvaluationResult"),
			label: i18n.t("RiskAssessment.EvaluationResult"),
		},
	},
	{
		path: "/survey-risk-mobile/finish",
		name: "CompleteEvaluationMobile",
		component: CompleteEvaluation,
		props: {
			isMobile: true,
		},
		meta: {
			title: i18n.t("RiskAssessment.CompleteEvaluation"),
			label: i18n.t("RiskAssessment.CompleteEvaluation"),
		},
	},
]

export default riskRoutes
