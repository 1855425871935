import router from "@/router"
import API from "../services"
import CustomToastr from "@/shared/plugins/toastr"
import i18n from "@/shared/plugins/i18n"

import {
	GET_ADMINS,
	CREATE_ADMIN,
	GET_ADMIN_BY_ID,
	UPDATE_ADMIN_BY_ID,
	UPDATE_ADMIN_PASSWORD,
	DELETE_ADMIN_BY_ID,
	GET_CLINIC,
} from "./action-types"

import {
	GET_ADMINS_SUCCESS,
	GET_ADMIN_BY_ID_SUCCESS,
	DELETE_ADMIN_BY_ID_SUCCESS,
	GET_CLINIC_SUCCESS,
	CHANGE_STATUS_PASSWORD_MODAL,
} from "./mutation-types"

export default {
	[GET_ADMINS]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getAdmins(params)
			commit(GET_ADMINS_SUCCESS, { ...res, filters: params })
		} catch (error) {}
	},

	// eslint-disable-next-line
	[CREATE_ADMIN]: async ({}, params = {}) => {
		try {
			await API.createAdmin(params)

			router.push({ name: "AdminList" })
		} catch (error) {}
	},

	[GET_ADMIN_BY_ID]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getAdminById(params)
			commit(GET_ADMIN_BY_ID_SUCCESS, res)
		} catch (error) {
			router.push({ name: "AdminList" })
		}
	},

	// eslint-disable-next-line
	[UPDATE_ADMIN_BY_ID]: async ({}, params = {}) => {
		try {
			await API.updateAdminById(params)

			router.push({ name: "AdminList" })
		} catch (error) {}
	},

	[UPDATE_ADMIN_PASSWORD]: async ({ commit }, params = {}) => {
		try {
			await API.updateAdminPassword(params)
			commit(CHANGE_STATUS_PASSWORD_MODAL, false)

			CustomToastr.success(i18n.t("ChangePwd.Success"))
		} catch (error) {}
	},

	[DELETE_ADMIN_BY_ID]: async ({ commit }, params = {}) => {
		try {
			const res = await API.deleteAdminById(params)
			commit(DELETE_ADMIN_BY_ID_SUCCESS, res)

			router.push({ name: "AdminList" })
		} catch (error) {}
	},

	[GET_CLINIC]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getClinics(params)
			commit(GET_CLINIC_SUCCESS, res)
		} catch (error) {}
	},
}
