import store from "@/store"

import {
	GET_ADMINS_SUCCESS,
	RESET_FILTERS_ADMIN_LIST,
	GET_ADMIN_BY_ID_SUCCESS,
	DELETE_ADMIN_BY_ID_SUCCESS,
	GET_CLINIC_SUCCESS,
	CHANGE_STATUS_ADMIN_MODAL,
	CHANGE_STATUS_DISCARD_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	CHANGE_STATUS_PASSWORD_MODAL,
} from "./mutation-types"

export default {
	[GET_ADMINS_SUCCESS]: function (state, payload = {}) {
		state.admins = payload.items
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[RESET_FILTERS_ADMIN_LIST]: function (state) {
		state.admins = null
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			keyword: "",
			offset: 0,
			limit: process.env.VUE_APP_LIMIT_LIST,
		}
	},

	[GET_ADMIN_BY_ID_SUCCESS]: function (state, payload = {}) {
		state.recentAdmin = payload
	},

	[DELETE_ADMIN_BY_ID_SUCCESS]: function (state) {
		state.recentAdmin = null
	},

	[GET_CLINIC_SUCCESS]: function (state, payload = {}) {
		state.clinics = payload
	},

	[CHANGE_STATUS_ADMIN_MODAL]: function (state, payload = {}) {
		state.isShowStatusModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DISCARD_MODAL]: function (state, payload = {}) {
		state.isShowDiscardModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DELETE_MODAL]: function (state, payload = {}) {
		state.isShowDeleteModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_PASSWORD_MODAL]: function (state, payload = {}) {
		state.isShowPasswordModal = payload
		store.commit("set", ["modalShow", payload])
	},
}
