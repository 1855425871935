import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	messages: [],
	totalItems: null,
	totalPages: null,
	recentFilters: {
		offset: 0,
		limit: process.env.VUE_APP_LIMIT_LIST,
	},

	recentMess: null,
	isShowDiscardModal: null,
	isShowDeleteModal: null,

	setting: [
		{ month: 1, week: 1, times: null },
		{ month: 1, week: 2, times: null },
		{ month: 1, week: 3, times: null },
		{ month: 1, week: 4, times: null },
		{ month: 2, week: 1, times: null },
		{ month: 2, week: 2, times: null },
		{ month: 2, week: 3, times: null },
		{ month: 2, week: 4, times: null },
		{ month: 3, week: 1, times: null },
		{ month: 3, week: 2, times: null },
		{ month: 3, week: 3, times: null },
		{ month: 3, week: 4, times: null },
		{ month: 4, week: 1, times: null },
		{ month: 4, week: 2, times: null },
		{ month: 4, week: 3, times: null },
		{ month: 4, week: 4, times: null },
		{ month: 5, week: 1, times: null },
		{ month: 5, week: 2, times: null },
		{ month: 5, week: 3, times: null },
		{ month: 5, week: 4, times: null },
		{ month: 6, week: 1, times: null },
		{ month: 6, week: 2, times: null },
		{ month: 6, week: 3, times: null },
		{ month: 6, week: 4, times: null },
		{ month: 7, week: 1, times: null },
		{ month: 7, week: 2, times: null },
		{ month: 7, week: 3, times: null },
		{ month: 7, week: 4, times: null },
		{ month: 8, week: 1, times: null },
		{ month: 8, week: 2, times: null },
		{ month: 8, week: 3, times: null },
		{ month: 8, week: 4, times: null },
		{ month: 9, week: 1, times: null },
		{ month: 9, week: 2, times: null },
		{ month: 9, week: 3, times: null },
		{ month: 9, week: 4, times: null },
		{ month: 10, week: 1, times: null },
		{ month: 10, week: 2, times: null },
		{ month: 10, week: 3, times: null },
		{ month: 10, week: 4, times: null },
		{ month: 11, week: 1, times: null },
		{ month: 11, week: 2, times: null },
		{ month: 11, week: 3, times: null },
		{ month: 11, week: 4, times: null },
		{ month: 12, week: 1, times: null },
		{ month: 12, week: 2, times: null },
		{ month: 12, week: 3, times: null },
		{ month: 12, week: 4, times: null },
		{ month: 13, week: 1, times: null },
		{ month: 13, week: 2, times: null },
		{ month: 13, week: 3, times: null },
		{ month: 13, week: 4, times: null },
		{ month: 14, week: 1, times: null },
		{ month: 14, week: 2, times: null },
		{ month: 14, week: 3, times: null },
		{ month: 14, week: 4, times: null },
		{ month: 15, week: 1, times: null },
		{ month: 15, week: 2, times: null },
		{ month: 15, week: 3, times: null },
		{ month: 15, week: 4, times: null },
		{ month: 16, week: 1, times: null },
		{ month: 16, week: 2, times: null },
		{ month: 16, week: 3, times: null },
		{ month: 16, week: 4, times: null },
		{ month: 17, week: 1, times: null },
		{ month: 17, week: 2, times: null },
		{ month: 17, week: 3, times: null },
		{ month: 17, week: 4, times: null },
		{ month: 18, week: 1, times: null },
		{ month: 18, week: 2, times: null },
		{ month: 18, week: 3, times: null },
		{ month: 18, week: 4, times: null },
	],
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
