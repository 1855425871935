<template>
	<div class="custom-table">
		<CCard>
			<CCardBody>
				<slot v-if="showFilters" name="filters">
					<div class="filters">
						<!-- Search -->
						<div>
							<input
								id="search"
								v-model.trim="keyword"
								class="form-control custom-input"
								type="text"
								:placeholder="searchPlaceholder"
								:maxlength="maxLength"
								@keydown="searchDebounce"
							/>
						</div>
					</div>
				</slot>

				<CRow class="form-group d-md-flex">
					<CCol class="text-left">
						<slot name="tabs" />
					</CCol>

					<slot v-if="showActions" name="actions">
						<CCol class="text-right">
							<CButton color="info" @click="create">{{ $t("DropDown.Create") }}</CButton>
						</CCol>
					</slot>
				</CRow>

				<slot name="table">
					<div class="table-responsive-md">
						<table class="table table-bordered table-hover table-responsive table-fit">
							<thead>
								<slot name="headers" />
							</thead>
							<tbody>
								<slot name="body" />
							</tbody>
						</table>
					</div>
				</slot>

				<slot name="footer">
					<CRow>
						<!-- Select limit  -->
						<CCol class="custom-table__footer">
							<CRow class="m-0 mb-3 options">
								<div class="align-self-center">{{ rangeItemsOfTotal }}</div>

								<!-- pagination -->
								<CPagination
									v-if="totalPages > 1"
									:active-page.sync="recentPage"
									:pages="totalPages"
									align="end"
								/>
							</CRow>
						</CCol>
					</CRow>
				</slot>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { MANAGEMENT } from "@/shared/plugins/constants"
import { debounce } from "lodash-es"

export default {
	name: "CommonTable",

	props: {
		totalPages: {
			type: [Number, String],
			default: 0,
		},
		recentFilter: {
			type: Object,
			default: () => {},
		},
		showFilters: {
			type: Boolean,
			default: true,
		},
		showActions: {
			type: Boolean,
			default: true,
		},
		totalItems: {
			type: Number,
			default: 0,
		},
		type: {
			type: String,
			default: null,
		},
	},

	data() {
		return {
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			limit: process.env.VUE_APP_LIMIT_LIST,
			offset: 0,
			recentPage: 1,

			keyword: "",
		}
	},

	computed: {
		rangeItemsOfTotal: function () {
			if (!this.totalPages) return "0 - 0 of 0"

			const from = Number(this.offset) + 1
			const to =
				from / this.limit < this.totalPages - 1
					? Number(this.offset) + Number(this.limit)
					: this.totalItems

			return `${from} - ${to} of ${this.totalItems}`
		},

		searchPlaceholder: function () {
			if (this.type) {
				return {
					[MANAGEMENT.ADMIN]: this.$t("AdminForm.Search"),
					[MANAGEMENT.CLINIC]: this.$t("ClinicForm.Search"),
				}[this.type]
			}

			return this.$t("DropDown.Search")
		},
	},

	watch: {
		recentPage(val) {
			if (val !== Math.floor(this.offset / this.limit) + 1) {
				this.offset = (val - 1) * this.limit
				this.search()
			}
		},
	},

	created() {
		this.setData()
	},

	methods: {
		setData() {
			const queryParams = this.$route.query
			this.keyword = queryParams.keyword || this.recentFilter?.keyword || this.keyword

			this.limit = queryParams.limit || this.recentFilter.limit || this.limit
			this.offset = queryParams.offset || this.recentFilter.offset || this.offset
			this.recentPage = Math.floor(this.offset / this.limit) + 1

			if (Object.keys(queryParams).length) {
				this.$emit("search", queryParams)
			} else this.search()
		},

		create() {
			this.$emit("create")
		},

		searchDebounce: debounce(function () {
			this.resetPaging()
		}, process.env.VUE_APP_DEBOUNCE_TIME),

		search() {
			const params = {
				limit: this.limit,
				offset: this.offset,
			}

			if (this.keyword) {
				params["keyword"] = this.keyword
			}

			this.$router.push({ query: params }).catch(error => {
				if (error.name !== "NavigationDuplicated") {
					throw error
				}
			})
			this.$emit("search", params)
		},

		changeLimit(selected) {
			this.limit = selected
			this.recentPage = Math.floor(this.offset / selected) + 1
			this.offset = (this.recentPage - 1) * selected
			this.search()
		},

		resetPaging() {
			this.offset = 0
			this.recentPage = 1
			this.search()
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.custom-table {
	.table td.fit,
	.table th.fit {
		white-space: nowrap;
		width: 1%;
	}

	.table th {
		background-color: $table-header-color;
		color: $base-color;
		font-weight: 600;
	}

	&__footer {
		display: grid;
		grid-template-columns: 1fr auto auto;

		.options {
			justify-self: end;
		}

		/deep/ .pagination {
			margin-left: 15px;
			margin-bottom: 0;
		}

		&__total {
			grid-column: -1/1;

			@media (min-width: $xxs) {
				grid-column: initial;
			}
		}
	}

	.actions {
		span {
			&:hover {
				cursor: pointer;
			}
		}
	}
}

/deep/ .multiselect {
	&__tags {
		height: 35px;
		min-height: 35px;
		padding-top: 6px;
	}
	&__select {
		height: 36px;
	}
}

.col-w-3 {
	width: 25%;
	flex: 0 0 25%;
}
.b-d-0 {
	border-radius: 0;
}
.w-135 {
	width: 135px;
}
.activeTab {
	background-color: #95bcf2;
	color: $color-white;
}
</style>
