import router from "@/router"
import API from "../services"

import {
	GET_MESSAGES,
	GET_MESSAGE_BY_ID,
	CREATE_MESSAGE,
	UPDATE_MESSAGE,
	DELETE_MESSAGE,
} from "./action-types"

import {
	GET_MESSAGES_SUCCESS,
	GET_MESSAGE_BY_ID_SUCCESS,
	DELETE_MESSAGE_SUCCESS,
} from "./mutation-types"

export default {
	[GET_MESSAGES]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getMessages(params)
			commit(GET_MESSAGES_SUCCESS, { ...res, filters: params })
		} catch (error) {}
	},

	[GET_MESSAGE_BY_ID]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getMessageById(params)
			commit(GET_MESSAGE_BY_ID_SUCCESS, res)
		} catch (error) {
			router.push({ name: "MessageList" })
		}
	},

	// eslint-disable-next-line
	[CREATE_MESSAGE]: async ({}, params = {}) => {
		try {
			await API.createMessage(params)

			router.push({ name: "MessageList" })
		} catch (error) {}
	},

	// eslint-disable-next-line
	[UPDATE_MESSAGE]: async ({}, params = {}) => {
		try {
			await API.updateMessage(params)

			router.push({ name: "MessageList" })
		} catch (error) {}
	},

	[DELETE_MESSAGE]: async ({ commit }, params = {}) => {
		try {
			const res = await API.deleteMessage(params)
			commit(DELETE_MESSAGE_SUCCESS, res)

			router.push({ name: "MessageList" })
		} catch (error) {}
	},
}
