import { callAPI } from "@/shared/services"

export default {
	// Start of Admin Side
	getAppointment: params => {
		return callAPI("GET", "admin/appointment", {
			params,
		}).then(res => res.data)
	},

	adminCreateAppt: params => {
		return callAPI("POST", "admin/appointment/create", params).then(res => res.data)
	},

	getApptById: params => {
		return callAPI("GET", `admin/appointment/${params.id}`).then(res => res.data)
	},

	updateApptById: params => {
		return callAPI("POST", `admin/appointment/${params.id}`, params).then(res => res.data)
	},

	deleteApptById: params => {
		return callAPI("DELETE", `admin/appointment/${params.id}`).then(res => res.data)
	},

	getListClinics: params => {
		return callAPI("GET", "clinic", {
			params,
			notLoading: true,
		}).then(res => res.data)
	},

	getCustomers: params => {
		return callAPI("GET", "admin/customer/search", {
			params,
			notLoading: true,
		}).then(res => res.data)
	},

	importFile: params => {
		return callAPI("POST", "admin/appointment/import", params, {
			notLoading: true,
			timeout: process.env.VUE_APP_TIMEOUT_IMPORT,
		}).then(res => res.data)
	},

	getImportStatus: params => {
		return callAPI("GET", "admin/appointment/import/status", {
			params: params,
			notLoading: true,
		}).then(res => res.data)
	},

	finishImport: params => {
		return callAPI("GET", "admin/appointment/import/completed", {
			params: params,
			notLoading: true,
		}).then(res => res.data)
	},

	mergeConflictImport: params => {
		return callAPI("PUT", "admin/appointment/merge-customer", params).then(res => res.data)
	},

	exportFileAppt: params => {
		return callAPI("GET", "admin/appointment/export-appointment", {
			params,
			timeout: process.env.VUE_APP_TIMEOUT_IMPORT,
		}).then(response => {
			const url = response.data.fileUrl
			const link = document.createElement("a")
			link.href = url
			link.setAttribute("download", null)
			document.body.appendChild(link)
			link.click()
		})
	},
	// End of Adnin Side

	// Start of Customer Side
	createAppointment: params => {
		return callAPI("POST", "appointment", params).then(res => res.data)
	},

	updateAppointment: params => {
		return callAPI("POST", "appointment/update-unofficial-appointment", params).then(
			res => res.data,
		)
	},

	searchAppointment: params => {
		return callAPI("GET", "appointment/search", {
			params,
		}).then(res => res.data)
	},

	cancelAppointment: params => {
		return callAPI("POST", "appointment/cancel-unofficial-appointment", params).then(
			res => res.data,
		)
	},

	getMainCities: params => {
		return callAPI("GET", "masterdata/main-city", {
			params,
			notLoading: true,
		}).then(res => res.data)
	},
	// End of Customer Side

	// Both
	getServices: params => {
		return callAPI("GET", "masterdata/services", {
			params,
			notLoading: true,
		}).then(res => res.data)
	},

	getClinics: params => {
		return callAPI("GET", "appointment/clinics", {
			params,
			notLoading: true,
		}).then(res => res.data)
	},
}
