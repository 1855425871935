import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	clinic: [],
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
