// tuts: https://webpack.js.org/guides/dependency-management/
function importAll(r) {
	return r.keys().map(r)
}

// import common locale
importAll(require.context(".", false, /\.js$/))

// import all module locale
importAll(require.context("../../modules/", true, /.*\/lang\/.*$/))
