import { callAPI } from "@/shared/services"

export default {
	getClinics: params => {
		return callAPI("GET", "admin/clinic/", { params }).then(res => res.data)
	},

	getClinicById: params => {
		return callAPI("GET", `admin/clinic/${params.id}`).then(res => res.data)
	},

	createClinic: params => {
		return callAPI("POST", "admin/clinic", params).then(res => res.data)
	},

	updateClinic: params => {
		return callAPI("POST", `admin/clinic/${params.get("id")}`, params).then(res => res.data)
	},

	deleteClinic: params => {
		return callAPI("DELETE", `admin/clinic/${params.id}`, params).then(res => res.data)
	},

	getCities: params => {
		return callAPI("GET", "masterdata/cities", { params, notLoading: true }).then(res => res.data)
	},

	uploadLink: params => {
		return callAPI("GET", "admin/clinic/upload-link", { params, notLoading: true }).then(
			res => res.data,
		)
	},

	uploadLinkS3: params => {
		return callAPI("PUT", "", params.file, {
			baseURL: params.url,
			notLoading: true,
		}).then(res => res.data)
	},

	getServices: params => {
		return callAPI("GET", "masterdata/services", {
			params,
			notLoading: true,
		}).then(res => res.data)
	},
}
