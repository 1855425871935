import router from "@/router"
import API from "../services"
import CustomToastr from "@/shared/plugins/toastr"
import i18n from "@/shared/plugins/i18n"
// import * as firebase from "@/shared/plugins/firebase"

import {
	LOGIN,
	LOGOUT,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	CHANGE_PASSWORD,
	// SEND_OTP,
	SEND_OTP_FORGOT,
	GET_OTP_AGAIN,
	REFRESH_TOKEN,
	GET_USER_LOGIN_PROFILE,
	UPDATE_USER_LOGIN_PROFILE,
	CHECK_TOKEN_VALID,
} from "./action-types"

import {
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	SEND_OTP_SUCCESS,
	SEND_OTP_FORGOT_SUCCESS,
	FORGOT_PASSWORD_SUCCESS,
	REFRESH_TOKEN_SUCCESS,
	GET_USER_LOGIN_PROFILE_SUCCESS,
	UPDATE_USER_LOGIN_PROFILE_SUCCESS,
	CHECK_TOKEN_VALID_SUCCESS,
	CHECK_TOKEN_VALID_FAILED,
	CHANGE_STATUS_LOGOUT_MODAL,
	CHANGE_STATUS_CHANGE_PASSWORD_MODAL,
	CHANGE_STATUS_UPDATE_PROFILE,
} from "./mutation-types"

import { TYPE, STATUS } from "../../../shared/plugins/constants"

export default {
	[LOGIN]: async ({ dispatch, commit }, params = {}) => {
		const res = await API.login({ email: params.username, password: params.password })
		commit(LOGIN_SUCCESS, params)
		commit(SEND_OTP_SUCCESS, res)
		await dispatch(GET_USER_LOGIN_PROFILE, { notLoading: true })
		router.push({ path: `${router.currentRoute.query.redirect}` || "/" })
	},

	[SEND_OTP_FORGOT]: async ({ commit }, params = {}) => {
		try {
			params.type = TYPE.RESET_PASSWORD
			await API.forgotVerifyOTP(params)
			commit(SEND_OTP_FORGOT_SUCCESS, params)
			router.push({
				path: "reset-password",
				query: router.currentRoute.query || {},
			})
		} catch (error) {}
	},

	[GET_OTP_AGAIN]: async () => {
		try {
			const email = localStorage.getItem("emailSendOTP")
			await API.recoverPwd({ email: email, type: TYPE.RESET_PASSWORD })
		} catch (error) {}
	},

	[LOGOUT]: async ({ commit }) => {
		commit(LOGOUT_SUCCESS)
		await commit(CHANGE_STATUS_LOGOUT_MODAL, false)
		router.push("/login")
	},

	[FORGOT_PASSWORD]: async ({ commit }, params = {}) => {
		try {
			await API.recoverPwd({ email: params.email, type: TYPE.RESET_PASSWORD })
			localStorage.setItem("emailSendOTP", params.email)
			commit(FORGOT_PASSWORD_SUCCESS, params)
			router.push({
				path: "login-otp",
				query: router.currentRoute.query || {},
			})
		} catch (error) {}
	},

	[RESET_PASSWORD]: async ({ commit }, params = {}) => {
		try {
			const res = await API.resetPwd(params)
			commit("set", ["modalShow", true], { root: true })
			router.push({
				path: "login",
				query: router.currentRoute.query || {},
			})
			return res
		} catch (error) {}
	},

	[CHANGE_PASSWORD]: async ({ commit }, params = {}) => {
		try {
			await API.changePwdMe(params)
			commit(CHANGE_STATUS_CHANGE_PASSWORD_MODAL, false)
			CustomToastr.success(i18n.t("ChangePwd.Success"))
		} catch (error) {}
	},

	[REFRESH_TOKEN]: async ({ dispatch, commit, state }) => {
		try {
			const params = {
				token: state.refreshToken,
			}
			const res = await API.refreshToken(params)
			commit(REFRESH_TOKEN_SUCCESS, res)
			return res
		} catch (error) {
			await dispatch(LOGOUT)
			throw error
		}
	},

	[GET_USER_LOGIN_PROFILE]: async ({ commit }, param = {}) => {
		try {
			const res = await API.getUserProfile(param)
			commit(GET_USER_LOGIN_PROFILE_SUCCESS, res)
		} catch (error) {
			router.push({ path: "/" })
		}
	},

	[UPDATE_USER_LOGIN_PROFILE]: async ({ commit }, params = {}) => {
		try {
			params.status = STATUS.ACTIVE
			const res = await API.updateUserProfile(params)
			CustomToastr.success(i18n.t("Profile.Success"))
			commit(UPDATE_USER_LOGIN_PROFILE_SUCCESS, res)
			commit(CHANGE_STATUS_UPDATE_PROFILE, false)
		} catch (error) {}
	},

	[CHECK_TOKEN_VALID]: async ({ commit }) => {
		try {
			// await API.checkTokenValid(params)
			commit(CHECK_TOKEN_VALID_SUCCESS)
		} catch (error) {
			commit(CHECK_TOKEN_VALID_FAILED, error.msg)
		}
	},
}
