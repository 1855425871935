import { callAPI } from "@/shared/services"

export default {
	riskAssessment: params => {
		return callAPI("POST", "risk-accessment", params).then(res => res.data)
	},

	finishAssessment: params => {
		return callAPI("POST", "risk-accessment/complete", params).then(res => res.data)
	},

	getRiskQuestions: params => {
		return callAPI("GET", "risk-accessment", {
			params: params,
		}).then(res => res.data)
	},
}
