import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	// Start of Admin Side
	appointment: [],
	customers: [],
	totalCustomer: null,
	filtersCustomer: {
		limit: Number(process.env.VUE_APP_LIMIT_LIST),
		offset: 0,
	},
	totalItems: null,
	totalPages: null,
	recentFilters: {
		key: null,
		serviceIds: null,
		clinicIds: null,
		statuses: null,
		customerTypes: null,
		limit: process.env.VUE_APP_LIMIT_LIST,
		offset: 0,
	},
	isShowDiscardModal: false,
	isUploadingFile: false,
	isShowProgressBar: false,
	isShowShortcutImport: false,
	isShowImportSuccess: false,
	isShowDelModal: false,
	importStatus: null,
	importFileId: null,
	intervalImport: null,
	// End of Admin Side

	// Start of Customer Side
	// End of Customer Side

	// Both
	recentAppt: null,
	mainCities: [],
	services: [],
	clinics: [],
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
