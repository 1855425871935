export const GET_CLINICS_SUCCESS = "GET_CLINICS_SUCCESS"
export const RESET_FILTERS_CLINIC_LIST = "RESET_FILTERS_CLINIC_LIST"

export const GET_CLINIC_BY_ID_SUCCESS = "GET_CLINIC_BY_ID_SUCCESS"
export const DELETE_CLINIC_SUCCESS = "DELETE_CLINIC_SUCCESS"

export const CHANGE_STATUS_DISCARD_MODAL = "CHANGE_STATUS_DISCARD_MODAL"
export const CHANGE_STATUS_DELETE_MODAL = "CHANGE_STATUS_DELETE_MODAL"
export const CHANGE_STATUS_CLINIC_MODAL = "CHANGE_STATUS_CLINIC_MODAL"

export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS"
export const GET_CITIES_FAILED = "GET_CITIES_FAILED"

export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS"
