import Vue from "vue"
import { camelCase, snakeCase } from "lodash-es"
import moment from "moment"

const checkValidPhone = event => {
	const specialKeyCodes = [8, 9, 13, 46, 37, 39] // backspace, enter, tab, delete, arrow left. arrow right
	const ctrlCombines = [65, 67, 86, 88] // ctrl A, ctrl C, ctrl V, ctrl X
	const countryKeyCodes = [231] // country keyboards on window: vietnamese
	if (
		!event.shiftKey &&
		((event.keyCode >= 48 && event.keyCode <= 57) ||
			(event.keyCode >= 96 && event.keyCode <= 105) ||
			(event.ctrlKey && ctrlCombines.includes(event.keyCode)) ||
			specialKeyCodes.includes(event.keyCode) ||
			(countryKeyCodes.includes(event.keyCode) &&
				[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(+event.key)))
	) {
		return true
	}
	return event.preventDefault()
}

const checkValidName = event => {
	if (!/^[a-zA-Z\s]*$/i.test(nomalizeText(event.key))) return event.preventDefault()
}

// use for check error field response api.
// formFields: all field in form submit. Each field should be camelCase. Converted to snakeCase.
// add prefix to support check custom vee-validate fields in multi language. ex: auth_full_name
// error: {field: snakeCase , msg: string}
const checkErrorFieldResponse = (prefix = "", formFields = [], error = { field: "", msg: "" }) => {
	const validateFields = formFields.map(el => {
		el = snakeCase(el)
		return el.replace(prefix, "")
	})
	const message = {}
	if (validateFields.includes(error.field)) {
		message[`${prefix}${error.field}`] = error.msg
	}
	return message
}

const camelizeKeys = data => {
	if (Array.isArray(data)) {
		return data.map(v => camelizeKeys(v))
	} else if (data !== null && data.constructor === Object) {
		return Object.keys(data).reduce(
			(result, key) => ({
				...result,
				// eslint-disable-next-line no-unused-vars
				[camelCase(key)]: camelizeKeys(data[key]),
			}),
			{},
		)
	}
	return data
}

const mappingResponseConvention = data => {
	try {
		return camelizeKeys(data)
	} catch (error) {}
}

const mappingRequestConvention = resquest => {
	try {
		// handle request data
		if (resquest.data && resquest.data instanceof FormData) {
			let mapping = new FormData()
			resquest.data.forEach(function (value, key) {
				mapping.append(snakeCase(key), removeExtraSpaces(value))
			})
			resquest.data = mapping
			return resquest
		}
		if (resquest.data && resquest.data instanceof Object) {
			let mapping = {}
			Object.keys(resquest.data).map(field => {
				mapping[snakeCase(field)] = removeExtraSpaces(resquest.data[field])
			})
			resquest.data = { ...mapping }
		}

		// handle request params
		if (resquest.params && resquest.params instanceof FormData) {
			let mapping = new FormData()
			resquest.params.forEach(function (value, key) {
				mapping.append(snakeCase(key), removeExtraSpaces(value))
			})
			resquest.params = mapping
			return resquest
		}
		if (resquest.params && resquest.params instanceof Object) {
			let mapping = {}
			Object.keys(resquest.params).map(field => {
				mapping[snakeCase(field)] = removeExtraSpaces(resquest.params[field])
			})
			resquest.params = { ...mapping }
		}

		return resquest
	} catch (error) {}
}

const convertToDateVietNam = date => {
	const dateMoment = moment(date)

	if (dateMoment.isValid()) return dateMoment.format("DD/MM/YYYY")

	return null
}

const convertToISODate = date => {
	const dateMoment = moment(date, "DD/MM/YYYY")

	if (dateMoment.isValid()) return dateMoment.format("YYYY-MM-DD")

	return null
}

const disabledAfterToday = date => {
	const today = new Date()
	today.setHours(0, 0, 0, 0)

	return date > today
}

const disabledBeforeToday = date => {
	const today = new Date()
	today.setHours(0, 0, 0, 0)

	return date < today
}

const getKeyByValue = (object, value) => {
	return Object.keys(object).find(key => object[key] === value)
}

const removeExtraSpaces = value => {
	// if (isString(value)) {
	//   return value.replace(/  +/g, " ");
	// }
	return value
}

const getTimeLocale = (time, format = "DD/MM/YYYY HH:mm:ss") => {
	if (!moment(time)._isValid) return null
	return moment.utc(time).local().format(format)
}

const getTimeUTC = (time, currentFormat = "DD/MM/YYYY", format = "YYYY-MM-DDTHH:mm:ss") => {
	if (!moment(time, currentFormat)._isValid) return null
	return moment(time, currentFormat).utc().format(format)
}

const getTimeFromNow = (startTime, format = "DD/MM/YYYY HH:mm:ss") => {
	const nowTime = moment(new Date())
	const fromTime = moment(getTimeLocale(startTime), format)

	// different year
	if (!moment(nowTime).isSame(fromTime, "year")) {
		return moment(fromTime).format("DD/MM/YYYY HH:mm")
	}

	// date > 3 days ago
	if (nowTime.diff(fromTime, "days") > 3) {
		return moment(fromTime).format("DD/MM HH:mm")
	}

	return fromTime.from(fromTime > nowTime ? fromTime : nowTime)
}

const formatNumber = number => {
	try {
		return (number + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	} catch (error) {}
}

const addPadStartTime = number => {
	return number.toString().padStart(2, "0")
}

const nomalizeText = text => {
	if (!text || !text.length) return null

	return text
		.toLowerCase()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/đ/g, "d")
		.replace(/Đ/g, "D")
		.replace(/\s\s+/g, " ")
		.trim()
}

const mixin = {
	methods: {
		checkErrorFieldResponse,
		checkValidPhone,
		convertToDateVietNam,
		convertToISODate,
		disabledAfterToday,
		disabledBeforeToday,
		getTimeLocale,
		getTimeUTC,
		getTimeFromNow,
		formatNumber,
		nomalizeText,
		checkValidName,
	},
}

Vue.mixin(mixin)

export {
	checkErrorFieldResponse,
	checkValidPhone,
	mappingResponseConvention,
	mappingRequestConvention,
	convertToDateVietNam,
	convertToISODate,
	disabledAfterToday,
	disabledBeforeToday,
	getKeyByValue,
	formatNumber,
	getTimeLocale,
	addPadStartTime,
}
