import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	CustomerTitle: {
		CustomerDetail: "Chi tiết thông tin",
		CreateCustomer: "Tạo mới khách hàng",
		UpdateCustomer: "Chỉnh sửa thông tin",
		CustomerManagement: "Quản lý khách hàng",
		CustomerList: "Danh sách khách hàng",
		CustomerInformation: "Thông tin khách hàng",
		Examination: "Danh sách khách hàng",
		CustomerResultDetail: "Đánh giá nguy cơ",
		CustomerGiftDetail: "Nhận quà miễn phí",
	},

	CustomerForm: {
		ID: "Mã khách hàng",
		Name: "Tên khách hàng",
		Type: "Loại khách hàng",
		Birthday: "Năm sinh",
		Clinic: "Phòng khám",
		Filter_date: "Ngày sàng lọc",
		Prep_date: "Ngày bắt đầu dùng PrEP",
		Contact: "Phương thức liên hệ",
		_Contact: "Phương thức",
		Address: "Địa chỉ",
		Count: "Số lần khám",
		Status: "Trạng thái",
		Note: "Ghi chú",

		Customer_type: "Loại KH",
		Customer_code_prep: "Mã KH PrEP",
		classify: "Phân loại",
		CMND: "CMND/CCCD",
		Gender_focus: "Đối tượng",
		Gender: "Giới tính (theo giấy tờ tùy thân)",
		Phone: "Điện thoại",
		Email: "Email",
		Male: "Nam",
		Female: "Nữ",
		DateFormat: "dd/mm/yyyy",
		Update: "Chỉnh sửa",
		Title: "Thông tin khách hàng",
		MaxTickets: "Số yêu cầu tối đa",

		Customer: "Khách hàng",
		CustomerCode: "Mã KH",
		CustomerName: "Họ tên",
		CustomerSDT: "SĐT",

		ConfirmStatus:
			"Thay đổi trạng thái thành không hoạt động có nghĩa là chặn khách hàng này đăng nhập vào mobile app, điều này khiến họ không thể sử dụng các tính năng của ứng dụng nữa.",
		AreYouSure: "Bạn chắc chứ?",
		DeleteDescription:
			"Sau khi xác nhận, tài khoản này sẽ bị xóa ở mọi nơi và sẽ không thể thấy nó nữa.",

		FilterPrEPDate: "Lọc ngày bắt đầu dùng PrEP",
		Search: "Nhập tên, SĐT hoặc năm sinh",
		Referrer: "SĐT người giới thiệu (nếu có)",
		CreateNewUser: "Tạo tài khoản App",
		CreateUserSuccess:
			"Tạo tài khoản thành công. Vui lòng thông báo khách hàng kiểm tra tin nhắn điện thoại để nhận link tải app và mật khẩu.",
		CustomerVerified: "Có tích xanh",
		Yes: "Có",
		No: "Không",
		Nickname: "Nickname",

		ReceivedSMS: "Cài đặt nhận SMS cho SĐT của bạn",
		ReminderAppt: "Nhắc lịch khám",
		ServiceRatingPrep: "Đánh giá dịch vụ PrEP",
		DontNeed: "Tôi chưa cần",
		NoteSMS: "Nội dung tin nhắn sẽ không chứa tên dịch vụ để đảm bảo tính riêng tư. Áp dụng cho KH chưa cài app Carezone.",
	},

	ScheduleForm: {
		Contact: "Phương thức liên hệ",
		Request: "Yêu cầu",
		Clinic: "Phòng khám",
		Examination_code: "Mã đặt hẹn",
		Date: "Ngày hẹn",
		Note: "Ghi chú",
		Status: "Trạng thái",
		Result: "Đánh giá",
	},

	ClinicForm: {
		ID: "ID",
		Status: "Trạng thái",
		ClinicCode: "Mã phòng khám",
		ClinicName: "Tên phòng khám",
		Type: "Loại",
		Logo: "Logo",
		Address: "Địa chỉ",
		Province: "Thành phố",
		Phone: "Số điện thoại",
		Website: "Website",
		WorkingTime: "Giờ làm việc",
		ShortDesClinic: "Giới thiệu ngắn về phòng khám",
		Email: "Hỗ trợ trực tuyến",
		ConfirmStatus:
			"Thay đổi trạng thái thành dừng hoạt động thì phòng khám này sẽ không xuất hiện trong danh sách lựa chọn phòng khám của tất cả các form.",
		PrivateClinic: "PK tư nhân",
		PublicClinic: "PK công",
		DNXHClinic: "DNXH",
		CBOClinic: "CBO",
		ChooseFile: "Choose File",
		DeleteDescription: "Khi xác nhận Clinic này sẽ bị xóa.",
		PKCode: "Mã PK",
		Name: "Tên",
		Rating: "Điểm đánh giá",
		Actions: "Actions",
		Feedback: "Feedback link",
		LinkRating: "Link đánh giá",
	},

	PrEPStatusForm: {
		STT: "STT",
		ExaminationDate: "Ngày khách hàng đến khám",
		Result: "Kết quả XN HIV",
		Status: "Tình trạng điều trị PrEP",
		Regimen: "Phác đồ PrEP",
		MedicineUsed: "Số thuốc được phát",
		Detail: "Chi tiết",
		ExaminationDateNext: "Ngày hẹn khám tiếp theo",
	},

	RiskForm: {
		ResultDate: "Ngày đánh giá",
		Result: "Kết quả đánh giá",
		Actions: "Xem",
	},

	CustomerGifts: {
		GiftDate: "Ngày đăng ký nhận quà",
		Service: "Dịch vụ",
		AddressOraQuick: "Đ/c nhận que OraQuick",
		AddressPrEP: "Đ/c tư vấn PrEP",
		View: "Xem",
		CustomerName: "Tên",
		PhoneNumber: "Số điện thoại",
		BirthYear: "Năm sinh",
		EvaluationDate: "Ngày đánh giá",
		ViewOptions: "Xem các lựa chọn",
	},

	RiskDetail: {
		RiskResult: "Đánh giá nguy cơ",
		CustomerName: "Tên",
		PhoneNumber: "Số điện thoại",
		BirthYear: "Năm sinh",
		EvaluationDate: "Ngày đánh giá",
		ViewOptions: "Xem các lựa chọn",
	},

	MessageForm: {
		STT: "STT",
		Text: "Tin nhắn",
		Date: "Ngày gửi",
	},

	CustomerHistory: {
		Title: "Lịch sử bảo hành",
	},

	CustomerManagement: {
		Active: "Active",
		Inactive: "Inactive",
		No: "No.",
		Actions: "Xem/Sửa",
	},

	Status: {
		Active: "Đang hoạt động",
		Inactive: "Dừng hoạt động",
		Pending: "Đang chờ",
		Complete: "Hoàn thành",
	},

	ClinicText: {
		GLINK: "Phòng khám Glink - Số 18, ngõ 9 Minh Khai, Hai Bà Trưng",
		BIEN_VIET: "Phòng khám Biển Việt - Số 18NV1, Tân Triều, Thanh Trì",
	},

	ServiceText: {
		OraQuick: "(Chỉ có tại Hà Nội) Đăng ký nhận que tự xét nghiệm HIV - OraQuick miễn phí",
		PrEP: "Đăng ký nhận thuốc PrEP miễn phí (thuốc dự phòng trước phơi nhiễm HIV)",
		NONE: "Không có nhu cầu sử dụng dịch vụ nào",
	},

	ErrorMessage: {
		PhoneUsed: "Số điện thoại đã được sử dụng bởi tài khoản khác.",
		UserAlreadyCreated: "Khách hàng đã có tài khoản trên hệ thống.",
	},

	fields: {
		...{
			customer_code: "Mã khách hàng",
			customer_full_name: "Tên khách hàng",
			customer_type: "Loại khách hàng",
			customer_type_code: "Mã khách hàng PrEP",
			customer_birth_day: "Năm sinh",
			customer_PK: "Phòng khám",
			customer_filter_date: "Ngày sàng lọc",
			customer_start_prep_date: "Ngày bắt đầu dùng PrEP",
			customer_contact: "Phương thức liên hệ",
			customer_address: "Địa chỉ",
			customer_count: "Địa chỉ",
			customer_status: "Trạng thái",
			customer_phone: "Điện thoại",
			customer_sms: "SMS",
			customer_email: "Email",
			customer_cmnd: "CMND",
			// customer_max_process_ticket: "Số yêu cầu tối đa",
		},
	},

	validation: {
		...vi.messages,
		...{
			// custom message rules
			isEmail: "Thông tin này phải là một địa chỉ email hợp lệ",
		},
	},
})
