export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"

export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS"

export const SEND_OTP_FORGOT_SUCCESS = "SEND_OTP_FORGOT_SUCCESS"

export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS"
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR"

export const GET_USER_LOGIN_PROFILE_SUCCESS = "GET_USER_LOGIN_PROFILE_SUCCESS"

export const UPDATE_USER_LOGIN_PROFILE_SUCCESS = "UPDATE_USER_LOGIN_PROFILE_SUCCESS"

export const CHECK_TOKEN_VALID_SUCCESS = "CHECK_TOKEN_VALID_SUCCESS"
export const CHECK_TOKEN_VALID_FAILED = "CHECK_TOKEN_VALID_FAILED"

export const CHANGE_STATUS_LOGOUT_MODAL = "CHANGE_STATUS_LOGOUT_MODAL"
export const CHANGE_STATUS_CHANGE_PASSWORD_MODAL = "CHANGE_STATUS_CHANGE_PASSWORD_MODAL"

export const CHANGE_STATUS_UPDATE_PROFILE = "CHANGE_STATUS_UPDATE_PROFILE"
