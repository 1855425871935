import {
	GET_LIST_TOPIC_COMMENT_SUCCESS,
	RESET_FILTERS_TOPIC_LIST,
	TRASH_LIST_TOPIC_COMMENT_SUCCESS,
} from "./mutation-types"

export default {
	[GET_LIST_TOPIC_COMMENT_SUCCESS]: function (state, payload = {}) {
		state.topics = payload.data
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[RESET_FILTERS_TOPIC_LIST]: function (state) {
		state.topics = null
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			keyword: "",
			offset: 0,
			limit: process.env.VUE_APP_LIMIT_LIST,
		}
	},
	[TRASH_LIST_TOPIC_COMMENT_SUCCESS]: function (state) {
		state.topics = null
		state.totalItems = null
		state.totalPages = null
		state.recentFilters = {
			keyword: "",
			offset: 0,
			limit: process.env.VUE_APP_LIMIT_LIST,
		}
	},
}
