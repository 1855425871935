import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	ProductBooking: {
		BookingForm: "Booking From",
		BookingSuccess: "Booking Successfully",
		Desciption:
			"We are providing free HIV self-testing strips and HIV exposure prophylaxis in limited quantities. Please register here, your information will be completely confidential",
		Finish: "Finish",
		YourFullName: "Your Full Name",
		Phone: "Phone",
		DeliveryAddress: "Delivery address",
		MoreInfo: "More information about HIV testing and PrEP services:",
		Facebook: "Facebook",
		Website: "Website",
		WebsiteLink: "Website.vn",
		WeCare: "Xomcauvong.com",
		XomCauVong: "Xóm Cầu Vồng",
		Congratulations: "Congratulations on your successful booking",
		Contact: "We will contact you as soon as possible",
		BackToHome: "Back to home page",
		Birthyear: "Birthyear",
		Address: "State/Province",
	},

	BookingQuestion3: {
		Question: "Which services do you choose?",
		Answer1: "Get free self-test HIV-OraQuick",
		Answer2: "Get free PrEP medicine (HIV pre-exposure prophylaxis)",
		Answer3: "No need for any services",
	},

	BookingQuestion4: {
		Question: "How do you want to receive OraQuick sticks?",
		Answer1: "By post",
		Answer2: "By grab delivery service",
		Answer3: "At the clinic",
	},

	BookingQuestion6: {
		Question: "Where would you like to be receive OraQuick sticks?",
		Answer1: "(HN) PK Glink - Số 18, ngõ 9 Minh Khai, Hai Bà Trưng",
		Answer2: "(HN) PK Đa khoa Biển Việt - Số 18 NV1, Tân Triều, Thanh Trì",
		Answer3: "(HN) DNXH Venus - Tầng 2 - Số 6 Ngõ 538 Đường Láng, Đống Đa",
		Answer4: "(HCM) PK ALO CARE - 4A đường 14, P. Linh Đông, Q. Thủ Đức",
		Answer5: "(HCM) PK Glink - 224/38 Lý Thường Kiệt, P.14, Q. 10",
		Answer6: "(ĐN) PK ALO CARE Đồng Nai - 160 CMT8, TP. Biên Hoà",
		Answer7: "(ĐN) PK Glink Đồng Nai - C61 Khu Liên Kế - Khu Phố 1, TP. Biên Hoà",
	},

	BookingQuestion7: {
		Question: "Where would you like to be consulted and used PrEP?",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			booking_full_name: "Input",
			booking_phone_number: "Input",
			booking_year_number: "Input",
			booking_service: "Input",
			booking_receive_method: "Input",
			booking_receive_address: "Input",
			booking_clinic: "Input",
			booking_address: "Input",
		},
	},

	validation: {
		...en.messages,
		...{
			// custom message rules
			integer: "The {_field_} field must be an integer",
		},
	},
	Address: {
		HANOI: "Hà Nội",
		DONGNAI: "Đồng Nai",
		HCM: "TP. Hồ Chí Minh",
	},
})
