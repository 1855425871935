import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	currentUser: null,
	accessToken: null,
	refreshToken: null,
	loginForm: {
		username: null,
		password: null,
	},
	showLogoutModal: false,
	showChangePwd: false,
	resetPwdErr: null,
	resetTokenValid: null,
	tokenErr: null,
	OTPErr: null,
	isLoading: true,
	showUpdateProfile: false,
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
