import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	RiskAssessment: {
		AssessmentForm: "Assessment From",
		AssessmentSuccess: "Assessment Successfully",
		EvaluationResult: "Evaluation Result",
		CompleteEvaluation: "Complete Evaluation",
		RegisterEvaluation: "Register For Evaluation",
		Next: "Next",
		Back: "Back",
		GetResults: "Get results",
		Finish: "Finish",
	},

	RegisterEvaluation: {
		Title:
			"All information collected on this form is completely confidential, and is used only to help you self-assess your HIV risk and connect you to appropriate and free services (eg HIV testing).",
		YourPhone: "Your phone",
		YourBirth: "Your birth",
		StartNow: "Start now",
	},

	CompleteEvaluation: {
		Title: "You completed evaluation",
		Question: "Do you want to increase your protection?",
		Appointment: "Make an appointment for testing / counseling",
		GetFree: "Get free HIV / PrEP self-test strips",
		BackHome: "Back to home page",
	},

	AssessmentResult: {
		Question: "How do you know about this evaluation?",
		Answer1: "Facebook Xomcauvong",
		Answer2: "Website Xomcauvong",
		Answer3: "Glink Clinic",
		Answer4: "MTMTG group",
		Answer5: "Venus group",
		Answer6: "Niem Tin Xanh group",
		Answer7: "Other",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			assessment_q1: "Question",
			assessment_q2: "Question",
			assessment_q3: "Question",
			assessment_q4: "Question",
			assessment_q5: "Question",
			assessment_q6: "Question",
			assessment_q7: "Question",
			assessment_q8: "Question",
			assessment_q9: "Question",
			assessment_q10: "Question",
			assessment_q11: "Question",
			assessment_q12: "Question",
			assessment_q13: "Question",
			assessment_q14: "Question",
			assessment_q15: "Question",
			assessment_q16: "Question",
			assessment_q17: "Question",
			assessment_q18: "Question",

			register_phone: "Phone",
		},
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
			integer: "The {_field_} field must be an integer",
		},
	},
})
