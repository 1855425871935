import store from "@/store"

import {
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	SEND_OTP_SUCCESS,
	SEND_OTP_FORGOT_SUCCESS,
	FORGOT_PASSWORD_SUCCESS,
	REFRESH_TOKEN_SUCCESS,
	REFRESH_TOKEN_ERROR,
	GET_USER_LOGIN_PROFILE_SUCCESS,
	UPDATE_USER_LOGIN_PROFILE_SUCCESS,
	CHECK_TOKEN_VALID_SUCCESS,
	CHECK_TOKEN_VALID_FAILED,
	CHANGE_STATUS_LOGOUT_MODAL,
	CHANGE_STATUS_CHANGE_PASSWORD_MODAL,
	CHANGE_STATUS_UPDATE_PROFILE,
} from "./mutation-types"

export default {
	[LOGIN_SUCCESS]: function (state, payload = {}) {
		state.loginForm.username = payload.isSave ? payload.username : null
		state.loginForm.password = payload.isSave ? payload.password : null
	},

	[LOGOUT_SUCCESS]: function (state) {
		state.currentUser = null
		state.accessToken = null
		state.refreshToken = null
	},

	[FORGOT_PASSWORD_SUCCESS]: function (state, payload = {}) {
		state.emailForgot = { ...payload }
	},

	[SEND_OTP_SUCCESS]: function (state, payload = {}) {
		state.accessToken = payload.accessToken
		state.refreshToken = payload.refreshToken
		state.OTPErr = null
	},

	[SEND_OTP_FORGOT_SUCCESS]: function (state, payload = {}) {
		state.emailForgot = { ...payload }
	},

	[REFRESH_TOKEN_SUCCESS]: function (state, payload = {}) {
		state.accessToken = payload.accessToken
		state.refreshToken = payload.refreshToken
	},

	[REFRESH_TOKEN_ERROR]: function (state) {
		state.currentUser = null
		state.accessToken = null
		state.refreshToken = null
	},

	[GET_USER_LOGIN_PROFILE_SUCCESS]: function (state, payload = {}) {
		state.currentUser = payload
	},

	[UPDATE_USER_LOGIN_PROFILE_SUCCESS]: function (state, payload = {}) {
		state.currentUser = payload
	},

	[CHECK_TOKEN_VALID_SUCCESS]: function (state) {
		state.resetTokenValid = true
		state.isLoading = false
	},

	[CHECK_TOKEN_VALID_FAILED]: function (state, payload = {}) {
		state.resetTokenValid = false
		state.isLoading = false
		state.tokenErr = payload
	},

	[CHANGE_STATUS_LOGOUT_MODAL]: function (state, payload = {}) {
		state.showLogoutModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_CHANGE_PASSWORD_MODAL]: function (state, payload = {}) {
		state.showChangePwd = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_UPDATE_PROFILE]: function (state, payload = {}) {
		state.showUpdateProfile = payload
		store.commit("set", ["modalShow", payload])
	},
}
