import i18n from "@/shared/plugins/i18n"
// eslint-disable-next-line no-unused-vars
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	BannerTitle: {
		Name: "Banner",
		BannerManagement: "Quản lý Banner",
	},

	BannerHeader: {
		No: "STT",
		Name: "Tên",
		Type: "Phân loại",
		Image: "Hình ảnh",
		Time: "Thời gian",
		Status: "Trạng thái",
		Edit: "Xem/Sửa",
	},

	BannerStatus: {
		ACTIVE: "Đang hoạt động",
		INACTIVE: "Không hoạt động",
	},

	BannerForm: {
		CreateBanner: "Tạo mới Banner",
		Name: "Tên",
		Link: "Link đích",
		Time: "Thời gian",
		To: "đến",
		Type: "Phân loại",
		Status: "Trạng thái",
		Save: "Lưu",
		Cancel: "Thoát",
		Delete: "Xoá",
		Tutorial: "Pop-up khi vừa cài app",
		Campaign: "Popup chiến dịch",
		Banner: "Banner trang chủ",
		PlaceHolderName: "Vui lòng nhập tên",
		PlaceHolderLink: "Vui lòng nhập link",
		ACTIVE: "Hoạt động",
		INACTIVE: "Ngưng hoạt động",
		RemoveImage: "Bạn có muốn xoá hình ảnh?",
		DeleteDescription: "Khi xác nhận, nội dung này sẽ bị xoá",
		ErrorUpload: "Vui lòng sử dụng ảnh dưới 1Mb",
		FilterTypeHolder: "Lọc theo loại Banner",
	},

	FormError: {
		images: "Vui lòng chọn ảnh",
	},
	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			banner_status: "Thông tin này",
			banner_name: "Thông tin này",
			banner_type: "Thông tin này",
			banner_link: "Thông tin này",
			banner_time: "Thông tin này",
			banner_image: "Thông tin này",

			banner_result_0: "Thông tin này",
			banner_result_1: "Thông tin này",
			banner_result_2: "Thông tin này",
			banner_result_3: "Thông tin này",
			banner_result_4: "Thông tin này",
			banner_result_5: "Thông tin này",
			banner_result_6: "Thông tin này",
			banner_result_7: "Thông tin này",
			banner_result_8: "Thông tin này",
			banner_result_9: "Thông tin này",
			banner_result_10: "Thông tin này",
			banner_result_11: "Thông tin này",
			banner_result_12: "Thông tin này",
			banner_result_13: "Thông tin này",
			banner_result_14: "Thông tin này",
			banner_result_15: "Thông tin này",
			banner_result_16: "Thông tin này",
			banner_result_17: "Thông tin này",
			banner_result_18: "Thông tin này",

			// Search form
			banner_code: "Thông tin này",
		},
	},
})
