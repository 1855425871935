import i18n from "@/shared/plugins/i18n"
// eslint-disable-next-line no-unused-vars
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	NotificationTitle: {
		Name: "Thông Báo",
		NotificationManagement: "Quản lý Thông báo",
	},

	NotificationHeader: {
		No: "STT",
		Title: "Tên",
		Body: "Mỏ tả",
		Receiver: "Người nhận",
		Content: "Mô tả chi tiết",
		Action: "Tên hành động",
		CreatedDate: "Ngày tạo",
	},
	NotificationStatus: {
		ACTIVE: "Đang hoạt động",
		INACTIVE: "Không hoạt động",
	},

	NotificationForm: {
		CreateNotification: "Tạo mới thông báo",
		Title: "Tên",
		Body: "Mô tả ngắn gọn",
		ContentDetail: "Mô tả chi tiết",
		Group: "Người nhận",
		Link: "Link đích",
		Action: "Tên hành động",
		ALL: "Đã đăng ký app",
		PREP: "KH PREP",
		ALL_DEVICE: "Đã cài app",
		PERSONS: "Cá nhân",
		PlaceHolderTitle: "Vui lòng nhập tiêu đề",
		PlaceHolderBody: "Vui lòng nhập mô tả",
		PlaceHolderContentDetail: "Vui lòng nhập mô tả chi tiết",
		PlaceHolderLink: "Vui lòng nhập liên kết",
		PlaceHolderAction: "Vui lòng nhập tên hành động",
		Error: "Vui lòng nhập nhóm khách hoàng hoặc khách hàng cần gửi thông báo",
		ModalContent: "Bạn chắc chắn có muốn gửi thông báo này?",
	},

	FormError: {
		images: "Vui lòng chọn ảnh",
	},
	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			notification_name: "Thông tin này",
			notification_body: "Thông tin này",
			notification_action: "Thông tin này",
			banner_link: "Thông tin này",
			banner_time: "Thông tin này",
			banner_image: "Thông tin này",

			banner_result_0: "Thông tin này",
			banner_result_1: "Thông tin này",
			banner_result_2: "Thông tin này",
			banner_result_3: "Thông tin này",
			banner_result_4: "Thông tin này",
			banner_result_5: "Thông tin này",
			banner_result_6: "Thông tin này",
			banner_result_7: "Thông tin này",
			banner_result_8: "Thông tin này",
			banner_result_9: "Thông tin này",
			banner_result_10: "Thông tin này",
			banner_result_11: "Thông tin này",
			banner_result_12: "Thông tin này",
			banner_result_13: "Thông tin này",
			banner_result_14: "Thông tin này",
			banner_result_15: "Thông tin này",
			banner_result_16: "Thông tin này",
			banner_result_17: "Thông tin này",
			banner_result_18: "Thông tin này",

			// Search form
			banner_code: "Thông tin này",
		},
	},
	validation: {
		...vi.messages,
		...{
			// custom message rules
			isUrl: "Địa chỉ không hợp lệ",
		},
	},
})
