<template>
	<div class="custom-table">
		<CCard>
			<CCardBody>
				<slot name="filters">
					<div class="filters">
						<!-- Search -->
						<div>
							<input
								id="search"
								v-model.trim="keyword"
								class="form-control custom-input"
								type="text"
								:placeholder="$t('CustomerForm.Search')"
								:maxlength="maxLength"
								@keydown="searchDebounce"
							/>
						</div>

						<!-- Status -->
						<div>
							<multiselect
								id="status"
								v-model="status"
								:options="listStatus"
								:custom-label="labelStatus"
								:multiple="true"
								:close-on-select="true"
								:clear-on-select="false"
								:show-labels="false"
								:searchable="false"
								:placeholder="$t('DropDown.FilterStatus')"
								:limit-text="limitTextObject"
								:limit="limitVisibleDD"
								@input="resetPaging"
							>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>

						<!-- Customer -->
						<div>
							<multiselect
								id="types"
								v-model="types"
								:options="listCustomersType"
								:custom-label="labelCustomersType"
								:multiple="true"
								:close-on-select="true"
								:clear-on-select="false"
								:show-labels="false"
								:searchable="false"
								:placeholder="$t('DropDown.FilterCustomer')"
								:limit-text="limitTextObject"
								:limit="limitVisibleDD"
								@input="resetPaging"
							>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>

						<!-- Risk -->
						<div>
							<multiselect
								id="riskEvaluation"
								v-model="riskEvaluation"
								:options="listRisks"
								:custom-label="labelRisk"
								:multiple="true"
								:close-on-select="true"
								:clear-on-select="false"
								:show-labels="false"
								:searchable="false"
								:placeholder="$t('DropDown.FilterHIV')"
								:limit-text="limitTextObject"
								:limit="limitVisibleDD"
								@input="resetPaging"
							>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>

						<!-- Date -->
						<div>
							<date-picker
								v-model="dateRangePrEP"
								type="date"
								value-type="format"
								range
								class="w-100"
								:confirm="true"
								:confirm-text="$t('Button.Find')"
								:editable="false"
								:placeholder="$t('CustomerForm.FilterPrEPDate')"
								@change="resetPaging"
							></date-picker>
						</div>

						<!-- Register status -->
						<div>
							<multiselect
								id="registerStatus"
								v-model="registerStatus"
								:options="listRegisterStatus"
								:custom-label="labelRegisterStatus"
								:multiple="true"
								:close-on-select="true"
								:clear-on-select="false"
								:show-labels="false"
								:searchable="false"
								:placeholder="$t('DropDown.RegisterStatus')"
								:limit-text="limitTextObject"
								:limit="limitVisibleDD"
								@input="resetPaging"
							>
								<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
								<template #noResult>{{ $t("DropDown.NoResult") }}</template>
							</multiselect>
						</div>
					</div>
				</slot>

				<CRow class="form-group d-md-flex">
					<slot v-if="showActions" name="actions">
						<CCol class="text-right">
							<template v-if="showCreateButton">
								<CButton color="info" @click="create">{{ $t("DropDown.Create") }}</CButton>
							</template>
							<template v-if="showExportButton">
								<button type="button" class="btn btn-outline-dark ml-2" @click="exportExcel">
									{{ $t("DropDown.ExportExcel") }}
								</button>
							</template>
							<template v-if="showImportButton">
								<button type="button" class="btn btn-outline-dark ml-2">
									{{ $t("DropDown.Import") }}
								</button>
							</template>
						</CCol>
					</slot>
				</CRow>

				<slot name="table">
					<div class="table-responsive-md">
						<table class="table table-bordered table-hover table-responsive table-fit">
							<thead>
								<slot name="headers" />
							</thead>
							<tbody>
								<slot name="body" />
							</tbody>
						</table>
					</div>
				</slot>

				<slot name="footer">
					<CRow>
						<!-- Select limit  -->
						<CCol class="custom-table__footer">
							<CRow class="m-0 mb-3 options">
								<div class="align-self-center">{{ rangeItemsOfTotal }}</div>

								<!-- pagination -->
								<CPagination
									v-if="totalPages > 1"
									:active-page.sync="recentPage"
									:pages="totalPages"
									align="end"
								/>
							</CRow>
						</CCol>
					</CRow>
				</slot>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { debounce, isArray } from "lodash-es"
import { STATUS, CUSTOMER_TYPE, RISK_EVALUATION, INPUT_LENGTH } from "@/shared/plugins/constants"
import timezone from "moment-timezone"

export default {
	name: "CustomTableCustomer",

	props: {
		totalPages: {
			type: [Number, String],
			default: 0,
		},
		showCreateButton: {
			type: Boolean,
			default: true,
		},
		showImportButton: {
			type: Boolean,
			default: false,
		},
		showExportButton: {
			type: Boolean,
			default: false,
		},
		showActions: {
			type: Boolean,
			default: true,
		},
		totalItems: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			limitVisibleDD: INPUT_LENGTH.VISIBLE_DROPDOWN,
			formatDate: process.env.VUE_APP_DATE_FORMAT,
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			recentPage: 1,

			limit: process.env.VUE_APP_LIMIT_LIST,
			offset: 0,

			keyword: "",
			status: [],
			types: [],
			riskEvaluation: [],
			dateRangePrEP: [],
			registerStatus: [],
			listRegisterStatus: [STATUS.ACTIVE],

			listStatus: [STATUS.ACTIVE, STATUS.INACTIVE],

			listCustomersType: [CUSTOMER_TYPE.PREP, CUSTOMER_TYPE.NORMAL, CUSTOMER_TYPE.UNOFFICIAL],

			listRisks: [
				RISK_EVALUATION.VERY_HIGH,
				RISK_EVALUATION.HIGH,
				RISK_EVALUATION.MEDIUM,
				RISK_EVALUATION.LOW,
				RISK_EVALUATION.NOT_EVALUATE,
			],
		}
	},

	computed: {
		rangeItemsOfTotal: function () {
			if (!this.totalPages) return "0 - 0 of 0"

			const from = Number(this.offset) + 1
			const to =
				from / this.limit < this.totalPages - 1
					? Number(this.offset) + Number(this.limit)
					: this.totalItems

			return `${from} - ${to} of ${this.totalItems}`
		},
	},

	watch: {
		recentPage(val) {
			if (val !== Math.floor(this.offset / this.limit) + 1) {
				this.offset = (val - 1) * this.limit
				this.search()
			}
		},
	},

	created() {
		this.setData()
	},

	methods: {
		setData() {
			const queryParams = this.$route.query
			this.keyword = queryParams.keyword || this.recentFilter?.keyword || this.keyword

			this.status = this.getDataParams(queryParams.status, this.recentFilter?.status, this.status)
			this.types = this.getDataParams(queryParams.types, this.recentFilter?.types, this.types)
			this.registerStatus = this.getDataParams(
				queryParams.registerStatus,
				this.recentFilter?.registerStatus,
				this.registerStatus,
			)
			this.riskEvaluation = this.getDataParams(
				queryParams.riskEvaluation,
				this.recentFilter?.riskEvaluation,
				this.riskEvaluation,
			)

			this.dateRangePrEP = [
				queryParams.prepStartDate || this.recentFilter?.prepStartDate || this.dateRangePrEP[0],
				queryParams.prepEndDate || this.recentFilter?.prepEndDate || this.dateRangePrEP[1],
			]

			this.limit = queryParams.limit || this.recentFilter?.limit || this.limit
			this.offset = queryParams.offset || this.recentFilter?.offset || this.offset
			this.recentPage = Math.floor(this.offset / this.limit) + 1

			if (Object.keys(queryParams).length) {
				this.$emit("search", queryParams)
			} else this.search()
		},

		create() {
			this.$emit("create")
		},

		searchDebounce: debounce(function () {
			this.resetPaging()
		}, process.env.VUE_APP_DEBOUNCE_TIME),

		search() {
			const params = {
				limit: this.limit,
				offset: this.offset,
			}

			if (this.keyword) {
				params["keyword"] = this.keyword
			}
			if (this.status.length > 0) {
				params["status"] = this.status.join(",")
			}
			if (this.registerStatus != null) {
				params["registerStatus"] = this.registerStatus.join(",")
			}
			if (this.types.length > 0) {
				params["types"] = this.types.join(",")
			}
			if (this.riskEvaluation.length > 0) {
				params["riskEvaluation"] = this.riskEvaluation.join(",")
			}
			if (this.dateRangePrEP[0] && this.dateRangePrEP[1]) {
				params["prepStartDate"] = this.dateRangePrEP[0]
				params["prepEndDate"] = this.dateRangePrEP[1]
			}

			this.$router.push({ query: params }).catch(error => {
				if (error.name !== "NavigationDuplicated") {
					throw error
				}
			})

			this.$emit("search", { ...params, timezone: timezone.tz.guess() })
		},

		resetPaging() {
			this.offset = 0
			this.recentPage = 1
			this.search()
		},

		exportExcel() {
			this.$emit("export")
		},

		limitTextObject(count) {
			return this.$t("Multiselect.OtherObjects", { count: count })
		},

		labelStatus(value) {
			return {
				[STATUS.ACTIVE]: this.$t("Status.Active"),
				[STATUS.INACTIVE]: this.$t("Status.Inactive"),
			}[value]
		},
		labelRegisterStatus(value) {
			return {
				[STATUS.ACTIVE]: this.$t("RegisterStatus.Active"),
			}[value]
		},
		labelCustomersType(value) {
			return {
				[CUSTOMER_TYPE.PREP]: this.$t("CustomersType.PrEP"),
				[CUSTOMER_TYPE.NORMAL]: this.$t("CustomersType.Normal"),
				[CUSTOMER_TYPE.UNOFFICIAL]: this.$t("CustomersType.Unofficial"),
			}[value]
		},

		labelRisk(value) {
			return {
				[RISK_EVALUATION.VERY_HIGH]: this.$t("RiskEvaluation.VeryHigh"),
				[RISK_EVALUATION.HIGH]: this.$t("RiskEvaluation.High"),
				[RISK_EVALUATION.MEDIUM]: this.$t("RiskEvaluation.Medium"),
				[RISK_EVALUATION.LOW]: this.$t("RiskEvaluation.Low"),
				[RISK_EVALUATION.NOT_EVALUATE]: this.$t("RiskEvaluation.NotEvaluation"),
			}[value]
		},

		getDataParams(arg1, arg2, arg3) {
			return this.convertToArray(arg1) || this.convertToArray(arg2) || this.convertToArray(arg3)
		},

		convertToArray(value) {
			if ([null, undefined, ""].indexOf(value) > -1) return null

			if (isArray(value)) return value
			else return value.split(",")
		},
	},
}
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";

.filters {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
	gap: 20px;
	margin-bottom: 2rem;
}

.custom-table {
	.table td.fit,
	.table th.fit {
		white-space: nowrap;
		width: 1%;
	}

	.table th {
		background-color: $table-header-color;
		color: $base-color;
		font-weight: 600;
	}

	&__footer {
		display: grid;
		grid-template-columns: 1fr auto auto;

		.options {
			justify-self: end;
		}

		.pagination {
			margin-left: 15px;
			margin-bottom: 0;
		}

		&__total {
			grid-column: -1/1;

			@media (min-width: $xxs) {
				grid-column: initial;
			}
		}
	}

	.actions {
		span {
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.col-w-3 {
	width: 25%;
	flex: 0 0 25%;
}
</style>
