import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	step: 1,
	riskQuestions: [],
	totalGroupQuestions: null,
	dataAssessment: {},
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
