import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

const initialState = {
	clinics: [],
	schedule: [],
	prep: [],
	totalItems: null,
	totalPages: null,
	recentFilters: {
		clinic: null,
		customerType: null,
		riskEvaluation: null,
		status: null,
		keyword: null,
		offset: 0,
		limit: process.env.VUE_APP_LIMIT_LIST,
	},
}

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
}
