import {
	faEye,
	faEyeSlash,
	faUser,
	faKey,
	faPencilAlt,
	faFileAlt,
	faTimes,
	faTrashAlt,
	faBell,
	faCheckCircle,
	faTimesCircle,
	faFileInvoice,
	faUserClock,
	faCheck,
	faSortUp,
	faSortDown,
	faArrowRight,
	faMinus,
} from "@fortawesome/free-solid-svg-icons"

export default {
	faEye,
	faEyeSlash,
	faUser,
	faKey,
	faPencilAlt,
	faFileAlt,
	faTimes,
	faTrashAlt,
	faBell,
	faCheckCircle,
	faTimesCircle,
	faFileInvoice,
	faUserClock,
	faCheck,
	faSortUp,
	faSortDown,
	faArrowRight,
	faMinus,
}
