import API from "../services"

import {
	GET_CLINIC_LIST,
	GET_REPORT_SCHEDULE_LIST,
	GET_REPORT_PREP_LIST,
	GET_REPORT_PREP_EXPORT,
	GET_REPORT_SCHEDULE_EXPORT,
	GET_REPORT_CUSTOM_FORM_EXPORT,
	GET_REPORT_PLATFORM_INDEX,
} from "./action-types"

import {
	GET_CLINIC_LIST_SUCCESS,
	GET_REPORT_SCHEDULE_LIST_SUCCESS,
	GET_REPORT_CUSTOM_FORM_SUCCESS,
	GET_REPORT_PLATFORM_INDEX_SUCCESS,
} from "./mutation-types"

export default {
	[GET_REPORT_SCHEDULE_LIST]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getReportSchedule(params)
			commit(GET_REPORT_SCHEDULE_LIST_SUCCESS, { ...res, filters: params })
			return res
		} catch (error) {}
	},

	// eslint-disable-next-line
	[GET_REPORT_PREP_LIST]: async ({}, params = {}) => {
		try {
			const res = await API.getReportPrep(params)
			return res
		} catch (error) {}
	},

	// eslint-disable-next-line
	[GET_REPORT_PREP_EXPORT]: async ({}, params = {}) => {
		try {
			const res = await API.getReportPrepExcel(params)
			return res
		} catch (error) {}
	},

	// eslint-disable-next-line
	[GET_REPORT_SCHEDULE_EXPORT]: async ({}, params = {}) => {
		try {
			const res = await API.getReportScheduleExcel(params)
			return res
		} catch (error) {}
	},

	[GET_CLINIC_LIST]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getClinic(params)
			commit(GET_CLINIC_LIST_SUCCESS, res)
		} catch (error) {}
	},
	[GET_REPORT_CUSTOM_FORM_EXPORT]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getReportCustomFormExcel(params)
			commit(GET_REPORT_CUSTOM_FORM_SUCCESS, res)
		} catch (error) {}
	},
	[GET_REPORT_PLATFORM_INDEX]: async ({ commit }, params = {}) => {
		try {
			const res = await API.getReportPlatformIndex(params)
			commit(GET_REPORT_PLATFORM_INDEX_SUCCESS, res)
		} catch (error) {}
	},
}
