import Vue from "vue"
import VueToasted from "vue-toasted"

Vue.use(VueToasted, {
	duration: 5000,
	keepOnHover: true,
	className: "custom-toastr",
	containerClass: "custom-toastr-container",
	iconPack: "fontawesome",
})

const CustomToastr = {
	error: (message, actions = {}) => {
		if (!Vue.toasted.toasts?.length) {
			Vue.toasted.show(message, {
				action: [{ ...actions }],
				type: "error",
				singleton: true,
			})
		}
	},
	success: (message, actions = {}) => {
		if (!Vue.toasted.toasts?.length) {
			Vue.toasted.show(message, {
				action: [{ ...actions }],
				type: "success",
			})
		}
	},
	info: (message, actions = {}) => {
		if (!Vue.toasted.toasts?.length) {
			Vue.toasted.show(message, {
				action: [{ ...actions }],
				type: "info",
			})
		}
	},
}

export default CustomToastr
