import store from "@/store"

import {
	GET_BANNER_BY_ID_SUCCESS,
	GET_LIST_BANNER_SUCCESS,
	RESET_FILTERS_BANNER_LIST,
} from "./mutation-types"
import {
	CHANGE_STATUS_BANNER_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	CHANGE_STATUS_DISCARD_MODAL,
	DELETE_BANNER_SUCCESS,
} from "../store/mutation-types"

export default {
	[GET_LIST_BANNER_SUCCESS]: function (state, payload = {}) {
		state.banners = payload.data
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[RESET_FILTERS_BANNER_LIST]: function (state) {
		state.topics = null
		state.totalItems = null
		state.totalPages = null
		state.recentBanner = null
		state.recentFilters = {
			keyword: "",
			offset: 0,
			limit: process.env.VUE_APP_LIMIT_LIST,
		}
	},

	[DELETE_BANNER_SUCCESS]: function (state) {
		state.recentBanner = null
	},

	[GET_BANNER_BY_ID_SUCCESS]: function (state, payload = {}) {
		state.recentBanner = payload
	},

	[CHANGE_STATUS_BANNER_MODAL]: function (state, payload = {}) {
		state.isShowStatusModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DISCARD_MODAL]: function (state, payload = {}) {
		state.isShowDiscardModal = payload
		store.commit("set", ["modalShow", payload])
	},

	[CHANGE_STATUS_DELETE_MODAL]: function (state, payload = {}) {
		state.isShowDeleteModal = payload
		store.commit("set", ["modalShow", payload])
	},
}
