import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	AppointmentTitle: {
		CustomerDetail: "Chi tiết thông tin",
		CreateCustomer: "Tạo mới khách hàng",
		UpdateCustomer: "Chỉnh sửa thông tin",
		AppointmentManagement: "Quản lý lịch khám",
		AppointmentList: "Danh sách lịch khám",
		CustomerInformation: "Thông tin lịch khám",
		Examination: "Lịch khám",
	},

	Appointment: {
		Register: "Đăng ký lịch hẹn",
		FindSchedule: "Tìm lịch hẹn đã đặt",
		Description:
			"Đặt hẹn với các phòng khám thân thiện với cộng động để được tư vấn, xét nghiệm và điều trị kịp thời với phương châm <span class='quote'>An tâm - Tin cậy - Nhanh nhạy - Sẻ chia</span>. Thông tin của bạn sẽ hoàn toàn được bảo mật, mã hoá trong hệ thống và chỉ được chia sẻ phòng khám bạn đăng ký.",
		AppointmentForm: "Đặt hẹn",
		CreateAppointment: "Tạo mới lịch hẹn",
		UpdateAppointment: "Cập nhật lịch hẹn",
		AppointmentSuccess: "Đặt hẹn thành công",
		SearchForm: "Tìm lịch hẹn đã đặt",
		AppointmentDetail: "Chi tiết lịch hẹn",
		CreateNewAppt: "Tạo mới lịch hẹn",
		AppointmentId: "Lịch hẹn ID {id}",
		FilterByDate: "Lọc theo ngày hẹn",
		Search: "Nhập mã đặt hẹn, tên, SĐT hoặc năm sinh",
	},

	AppointmentForm: {
		Status: "Trạng thái",
		CustomerType: "Phân loại khách hàng",
		Name: "Họ và tên",
		Phone: "Số điện thoại",
		YearOfBirth: "Năm sinh",
		Contact: "Phương thức liên hệ",
		Services: "Dịch vụ",
		Clinics: "Đơn vị cung cấp dịch vụ",
		DateTime: "Ngày giờ hẹn",
		HIVResult: "Kết quả XN HIV  tại cộng đồng (Nếu có)",
		Note: "Ghi chú",
		ConfirmPhone:
			"Số điện thoại này đã được sử dụng cho tài khoản khác. Bạn muốn đặt hẹn cho tài khoản này?",
		HaNoi: "Hà Nội",
		HoChiMinh: "Hồ Chí Minh",
		NewServiceAppt: "Đặt lịch mới",
		Finish: "Hoàn thành",
		WorkingHours: "Thời gian làm việc",
		Website: "Website",
		ResultDate: "Ngày có kết quả",
		NextSchedule: "Ngày hẹn khám tiếp theo",
		HIVScreening: "KQ XN sàng lọc HIV",
		HIVPositive: "KQ XN khẳng định HIV",
		IntroducePrEP: "Được giới thiệu PrEP từ dịch vụ khác",
		PrEPTreatmentStatus: "Tình trạng điều trị PrEP",
		PrEPRegimen: "Phác đồ PrEP",
		MedicationDispensed: "Số thuốc được phát",
		SendingSource: "Nguồn chuyển gửi",
		HivART: "Kết quả tư vấn và điều trị HIV (ART)",
		STIResult: "Kết quả Khám, xét nghiệm và điều trị STI",
		HepatitisBResult: "Kết quả Khám, xét nghiệm và điều trị viêm gan B",
		HepatitisCResult: "Kết quả Khám, xét nghiệm và điều trị viêm gan C",
		MindCounselingResult: "Kết quả Tư vấn sức khỏe tâm trí",
		SubstanceResult: "Kết quả Tư vấn lạm dụng nghiện chất",
		HormonResult: "Kết quả Tư vấn và sử dụng hormone",
		FindCustomers: "Tìm kiếm KH",
		ShortIntro: "Giới thiệu",
		AreYouSure: "Bạn chắc chứ?",
		DeleteDescription: "Sau khi xác nhận, lịch khám này sẽ bị xóa.",

		EnterPhone: "Vui lòng nhập SĐT chính xác để nhận mã đặt hẹn",

		ReceivedSMS: "Cài đặt nhận SMS cho SĐT của bạn",
		ReminderAppt: "Nhắc lịch khám",
		ServiceRatingPrep: "Đánh giá dịch vụ PrEP",
		DontNeed: "Tôi chưa cần",
		NoteSMS: "Nội dung tin nhắn sẽ không chứa tên dịch vụ để đảm bảo tính riêng tư. Áp dụng cho KH chưa cài app Carezone.",
	},

	AppointmentSearch: {
		ApptCode: "Mã đặt hẹn",
		ScheduledAppt: "Tìm lịch hẹn đã đặt",
		CancelSchedule: "Huỷ lịch",
		Find: "Tìm kiếm",
		SearchBy: "Tìm kiếm bằng tên hoặc số điện thoại",
		SearchFalse: "Không tìm thấy kết quả nào phù hợp với yêu cầu của bạn",
	},

	AppointmentDetail: {
		SearchResult: "Kết quả tìm kiếm",
		AppointmentInfo: "Thông tin lịch hẹn của bạn",
		Pending: "Chưa hoàn thành",
		Cancel: "Đã hủy",
		Complete: "Đã hoàn thành",
		CancelMessage: "Bạn có muốn hủy cuộc hẹn này",
		ApptInfo: "Thông tin lịch hẹn",
	},

	AppointmentSuccess: {
		Appointment: "Đặt lịch thành công",
		RegisterInfo:
			"Thông tin đăng ký dịch vụ của bạn đã được chuyển đến phòng khám. Nhân viên phòng khám sẽ liên hệ với bạn trong vòng 24h làm việc.",
		PleaseSave:
			"Vui lòng lưu lại mã đặt hẹn của bạn để tiện tra cứu. Nếu bạn cần hỗ trợ, vui lòng liên hệ qua số điện thoại phòng khám như thông tin lịch  hẹn bên dưới.",
	},

	ApptStatus: {
		Waiting: "Đang chờ",
		Finished: "Hoàn thành",
		Canceled: "Đã hủy",
	},

	ApptCustomerType: {
		PrEP: "KH PrEP",
		Normal: "KH thường",
		Unofficial: "Chưa chính thức",
	},

	ApptHIVResult: {
		Negative: "Không phản ứng",
		Positive: "Có phản ứng",
		NotAvailable: "Không xác định",
	},

	ServicesQuestion: {
		Answer1: "Tư vấn HIV",
		Answer2: "Xét nghiệm HIV",
		Answer3: "Tư vấn PrEP",
		Answer4: "Điều trị PrEP",
		Answer5: "Tư vấn STI",
		Answer6: "Điều trị STI",
		Answer7: "Tư vấn ARV",
		Answer8: "Điều trị ARV",
	},

	ImportFile: {
		Improting: "Đang nhập dữ liệu",
		Finish: "Hoàn thành import",
		NumberSuccess: "Import thành công {rate} bản ghi",
		NumberErrors: "Số lỗi sau khi import {number}",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			appointment_status: "Thông tin này",
			appointment_result_date: "Thông tin này",
			appointment_customer_type: "Thông tin này",
			appointment_name: "Thông tin này",
			appointment_phone: "Thông tin này",
			appointment_birth_year: "Thông tin này",
			appointment_contact: "Thông tin này",
			appointment_services: "Thông tin này",
			appointment_clinics: "Thông tin này",
			appointment_date: "Thông tin này",
			appointment_hiv_result: "Thông tin này",
			appointment_note: "Thông tin này",
			appointment_sms: "Thông tin này",
			appointment_hiv_screening: "Thông tin này",
			appointment_hiv_confirm: "Thông tin này",
			appointment_prep_service: "Thông tin này",
			appointment_prep_status: "Thông tin này",
			appointment_prep_regimen: "Thông tin này",
			appointment_prep_medication: "Thông tin này",
			appointment_prep_source: "Thông tin này",
			appointment_result_0: "Thông tin này",
			appointment_result_1: "Thông tin này",
			appointment_result_2: "Thông tin này",
			appointment_result_3: "Thông tin này",
			appointment_result_4: "Thông tin này",
			appointment_result_5: "Thông tin này",
			appointment_result_6: "Thông tin này",
			appointment_result_7: "Thông tin này",
			appointment_result_8: "Thông tin này",
			appointment_result_9: "Thông tin này",
			appointment_result_10: "Thông tin này",
			appointment_result_11: "Thông tin này",
			appointment_result_12: "Thông tin này",
			appointment_result_13: "Thông tin này",
			appointment_result_14: "Thông tin này",
			appointment_result_15: "Thông tin này",
			appointment_result_16: "Thông tin này",
			appointment_result_17: "Thông tin này",
			appointment_result_18: "Thông tin này",

			// Search form
			appointment_code: "Thông tin này",
		},
	},

	validation: {
		...vi.messages,
		...{
			// custom message rules
			integer: "{_field_} phải là một số nguyên",
			isEmail: "Thông tin này phải là một địa chỉ email hợp lệ",
		},
	},

	AppointmentFormTable: {
		ID: "Mã khách hàng",
		Name: "Khách hàng",
		Request: "Yêu cầu",
		Clinic: "Phòng khám",
		Id_date: "Mã đặt hẹn",
		Date: "Ngày hẹn",
		Note: "Ghi chú",
		Status: "Trạng thái",
		Result: "Kết quả",
		Type: "Loại khách hàng",
		HourDate: "Ngày giờ hẹn",
		ResultDate: "Ngày có kết quả",

		Birthday: "Năm sinh",
		Filter_date: "Ngày sàng lọc",
		Prep_date: "Ngày bắt đầu dùng PrEP",
		Address: "Địa chỉ",
		Count: "Số lần khám",

		Gender: "Giới tính",
		Phone: "Số điện thoại",
		Email: "Email",
		Male: "Nam",
		Female: "Nữ",
		DateFormat: "dd/mm/yyyy",
		Update: "Chỉnh sửa",
		Title: "Thông tin khách hàng",
		MaxTickets: "Số yêu cầu tối đa",
		Appoint: "Đặt hẹn",

		Username: "Họ tên",
		Contact: "Liên hệ",
		SDT: "SĐT",
		IsAutoCreate: "Lịch tự động",
	},

	AppointmentStatus: {
		PENDING: "Chưa hoàn thành",
		CUSTOMER_CANCEL: "Đã hủy",
		COMPLETE: "Đã hoàn thành",
	},

	CreateAppointmentType: {
		MANUAL: "Thủ công",
		AUTO: "Tự động",
	},
})
