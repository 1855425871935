// Start of Admin Side
export const GET_APPOINTMENT_LIST = "GET_APPOINTMENT_LIST"
export const GET_APPT_BY_ID = "GET_APPT_BY_ID"
export const ADMIN_CREATE_APPT = "ADMIN_CREATE_APPT"
export const UPDATE_APPT_BY_ID = "UPDATE_APPT_BY_ID"
export const DELETE_APPT_BY_ID = "DELETE_APPT_BY_ID"
export const GET_CUSTOMERS = "GET_CUSTOMERS"

export const IMPORT_FILE = "IMPORT_FILE"
export const GET_IMPORT_STATUS = "GET_IMPORT_STATUS"
export const INTERVAL_IMPORT = "INTERVAL_IMPORT"
export const FINISH_IMPORT = "FINISH_IMPORT"
export const MERGE_CONFLICT_IMPORT = "MERGE_CONFLICT_IMPORT"

export const EXPORT_FILE = "EXPORT_FILE"
// End of Admin Side

// Start of Customer Side
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT"
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT"
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT"
export const SEARCH_APPT = "SEARCH_APPT"

export const GET_CLINICS = "GET_CLINICS"
// End of Customer Side

// Both
export const GET_SERVICES = "GET_SERVICES"
export const GET_MAIN_CITIES = "GET_MAIN_CITIES"
