import i18n from "@/shared/plugins/i18n"
import en from "vee-validate/dist/locale/en.json"

i18n.mergeLocaleMessage("en", {
	ClinicTitle: {
		Clinic: "Clinic",
		ClinicManagement: "Clinic Management",
		ClinicList: "Clinic List",
		CreateClinic: "Create new Clinic",
		ClinicID: "Clinic ID {id}",
		UpdateClinic: "Update Clinic",
		ClinicName: "Clinic {name}",
	},

	ClinicForm: {
		ID: "ID",
		Status: "Status",
		ClinicCode: "Clinic Code",
		ClinicName: "Clinic Name",
		Type: "Type",
		Logo: "Logo",
		Address: "Address",
		Province: "Province",
		Phone: "Phone",
		Website: "Website",
		WorkingTime: "Working Time",
		ShortDesClinic: "Short description about Clinic",
		Email: "Email",
		ConfirmStatus:
			"Change status to inactive means block this clinic become invisible in clinics selection list of all forms.",
		PrivateClinic: "Private Clinic",
		PublicClinic: "Public Clinic",
		DNXHClinic: "DNXH",
		CBOClinic: "CBO",
		ChooseFile: "Choose File",
		DeleteDescription: "Once confirm this clinic will be deleted.",
		PKCode: "PK Code",
		Name: "Name",
		Rating: "Rating",
		Actions: "Actions",
		Feedback: "Feedback link",
		LinkRating: "Link Rating",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			clinic_status: "Input",
			clinic_code: "Input",
			clinic_name: "Input",
			clinic_type: "Input",
			clinic_logo: "Input",
			clinic_address: "Input",
			clinic_city: "Input",
			clinic_phone: "Input",
			clinic_website: "Input",
			clinic_working_hour: "Input",
			clinic_short_intro: "Input",
			clinic_email: "Input",
			clinic_feedback: "Input",
		},
	},
	validation: {
		...en.messages,
		...{
			// custom message rules
			integer: "The {_field_} field must be an integer",
			isEmail: "The input field must be a valid email",
		},
	},
})
