// Xomcauvong
export const REGISTER_EVALUATION = "REGISTER_EVALUATION"
export const RISK_ASSESSMENT = "RISK_ASSESSMENT"
export const FINISH_ASSESSMENT = "FINISH_ASSESSMENT"

export const GET_RISK_QUESTIONS = "GET_RISK_QUESTIONS"

// Mobile
export const RISK_ASSESSMENT_MOBILE = "RISK_ASSESSMENT_MOBILE"
export const FINISH_ASSESSMENT_MOBILE = "FINISH_ASSESSMENT_MOBILE"
