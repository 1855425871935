import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins"

const MessageList = () => import("./pages/MessageList.vue")
const CreateMessage = () => import("./pages/CreateMessage.vue")
const UpdateMessage = () => import("./pages/UpdateMessage.vue")

const MessageModule = () => import("./MessageModule.vue")

const messageRoutes = [
	{
		path: "/messages",
		name: "MessageModule",
		component: MessageModule,
		meta: {
			label: i18n.t("MessageTitle.MessageManagement"),
		},
		redirect: "/messages/list",
		children: [
			{
				path: "list",
				name: "MessageList",
				component: MessageList,
				meta: {
					title: i18n.t("MessageTitle.MessageList"),
					label: i18n.t("MessageTitle.MessageList"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
			{
				path: "create",
				name: "CreateMessage",
				component: CreateMessage,
				meta: {
					title: i18n.t("MessageTitle.CreateMessage"),
					label: i18n.t("MessageTitle.CreateMessage"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
			{
				path: ":id/update",
				name: "UpdateMessage",
				component: UpdateMessage,
				props: route => ({
					default: true,
					id: route.params.id,
				}),
				meta: {
					title: i18n.t("MessageTitle.UpdateMessage"),
					label: i18n.t("MessageTitle.UpdateMessage"),
					requiresAuth: true,
					role: [USER_ROLE.SYSTEM_ADMIN],
				},
			},
			{
				path: ":*",
				redirect: "/messages/list",
			},
		],
	},
]

export default messageRoutes
