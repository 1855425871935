import { callAPI } from "@/shared/services"

export default {
	orderProduct: params => {
		return callAPI("POST", "product-booking", params).then(res => res.data)
	},

	getClinic: params => {
		return callAPI("GET", "clinic", {
			params: params,
			notLoading: true,
		}).then(res => res.data)
	},
}
