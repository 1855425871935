import { callAPI } from "@/shared/services"

export default {
	getListBanner: params => {
		return callAPI("GET", "admin/banner", { params }).then(res => res.data)
	},

	createBanner: params => {
		return callAPI("POST", "admin/banner", params).then(res => res.data)
	},

	getBannerById: params => {
		return callAPI("GET", `banner/${params.id}`, params).then(res => res.data)
	},

	updateBanner: params => {
		return callAPI("PUT", `admin/banner/${params.get("id")}`, params).then(res => res.data)
	},

	deleteBanner: params => {
		return callAPI("DELETE", `admin/banner/${params.id}`).then(res => res.data)
	},
}
