import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	AdminTitle: {
		AdminManagement: "Quản lý Admin",
		AdminList: "Danh sách Admin",
		AdminDetail: "Chi tiết thông tin",
		CreateAdmin: "Tạo mới Admin",
		UpdateAdmin: "Chỉnh sửa Admin",
	},

	AdminManagement: {
		No: "No.",
		Actions: "Actions",
	},

	AdminForm: {
		No: "STT",
		Name: "Tên",
		Email: "Email",
		Status: "Trạng thái",
		Clinic: "Phòng khám",
		Permission: "Quyền",
		SystemAdmin: "System Admin",
		ClinicAdmin: "Clinic Admin",
		ClinicSupport: "Clinic Support",
		CreateAdmin: "Tạo mới tài khoản quản trị",
		UpdateAdmin: "Quản trị viên {name}",
		ChangePassword: "Đổi mật khẩu",
		CreateChangePassword: "Tạo/Đổi mật khẩu",
		DateCreated: "Ngày tạo",
		Creator: "Người tạo",
		Reference: "Tham khảo",
		ConfirmStatus:
			"Thay đổi trạng thái thành không hoạt động có nghĩa là chặn quản trị viên này đăng nhập vào web Phòng khám, điều này khiến họ không thể sử dụng các tính năng của ứng dụng nữa.",
		Active: "Đang hoạt động",
		Inactive: "Dừng hoạt động",
		DiscardChanges: "Loại bỏ những thay đổi?",
		AreYouSure: "Bạn chắc chứ?",
		ID: "ID",
		PKManagement: "Quản lý PK",
		EmailLogin: "Email đăng nhập",
		Actions: "Actions",
		DeleteDescription:
			"Sau khi xác nhận, tài khoản này sẽ bị xóa ở mọi nơi và quản trị viên này sẽ mất quyền với web Phòng khám.",
		All: "Tất cả",
		SendEmailPWD: "Mật khẩu sẽ được gửi về email đăng nhập của tài khoản",
		Search: "Nhập tên hoặc email để tìm kiếm",
	},

	fields: {
		...{
			admin_name: "Thông tin này",
			admin_email: "Thông tin này",
			admin_status: "Thông tin này",
			admin_clinic: "Thông tin này",
			admin_permission: "Thông tin này",
			admin_new_password: "Thông tin này",
		},
	},

	validation: {
		...vi.messages,
		...{
			// custom message rules
			isEmail: "Thông tin này phải là một địa chỉ email hợp lệ",
		},
	},
})
