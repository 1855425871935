import i18n from "@/shared/plugins/i18n"
import vi from "vee-validate/dist/locale/vi.json"

i18n.mergeLocaleMessage("vi", {
	ProductBooking: {
		BookingForm: "Booking From",
		BookingSuccess: "Đặt hàng thành công",
		Description:
			"Chúng tôi đang cung cấp miễn phí que tự xét nghiệm HIV và thuốc dự phòng phơi nhiễm HIV với số lượng có hạn. Vui lòng đăng ký tại đây, thông tin của bạn sẽ hoàn toàn được bảo mật",
		Finish: "Hoàn thành",
		YourFullName: "Họ tên của bạn",
		Phone: "Số điện thoại",
		DeliveryAddress: "Địa chỉ giao hàng",
		MoreInfo: "Thông tin thêm về xét nghiệm HIV và dịch vụ PrEP:",
		Facebook: "Facebook",
		Website: "Website",
		WebsiteLink: "Xomcauvong.com",
		WeCare: "Xomcauvong.com",
		XomCauVong: "Xóm Cầu Vồng",
		Congratulations: "Xin chúc mừng bạn đã đặt dịch vụ thành công",
		Contact: "Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất",
		BackToHome: "Quay về trang chủ",
		Birthyear: "Năm sinh",
		Address: "Tỉnh/Thành Phố",
	},

	BookingQuestion3: {
		Question: "Bạn muốn sử dụng dịch vụ nào dưới đây?",
		Answer1: "Đăng ký nhận que tự xét nghiệm HIV - OraQuick miễn phí",
		Answer1_HCM: "Đăng ký nhận bộ Tự xét nghiệm HIV - Mylan miễn phí",
		Answer2: "Đăng ký nhận thuốc PrEP miễn phí (thuốc dự phòng trước phơi nhiễm HIV)",
		Answer3: "Không có nhu cầu sử dụng dịch vụ nào",
	},

	BookingQuestion4: {
		Question: "Bạn muốn nhận que OraQuick thông qua hình thức nào?",
		Answer1: "Qua đường bưu điện",
		Answer2: "Qua dịch vụ giao hàng Grab",
		Answer3: "Nhận trực tiếp tại phòng khám",
	},

	BookingQuestion6: {
		Question: "Bạn muốn nhận que OraQuick tại cơ sở nào dưới đây?",
		Answer1: "(HN) PK Glink - Số 18, ngõ 9 Minh Khai, Hai Bà Trưng",
		Answer2: "(HN) PK Đa khoa Biển Việt - Số 18 NV1, Tân Triều, Thanh Trì",
		Answer3: "(HN) DNXH Venus - Tầng 2 - Số 6 Ngõ 538 Đường Láng, Đống Đa",
		Answer4: "(HCM) PK ALO CARE - 4A đường 14, P. Linh Đông, Q. Thủ Đức",
		Answer5: "(HCM) PK Glink - 224/38 Lý Thường Kiệt, P.14, Q. 10",
		Answer6: "(ĐN) PK ALO CARE Đồng Nai - 160 CMT8, TP. Biên Hoà",
		Answer7: "(ĐN) PK Glink Đồng Nai - C61 Khu Liên Kế - Khu Phố 1, TP. Biên Hoà",
	},

	BookingQuestion7: {
		Question: "Bạn muốn được tư vấn và sử dụng PrEP ở đâu?",
	},

	fields: {
		...{
			// Support sever side errors:
			// all fields should be snake case with prefix
			booking_full_name: "Thông tin này",
			booking_phone_number: "Thông tin này",
			booking_year_number: "Thông tin này",
			booking_service: "Thông tin này",
			booking_receive_method: "Thông tin này",
			booking_receive_address: "Thông tin này",
			booking_clinic: "Thông tin này",
			booking_address: "Thông tin này",
		},
	},

	validation: {
		...vi.messages,
		...{
			// custom message rules
			integer: "{_field_} phải là một số nguyên",
		},
	},
	Address: {
		HANOI: "Hà Nội",
		DONGNAI: "Đồng Nai",
		HCM: "TP. Hồ Chí Minh",
	},
})
