import {
	GET_REPORT_SCHEDULE_LIST_SUCCESS,
	GET_REPORT_PREP_LIST_SUCCESS,
	GET_CLINIC_LIST_SUCCESS,
} from "./mutation-types"

export default {
	[GET_REPORT_SCHEDULE_LIST_SUCCESS]: function (state, payload = {}) {
		state.schedule = payload.items
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[GET_REPORT_PREP_LIST_SUCCESS]: function (state, payload = {}) {
		state.prep = payload.items
		state.totalItems = payload.total
		state.recentFilters = { ...payload.filters }
		state.totalPages = Math.ceil(payload.total / state.recentFilters.limit)
	},

	[GET_CLINIC_LIST_SUCCESS]: function (state, payload = {}) {
		state.clinics = payload
	},
}
