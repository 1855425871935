// Start of Admin Side
export const GET_APPOINTMENT_LIST_SUCCESS = "GET_APPOINTMENT_LIST_SUCCESS"
export const RESET_FILTERS_APPT_LIST = "RESET_FILTERS_APPT_LIST"
export const GET_APPT_BY_ID_SUCCESS = "GET_APPT_BY_ID_SUCCESS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const RESET_CUSTOMERS = "RESET_CUSTOMERS"

export const SEND_IMPORT_FILE_SUCCESS = "SEND_IMPORT_FILE_SUCCESS"
export const GET_IMPORT_STATUS_SUCCESS = "GET_IMPORT_STATUS_SUCCESS"
export const GET_IMPORT_STATUS_FAILED = "GET_IMPORT_STATUS_FAILED"
export const CLEAR_INTERVAL_IMPORT = "CLEAR_INTERVAL_IMPORT"
export const RESET_IMPORT_STATUS = "RESET_IMPORT_STATUS"

export const CHANGE_UPLOAD_FILE_STATUS = "CHANGE_UPLOAD_FILE_STATUS"
export const CHANGE_PROGRESS_BAR_MODAL_STATUS = "CHANGE_PROGRESS_BAR_MODAL_STATUS"
export const CHANGE_IMPORT_SUCCESS_MODAL_STATUS = "CHANGE_IMPORT_SUCCESS_MODAL_STATUS"
export const CHANGE_STATUS_DISCARD_MODAL = "CHANGE_STATUS_DISCARD_MODAL"
export const CHANGE_STATUS_DELETE_MODAL = "CHANGE_STATUS_DELETE_MODAL"
// End of Admin Side

// Start of Customer Side
export const SEARCH_APPT_SUCCESS = "SEARCH_APPT_SUCCESS"
export const CANCEL_APPOINTMENT_SUCCESS = "CANCEL_APPOINTMENT_SUCCESS"
export const GET_MAIN_CITIES_SUCCESS = "GET_MAIN_CITIES_SUCCESS"
// End of Customer Side

// Both
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS"
export const GET_CLINICS_SUCCESS = "GET_CLINICS_SUCCESS"
export const RESET_CLINICS = "RESET_CLINICS"
export const RESET_APPT_FORM = "RESET_APPT_FORM"
export const RESET_DATA_INPUT_FILTERS = "RESET_DATA_INPUT_FILTERS"
